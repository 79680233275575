import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
} from "@angular/core";
import { PopupModel } from "./popup-model";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";

import { McService } from "../../services/mc-service";
import { StorageService } from "../../services/storage.service";
import { TokenManagerService } from "../../services/token-manager.service";
import { PageStyle } from "../../../models/style/style.model";
import { LayoutModel } from "../../../layout/layout-model";
import { FontsAndColorsService } from "../../services/fonts-and-colors.service";
import { DownloadAddToModel } from "../../../routes/media/media-model";
import { GoogleAnalyticsEventsService } from "../../services/google-analytics-events.service";
import { LanguageService } from "../../../shared/services/language.service";
import { Language } from "../../../models/language/language.model";
import { Router, ActivatedRoute } from "@angular/router";
import { DownloadMediaModel } from "../../../download/download-model";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.less"],
})
export class PopupComponent implements OnInit {
  @Input() layoutResponseModel: LayoutModel;
  @Input() popup: boolean;
  @Input() mediaId: number;
  @Input() isPublished: boolean;
  @Input() autoplay: boolean;
  @Input() hideCounters = false;
  @Output() popupChange = new EventEmitter<boolean>();
  @Output() popupClosed = new EventEmitter();
  @ViewChild("video") videoplayer: any;
  loginPopup: boolean;
  disabledButton = false;
  loader: boolean;
  downloadIds: any[] = [];
  video = document.getElementById("video");
  user: any;
  styles = new PageStyle();
  popupModelResponse = new PopupModel();
  socials: string[] = [];
  language: Language;
  sLng: number;
  urlForShare: string;
  token: string;
  dropdownRecent: boolean;
  mediaModel: any;
  playerSource: string;

  downloadSubscription: any;
  redirectToStoryClicked = false;

  constructor(
    private mcService: McService,
    private http: HttpClient,
    private languageService: LanguageService,
    private sService: StorageService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private fontsAndColorsService: FontsAndColorsService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();
    this.token = localStorage.getItem("Token");

    this.route.params.subscribe((params) => {
      if (this.redirectToStoryClicked) {
        this.getData();
      }
      //
    });

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });
  }

  @HostListener("document:keyup", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closePopup();
    }
  }

  ngOnInit() {
    this.getData();
    document.body.classList.add("noHeader");
    document.body.classList.add("noScroll");
  }

  ngOnDestroy() {
    document.body.classList.remove("noHeader");
    document.body.classList.remove("noScroll");
  }

  getData() {
    this.sLng = Number(localStorage.getItem("lng"));
    this.dropdownRecent = false;
    this.loader = true;
    this.user = TokenManagerService.getUser();
    this.getMediaDetail();

    if (this.user.cmsUser == null) {
      this.addViewToMedia();
    }
    this.downloadIds.push(this.mediaId);
  }

  updateSharedNumber(e) {
    const component = this;
    this.mediaModel = component.popupModelResponse.media;
    this.mediaModel.shares = e;
  }

  closePopup() {
    const component = this;
    this.mediaModel = component.popupModelResponse.media;
    this.popupChange.emit(false);
    this.popupClosed.emit(this.mediaModel);
  }

  onClickOutside(event: Object) {
    if (event && event["value"] === true) {
      this.dropdownRecent = false;
    }
  }

  addViewToMedia() {
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    this.googleAnalytics.emitEvent('viewmedia',
      "media/" + this.mediaId,
      "view",
      this.user.id ? this.user.id.toString() : "anonymous"
    );
    this.mcService.addViewToMedia(this.mediaId, this.user.id, this.isPublished).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  addDownload() {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    const niz = new DownloadAddToModel();
    if (this.isPublished) {
      niz.publishedMediaIds = [this.mediaId];
      niz.publishedMediaIds.forEach((mediaId) => {
        this.googleAnalytics.emitEvent('downloadMedia',
          "media/" + mediaId,
          "download-pm",
          niz.userId ? niz.userId.toString() : ""
        );
      });
      niz.storyMediaIds = [];
    }
    else {
      niz.storyMediaIds = [this.mediaId];
      niz.storyMediaIds.forEach((mediaId) => {
        this.googleAnalytics.emitEvent('downloadMedia',
          "media/" + mediaId,
          "download",
          niz.userId ? niz.userId.toString() : ""
        );
      });
      niz.publishedMediaIds = [];
    }
    niz.userId = this.user.id;
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  getMediaUrl() {
    this.urlForShare = this.popupModelResponse.media.shareUrl;
  }

  goToStory() {
    this.redirectToStoryClicked = true;
    this.router.navigateByUrl(this.popupModelResponse.alsoInStoryLink);
  }

  getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  getMediaDetail() {
    this.loader = true;
    this.mcService.getMediaDetail(this.mediaId, this.sLng, this.isPublished).subscribe(
      (response: any) => {
        this.popupModelResponse = response;

        if (
          this.popupModelResponse.downloadLinks.filter((d) => !d.isOriginal)
            .length > 0
        ) {
          this.playerSource = this.popupModelResponse.downloadLinks.filter(
            (d) => !d.isOriginal
          )[0].downloadUrl;
        } else {
          this.playerSource = this.popupModelResponse.downloadLinks.filter(
            (d) => d.isOriginal
          )[0].downloadUrl;
        }

        if (
          this.popupModelResponse.mustRegisterToAccess &&
          !this.popupModelResponse.anonymousShare &&
          !this.token
        ) {
          sessionStorage.setItem("redirectUrl", "/" + window.location.pathname);
          this.router.navigateByUrl("/sign-in");
        }

        if (this.popupModelResponse.socialSharing != null) {
          this.socials = this.popupModelResponse.socialSharing;
        }
        if (this.popupModelResponse.media.mediaType === "Document") {
          this.popupModelResponse.media.thumbUrl =
            "assets/images/document_default.png";
        } else if (this.popupModelResponse.media.mediaType === "Audio") {
          this.popupModelResponse.media.thumbUrl =
            "assets/images/audio_default.png";
        }
        this.getMediaUrl();
        this.loader = false;
      },
      (error) => {
        //
      }
    );
  }

  cancelDownload(media: DownloadMediaModel) {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
      this.disabledButton = false;
      media.downloadActive = false;
    }
  }

  // returnDownloadUrl() {
  //   return ('test string');
  // }

  download(media: DownloadMediaModel) {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (iOS) {
      try {
        (window as any).webkit.messageHandlers.downloadClicked.postMessage({
          url: media.downloadUrl,
        });
      } catch (err) { }

      return;
    }

    if (
      this.popupModelResponse.mustRegisterToDownload &&
      !this.popupModelResponse.anonymousShare &&
      localStorage.getItem("Token") === null
    ) {
      this.loginPopup = true;
      return;
    } else {
      if (!this.disabledButton) {
        const component = this;
        component.disabledButton = true;
        media.downloadActive = true;

        this.downloadSubscription = this.http
          .get(media.downloadUrl, { responseType: "blob" })
          .subscribe(
            (blob: Blob) => {
              const filename = media.downloadUrl.split("/")[8];
              saveAs(blob, filename);

              if (!this.user.cmsUser) {
                component.popupModelResponse.media.downloads++;
              }

              component.disabledButton = false;
              media.downloadActive = false;

              if (component.user.cmsUser == null) {
                component.addDownload();
              }
            },
            (error) => {
              component.disabledButton = false;
              media.downloadActive = false;
            }
          );
      }
    }
  }

  refreshMediaLinks() {
    this.getMediaDetail();
  }
}
