import { Component, HostListener, OnInit } from "@angular/core";
import { McService } from "../../shared/services/mc-service";
import { DownloadAddToModel, MediaModel } from "./media-model";
import { WebManageMediaModel } from "../../download/download-model";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { saveAs } from "file-saver";
import { HttpClient } from "@angular/common/http";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { StorageService } from "../../shared/services/storage.service";
import { PageStyle } from "../../models/style/style.model";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { Location } from "@angular/common";
import { ButtonsHelper } from "../../shared/helpers/buttons-helper";
import { SearchModel, WebSearchModel } from "../../header/search/search-model";
import { MyFilesService } from "../../shared/services/my-files.service";
import { DoCheck, KeyValueDiffers, KeyValueDiffer } from "@angular/core";
import { WebNewsDetailsMediaModel } from "../news/news-model";
import { MyFilesModel } from "../../shared/services/my-files-model";
import { forkJoin } from "rxjs";
import { AlgoliaService } from "../../shared/services/algolia.service";
declare var $: any;

@Component({
  selector: "app-media",
  templateUrl: "./media.component.html",
  styleUrls: ["./media.component.less"],
})
export class MediaComponent implements OnInit {
  selectedType: any;
  media: any[];
  forDownload: any[] = [];
  downloadIds: any[] = [];
  mediaId: number;
  activeSubTab: number = 1;
  cartItems: MyFilesModel;
  disabledButton: boolean;
  loader: boolean;
  filterDropdown: boolean;
  filtered: WebManageMediaModel[];
  grid: string;
  p = 1;
  itemsPerPage: number = 12;
  autoplay: boolean;
  mediaDropdown: boolean;
  all = true;
  image: boolean;
  video: boolean;
  document: boolean;
  audio: boolean;
  popup = false;
  loginPopup: boolean;
  mediaModelResponse = new MediaModel();
  styles = new PageStyle();
  user: any;
  activeAllTab = false;
  activeTab = false;
  activeImageTab = false;
  activeVideoTab = false;
  activeDocumentsTab = false;
  activeAudioTab = false;
  socials: string[] = [];
  addDownloadModel: DownloadAddToModel;
  activeAll = true;
  activeImage = false;
  activeVideo = false;
  activeDoc = false;
  activeAudio = false;
  totalItems: number;
  mediaNameLength: number;
  allActive = false;
  imageActive = false;
  videoActive = false;
  docActive = false;
  audioActive = false;
  language: Language;
  buttonsHelper = ButtonsHelper;
  sLng: number;
  filtersVisible = false;

  mediaMinute: number;
  mediaSecond: number;

  whiteShareStyle = true;

  callGetAllData = true;
  downloadActive = false;
  downloadSubscription: any;
  cancelDownloads = false;
  lazyLoadingLoader = false;
  maxPageMedia = 1;
  showFilter = false;
  searchModelData = new SearchModel();
  dateToButton = false;
  dateFromButton = false;
  dateFrom: Date = null;
  dateTo: Date = null;
  currentType;
  allFiles: number = 0;
  filters: any = [];
  differ: KeyValueDiffer<string, any>;
  mediaGroups: WebManageMediaModel[][] = [[]];

  isEasyJet: boolean = false;

  constructor(
    private mcService: McService,
    private http: HttpClient,
    private languageService: LanguageService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private location: Location,
    private myFilesService: MyFilesService,
    private route: ActivatedRoute,
    private differs: KeyValueDiffers,
    private algoliaService: AlgoliaService
  ) {
    // if (window.innerWidth <= 1100) {
    //   this.grid = "Columns2";
    // } else {
    //   this.grid = "Columns4";
    // }
    // window.onresize = (e) => {
    //   ngZone.run(() => {
    //     if (window.innerWidth <= 1100) {
    //       this.grid = "Columns2";
    //     } else {
    //       this.grid = "Columns4";
    //     }
    //   });
    // };
    this.differ = this.differs.find({}).create();
    this.myFilesService.files.subscribe((myFiles) => {
      this.cartItems = myFiles;
    });
    this.language = languageService.getLanguage();
    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    this.route.params.subscribe((params: Params) => {
      const id: string = params["id"];
      const title: string = params["title"];
      let media: string = params["media"];

      if (media && media.indexOf("?") > -1)
        media = media.substr(0, media.indexOf("?"));

      if (media) {
        this.callGetAllData = true;
        this.openPopup(+media);
      }
    });
  }

  // @HostListener("window:resize", ["$event"])
  // onResize(event) {
  //   this.resize();
  // }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 300
      && !this.lazyLoadingLoader
    ) {
      this.loadMore();
    }
  }

  toggleShowFilter() {
    this.showFilter = !this.showFilter;
  }

  loadMore() {
    if (this.p < this.maxPageMedia) {
      this.p++;
      this.lazyLoadingLoader = true;
      this.changePage(this.p);
    }
  }

  openPopup(id: number) {
    this.popup = true;
    this.mediaId = id;
  }

  public config: any;
  getSearch() {
    const configObservable = this.mcService.getSearchConfig();
    const searchObservable = this.mcService.getSearch();
    forkJoin([configObservable, searchObservable]).subscribe(
      (response: any) => {
        this.config = response[0];
        this.searchModelData = response[1];
        this.searchModelData.searchModel = this.getFilters();
        if (this.callGetAllData) {
          this.getMedias(this.p, null, true);
        } else {
          this.loader = false;
        }
      },
      (error) => {
        this.loader = false;
      }
    );
  }

  onChangeBrand(id: number) {
    if (id == 0) {
      this.searchModelData.searchModel.subClientIds = [];
      let select = (<HTMLSelectElement>document.getElementById('brandSelect'));
      select.value = '0';
    } else {
      this.searchModelData.searchModel.subClientIds = [id];
    }
  }

  clearFilters() {
    if (this.searchModelData.subClients.length > 0) {
      this.onChangeBrand(0);
    }
    this.searchModelData.searchModel.dateFrom = null;
    this.searchModelData.searchModel.dateTo = null;
    this.searchModelData.searchModel.searchText = '';
    this.getMedias(1, this.selectedType, true);
  }

  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
  }

  activeallC(name) {
    if (name == "all") {
      if (this.activeAllTab) {
        this.allActive = true;
      } else {
        this.activeAllTab = true;
      }
    } else if (name == "image") {
      if (this.activeImageTab) {
        this.imageActive = true;
      } else {
        this.activeImageTab = true;
      }
    } else if (name == "video") {
      if (this.activeVideoTab) {
        this.videoActive = true;
      } else {
        this.activeVideoTab = true;
      }
    } else if (name == "document") {
      if (this.activeDocumentsTab) {
        this.docActive = true;
      } else {
        this.activeDocumentsTab = true;
      }
    } else if (name == "audio") {
      if (this.activeAudioTab) {
        this.audioActive = true;
      } else {
        this.activeAudioTab = true;
      }
    }
  }

  activeallCOut(name) {
    if (name == "all") {
      if (!this.allActive) {
        this.activeAllTab = false;
      }
    } else if (name == "image") {
      if (!this.imageActive) {
        this.activeImageTab = false;
      }
    } else if (name == "video") {
      if (!this.videoActive) {
        this.activeVideoTab = false;
      }
    } else if (name == "document") {
      if (!this.docActive) {
        this.activeDocumentsTab = false;
      }
    } else if (name == "audio") {
      if (!this.audioActive) {
        this.activeAudioTab = false;
      }
    }
  }

  activeTabM(tab) {
    this.activeAll = false;
    this.activeImage = false;
    this.activeVideo = false;
    this.activeDoc = false;
    this.activeAudio = false;
    if (tab === "image") {
      this.activeImage = true;
    }
    if (tab === "all") {
      this.activeAll = true;
    }
    if (tab === "video") {
      this.activeVideo = true;
    }
    if (tab === "audio") {
      this.activeAudio = true;
    }
    if (tab === "doc") {
      this.activeDoc = true;
    }
  }


  ngOnInit() {
    this.sLng = Number(localStorage.getItem("lng"));
    this.user = TokenManagerService.getUser();
    window.scrollTo(0, 0);
    this.getSearch();
    this.cartItems = this.myFilesService.getMyFiles();
    if (localStorage.getItem("isEasyJet") === "1") {
      this.isEasyJet = true;
    }
  }

  changeColorButtonn(e) {
    // //
    if (e.target.tagName == "DIV") {
      e.target.style.background = this.styles.style.mainButton2HoverColor;
    } else {
      e.target.parentElement.style.background =
        this.styles.style.mainButton2HoverColor;
    }
  }

  changeColorButton(e) {
    if (e.target.tagName == "DIV") {
      e.target.style.background = this.styles.style.mainButton2DefaultColor;
    }
  }

  setColor(e) {
    setTimeout(() => {
      const element = document.getElementsByClassName(
        "current"
      ) as HTMLCollectionOf<HTMLElement>;
      if (element[0])
        element[0].style.backgroundColor =
          "" + this.styles.style.mainIdentifyingColor;
    });
  }

  openMediaDropdown(media) {
    media.mediaDropdown = !media.mediaDropdown;
    event.stopPropagation();
  }

  onClickOutside(event: Object, media) {
    if (event && event["value"] === true) {
      media.mediaDropdown = false;
    }
  }

  openDropdown() {
    this.filterDropdown = true;
  }

  closeDropdown() {
    this.filterDropdown = false;
  }

  public mediaFiles: any[] = [];

  getMedias(page, type = null, isReset = false) {
    this.saveFilters();
    this.currentType = type;
    this.loader = isReset;
    this.searchModelData.searchModel.page = page;
    if (isReset) this.searchModelData.searchModel.page = 1;
    this.searchModelData.searchModel.pageSize = this.itemsPerPage;
    this.searchModelData.searchModel.languageId = this.sLng;
    let typeId = this.getMediaTypeId(type);
    let dateFrom = null;
    let dateTo = null;
    if (this.searchModelData.searchModel.dateFrom != null) {
      dateFrom = new Date(this.searchModelData.searchModel.dateFrom);
      dateFrom.setHours(0, 0, 0, 0);
    }
    if (this.searchModelData.searchModel.dateTo != null) {
      dateTo = new Date(this.searchModelData.searchModel.dateTo);
      dateTo.setHours(23, 59, 59, 999);
    }
    this.algoliaService.searchMediaWithFilters(
      this.searchModelData.searchModel.searchText,
      this.searchModelData.searchModel.page,
      this.searchModelData.searchModel.pageSize,
      typeId,
      this.searchModelData.searchModel.subClientIds.length > 0 ? this.searchModelData.searchModel.subClientIds[0] : null,
      this.searchModelData.searchModel.marketId == 0 ? null : this.searchModelData.searchModel.marketId,
      dateFrom,
      dateTo
    ).then((response: any) => {
      const mappedResults = response.results.map((x) => {
        let id;
        if (x.isPublished) {
          id = x.publishedIds && x.publishedIds.length > 0 ? x.publishedIds[0]
            : parseInt(x.objectID);
        } else {
          id =
            x.storyMediaIds && x.storyMediaIds.length > 0
              ? x.storyMediaIds[0]
              : parseInt(x.objectID);
        }
        const fullHdUrl = x.urls.find((x) => x.format === 4);
        const originalUrl = x.urls.find((x) => x.format === 5);
        const thumbUrl = x.urls.find((x) => x.format === 2);
        return {
          mediaType: this.getMediaName(x.type),
          name: x.title,
          id: id,
          thumbUrl: thumbUrl ? thumbUrl.url : null,
          fullHDUrl: x.typeName === "image" && x.urls.find((url) => url.format == 3) ? x.urls.find((url) => url.format == 3).url : null,
          downloadLink: fullHdUrl ? fullHdUrl.url : originalUrl ? originalUrl.url : x.urls.length > 0 ? x.urls[0].url : null,
          typeName: x.typeName,
          isPublished: x.isPublished,
          relativeShareUrl: '/results/' + id + '?isPublished=' + x.isPublished,
          urls: x.urls,
        }
      });
      if (this.mediaFiles && !isReset) {
        this.mediaFiles = this.mediaFiles.concat(mappedResults);
      } else {
        this.mediaFiles = mappedResults;
        this.downloadIds = [];
        this.forDownload = [];
      }

      this.totalItems = response.total;
      this.updateFilters(response);

      this.maxPageMedia = Math.ceil(response.total / this.itemsPerPage);
      for (const x of this.mediaFiles) {
        if (x.mediaType.toLowerCase() === "document") {
          x.thumbUrl = "assets/images/document_default.png";
        } else if (x.mediaType.toLowerCase() === "audio") {
          x.thumbUrl = "assets/images/audio_default.png";
        }
        const fullHdUrl = x.urls.find((x) => x.format === 4);
        const originalUrl = x.urls.find((x) => x.format === 5);
        let downloadUrl = "";
        if (fullHdUrl) {
          downloadUrl = fullHdUrl.url;
        } else if (originalUrl) {
          downloadUrl = originalUrl.url;
        } else if (x.urls.length > 0) {
          downloadUrl = x.urls[0].url;
        }
        if (downloadUrl) {
          this.forDownload.push(downloadUrl);
          this.downloadIds.push(x.id);
          x.downloadUrl = downloadUrl;
        }
      }
      if (type) {
        switch (type) {
          case "Image":
            this.totalItems = response.images;
            this.activeTabM("image");
            break;
          case "Video":
            this.totalItems = response.videos;
            this.activeTabM("video");
            break;
          case "Document":
            this.totalItems = response.documents;
            this.activeTabM("doc");
            break;
          case "Audio":
            this.totalItems = response.audios;
            this.activeTabM("audio");
            break;
        }
      } else {
        this.activeTabM("all");
      }
      this.loader = false;
      this.lazyLoadingLoader = false;
      this.mediaGroups = [[]];
      this.groupMedia();
    });

  }

  getMediaTypeId(mediaName: string): number {
    switch (mediaName) {
      case "Image":
        return 3;
      case "Video":
        return 2;
      case "Document":
        return 4;
      case "Audio":
        return 1;
      default:
        return null;
    }
  }

  getMediaName(mediaTypeId: number): string {
    switch (mediaTypeId) {
      case 3:
        return "Image";
      case 2:
        return "Video";
      case 4:
        return "Document";
      case 1:
        return "Audio";
      default:
        return null;
    }
  }

  groupMedia() {
    let count = 0;
    for (const media of this.mediaFiles) {
      if (this.mediaGroups[count].length >= 4) count++;
      if (!this.mediaGroups[count]) this.mediaGroups[count] = [];
      this.mediaGroups[count].push(media);
    }
  }

  // resize() {
  //   if (this.mediaModelResponse.mediaPageGridType === "Columns2") {
  //     this.mediaNameLength = 80;
  //     if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
  //       this.mediaNameLength = 50;
  //     }
  //     if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
  //       this.mediaNameLength = 50;
  //     }
  //     if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
  //       this.mediaNameLength = 34;
  //     }
  //     if (window.innerWidth < 700) {
  //       this.mediaNameLength = 34;
  //     }
  //   }
  //   if (this.mediaModelResponse.mediaPageGridType === "Columns3") {
  //     this.mediaNameLength = 40;

  //     if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
  //       this.mediaNameLength = 30;
  //     }
  //     if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
  //       this.mediaNameLength = 26;
  //     }
  //     if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
  //       this.mediaNameLength = 15;
  //     }
  //     if (window.innerWidth < 700) {
  //       this.mediaNameLength = 34;
  //     }
  //   }
  //   if (this.mediaModelResponse.mediaPageGridType === "Columns4") {
  //     this.mediaNameLength = 26;

  //     if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
  //       this.mediaNameLength = 16;
  //     }
  //     if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
  //       this.mediaNameLength = 26;
  //     }
  //     if (window.innerWidth <= 1000 && window.innerWidth >= 700) {
  //       this.mediaNameLength = 15;
  //     }
  //     if (window.innerWidth < 700) {
  //       this.mediaNameLength = 34;
  //     }
  //   }
  // }

  sendToCart() {
    const component = this;
    this.mediaFiles.forEach(function (value) {
      if (component.cartItems.storyMediaIds.indexOf(value.id) == -1) {
        component.myFilesService.pushMyFiles(value.id);
        value.addedToCart = true;
        // localStorage.setItem("cart", JSON.stringify(component.cartItems));
      }
    });
  }

  getMediaUrl(url, mediaId) {
    // add url from api
    // //
    return url.replace(/%20| /g, "-") + "/" + mediaId;
  }

  addDownloadClientSide() {
    for (const x of this.mediaModelResponse.media) {
      for (const y of this.forDownload) {
        if (x.downloadLink == y) {
          x.downloads++;
        }
      }
    }
  }

  openMediaPopup(media) {
    this.mediaId = media.id;
    this.popup = true;
    if (this.user.cmsUser == null) {
      media.views++;
    }
    this.location.replaceState(media.relativeShareUrl);
    // this.router.navigateByUrl(UrlHelper.replaceFullUrlTitle('media' + media.relativeShareUrl));
  }

  openMediaPopupAutplay(media) {
    this.autoplay = true;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
    this.location.replaceState(media.relativeShareUrl);
    // this.router.navigateByUrl(UrlHelper.replaceFullUrlTitle('media' + media.relativeShareUrl));
  }

  changeTab(index) {
    if (index === this.activeSubTab) return;
    this.p = 1;
    this.activeSubTab = index;
    this.setColor($);
    switch (index) {
      case 1:
        this.all = true;
        this.image = false;
        this.video = false;
        this.document = false;
        this.audio = false;

        this.selectedType = null;
        this.activeAllTab = true;
        this.activeImageTab = false;
        this.activeVideoTab = false;
        this.activeDocumentsTab = false;
        this.activeAudioTab = false;
        this.getMedias(this.p, null, true);
        this.allActive = true;
        this.imageActive = false;
        this.videoActive = false;
        this.audioActive = false;
        this.docActive = false;

        break;
      case 2:
        this.all = false;
        this.image = true;
        this.video = false;
        this.document = false;
        this.audio = false;

        this.selectedType = "Image";
        this.getMedias(this.p, this.selectedType, true);
        this.activeAllTab = false;
        this.activeImageTab = true;
        this.activeVideoTab = false;
        this.activeDocumentsTab = false;
        this.activeAudioTab = false;
        this.allActive = false;
        this.imageActive = true;
        this.videoActive = false;
        this.audioActive = false;
        this.docActive = false;

        break;
      case 3:
        this.all = false;
        this.image = false;
        this.video = true;
        this.document = false;
        this.audio = false;
        this.activeAllTab = false;
        this.activeImageTab = false;
        this.activeVideoTab = true;
        this.activeDocumentsTab = false;
        this.activeAudioTab = false;

        this.selectedType = "Video";
        this.getMedias(this.p, this.selectedType, true);
        this.allActive = false;
        this.imageActive = false;
        this.videoActive = true;
        this.audioActive = false;
        this.docActive = false;

        break;

      case 4:
        this.all = false;
        this.image = false;
        this.video = false;
        this.document = true;
        this.audio = false;

        this.selectedType = "Document";
        this.getMedias(this.p, this.selectedType, true);
        this.activeAllTab = false;
        this.activeImageTab = false;
        this.activeVideoTab = false;
        this.activeDocumentsTab = true;
        this.activeAudioTab = false;
        this.allActive = false;
        this.imageActive = false;
        this.videoActive = false;
        this.audioActive = false;
        this.docActive = true;

        break;

      case 5:
        this.all = false;
        this.image = false;
        this.video = false;
        this.document = false;
        this.audio = true;

        this.selectedType = "Audio";
        this.getMedias(this.p, this.selectedType, true);
        this.activeAllTab = false;
        this.activeImageTab = false;
        this.activeVideoTab = false;
        this.activeDocumentsTab = false;
        this.activeAudioTab = true;
        this.allActive = false;
        this.imageActive = false;
        this.videoActive = false;
        this.audioActive = true;
        this.docActive = false;

        break;
    }
  }

  addDownload() {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    const niz = new DownloadAddToModel();
    niz.publishedMediaIds = this.mediaFiles.filter((x) => x.isPublished).map((x) => x.id);
    niz.storyMediaIds = this.mediaFiles.filter((x) => !x.isPublished).map((x) => x.id);
    niz.userId = this.user.id;
    niz.storyMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId.toString()
      );
    });
    niz.publishedMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download-pm",
        niz.userId.toString()
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  mediaChecked(media, event) {
    const component = this;
    const index = this.forDownload.indexOf(media);
    media.checked = !media.checked;
    if (component.forDownload.indexOf(media) == -1 && media.checked) {
      component.forDownload.push(media.downloadLink);
      component.downloadIds.push(media.id);
    } else if (!media.checked) {
      component.forDownload.splice(index, 1);
      component.downloadIds.splice(index, 1);
    }

    event.target.style.backgroundColor = event.target.checked
      ? this.styles.style.mainIdentifyingColor
      : "";
  }

  cancelDownload() {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }

    this.downloadActive = false;
    this.disabledButton = false;
    this.cancelDownloads = true;
  }

  download() {
    if (
      this.mediaModelResponse.mustRegisterToDownload &&
      localStorage.getItem("Token") === null
    ) {
      this.loginPopup = true;
      return;
    } else {
      const urls = this.forDownload;
      const component = this;
      const zip = new JSZip();
      let count = 0;
      const zipFilename = "download.zip";

      if (this.forDownload.length > 1) {
        component.disabledButton = true;
        component.downloadActive = true;
        component.cancelDownloads = false;

        urls.forEach((url) => {
          const filename = url.split("/")[8];

          if (component.cancelDownloads) {
            return;
          }

          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (component.cancelDownloads) {
              return;
            }

            zip.file(filename, data, { binary: true });
            count++;

            if (count === urls.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, zipFilename);
                component.disabledButton = false;
                component.downloadActive = false;

                if (component.user.cmsUser === null) {
                  component.addDownload();
                }
              });
            }
          });
        });
        this.addDownloadClientSide();
      } else {
        for (const x of this.forDownload) {
          component.downloadActive = true;
          component.disabledButton = true;

          this.downloadSubscription = this.http
            .get(x, { responseType: "blob" })
            .subscribe(
              (blob: Blob) => {
                const filename = x.split("/")[8];
                saveAs(blob, filename);

                component.downloadActive = false;
                component.disabledButton = false;

                if (component.user.cmsUser === null) {
                  component.addDownload();
                }

                this.addDownloadClientSide();
              },
              (error) => {
                component.downloadActive = false;
                component.disabledButton = false;
              }
            );
        }
      }
    }
  }

  changePage(page) {
    this.p = page;
    this.getMedias(page, this.selectedType);
  }

  twoGrid() {
    this.grid = "Columns2";
    this.itemsPerPage = 12;
  }

  threeGrid() {
    this.grid = "Columns3";
    this.itemsPerPage = 12;
  }

  fourGrid() {
    this.grid = "Columns4";
    this.itemsPerPage = 12;
  }

  openDateFrom() {
    this.dateFromButton = !this.dateFromButton;
  }

  openDateTo() {
    this.dateToButton = !this.dateToButton;
  }

  changeFromDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        this.dateTo = new Date();
      }
    }
  }

  changeToDate(event) {
    if (event.value !== null) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        const dateTo: Date = new Date(this.dateTo);
        this.dateFrom = new Date(dateTo.getTime());
      }
    }
  }
  updateFilters(response) {
    this.allFiles = response.total;
    this.filters = [
      {
        key: 1,
        text: this.language.allFiles,
        faClass: "fal fa-archive",
        amount: this.allFiles,
      },
      {
        key: 2,
        text: this.language.images,
        faClass: "fal fa-camera-retro",
        amount: response.images,
      },
      {
        key: 3,
        text: this.language.videoFiles,
        faClass: "fal fa-video",
        amount: response.videos,
      },
      {
        key: 4,
        text: this.language.documents,
        faClass: "fal fa-file",
        amount: response.documents,
      },
      {
        key: 5,
        text: this.language.audioFiles,
        faClass: "fal fa-volume-up",
        amount: response.audios,
      },
    ];
  }

  saveFilters() {
    console.log(this.searchModelData.searchModel.dateFrom);
    localStorage.setItem("mediaFilters", JSON.stringify(this.searchModelData.searchModel));
  }

  getFilters(): WebSearchModel {
    let filters: WebSearchModel = JSON.parse(localStorage.getItem("mediaFilters"));
    if (filters) {
      this.showFilter = filters.subClientIds !== null
        && filters.subClientIds.length > 0
        || filters.searchText !== null
        && filters.searchText.length > 0
        || filters.dateFrom !== null || filters.dateTo !== null;
      return filters;
    } else {
      filters = new WebSearchModel();
      this.showFilter = false;
      filters.images = true;
      filters.video = true;
      filters.document = true;
      filters.audio = true;
      filters.media = true;
      filters.stories = false;
      return filters;
    }
  }

  isSelected(id: number): boolean {
    return this.searchModelData.searchModel.subClientIds[0] == id;
  }

}
