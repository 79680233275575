import { Component, ElementRef, OnInit } from "@angular/core";
import { McService } from "../shared/services/mc-service";
import { LayoutModel } from "./layout-model";
import { PageStyle } from "../models/style/style.model";
import { Language } from "../models/language/language.model";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { FontsAndColorsService } from "../shared/services/fonts-and-colors.service";
import { LanguageService } from "../shared/services/language.service";
import { MetaModel } from "../models/meta/meta";
import { StorageService } from "../shared/services/storage.service";
import { TokenManagerService } from "../shared/services/token-manager.service";
import { filter } from "rxjs/operators";
import { MCLanguageService } from "../shared/services/mc-language.service";
import { SavedStoryService } from "../shared/services/saved-story.service";
import { AlgoliaService } from "../shared/services/algolia.service";
import { LoginAttemptsService } from "../shared/services/login-attempts.service";
import { forEach } from "jszip";
import { CommunicationService } from "../shared/services/communication.service";
declare global {
  interface Window {
    showCustomCookieNotice: boolean;
  }
}

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.less"],
})
export class LayoutComponent implements OnInit {
  styles: PageStyle;
  layoutResponseModel: LayoutModel;
  loader = true;
  showCookieNotice = true;
  metadatas: MetaModel;
  sLng: number;
  isVodafone: boolean;
  isEasyJet: boolean;
  isLoci: boolean;
  clientName: string = "";
  language: Language;

  constructor(
    private algoliaService: AlgoliaService,
    private mcService: McService,
    private communicationService: CommunicationService,
    private sService: StorageService,
    private router: Router,
    public fontsAndColorsService: FontsAndColorsService,
    public languageService: LanguageService,
    public mcLanguageService: MCLanguageService,
    private loginAttemptsService: LoginAttemptsService,
    private elementRef: ElementRef,
    private savedStoryService: SavedStoryService,
    private activatedRoute: ActivatedRoute,
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((response: NavigationEnd) => {
        // if (
        //   window.showCustomCookieNotice != null &&
        //   window.showCustomCookieNotice
        // ) {
        //   if (localStorage.CookieAgreed != null) {
        //     this.showCookieNotice = !JSON.parse(localStorage.CookieAgreed);
        //   } else {
        //     this.showCookieNotice = true;
        //   }
        // }
      });
  }

  ngOnInit() {
    this.sLng = Number(localStorage.getItem("lng"));
    this.loader = true;
    this.styles = this.fontsAndColorsService.getPageStyleInfo();
    this.setCssVariables();
    this.getClientInfo();
    this.getHeaderFooter(1);
    this.getCurrentMarket();
    // this.getLoginAttempts();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.token) {
        this.mcService.loginWithToken(params.token).subscribe(
          (response: any) => {
            localStorage.setItem("Token", JSON.stringify({ token: response.token }));
            localStorage.setItem("User", JSON.stringify({ user: response.user }));
            this.sService.setName(response.user.fullName);
            this.savedStoryService.getIds();
            this.router.navigateByUrl("/", { replaceUrl: true });
          },
          (error) => {
            this.router.navigateByUrl("/", { replaceUrl: true });
          }
        );
      }
    });

    // this.showCookieNotice = false;
    // if (
    //   window.showCustomCookieNotice != null &&
    //   window.showCustomCookieNotice
    // ) {
    //   this.showCookieNotice = true;
    // }
  }

  getCurrentMarket() {
    this.mcService.getCurrentMarket().subscribe(
      (response: any) => {
        localStorage.setItem("currentMarket", JSON.stringify(response));
      },
      (error) => {
        //
      }
    );
  }

  getLoginAttempts() {
    this.mcService.getLoginAttempts(TokenManagerService.getUser().id, 1, 10).subscribe(
      (response: any) => {
        localStorage.setItem("loginAttempts", JSON.stringify(response));
      },
      (error) => {
        //
      }
    );
  }

  getHeaderFooter(x) {
    this.mcService.getHeaderFooter(this.sLng).subscribe(
      (response: any) => {
        this.layoutResponseModel = response;
        for (const link of this.layoutResponseModel.headerLinks) {
          if ("/" + link.url === this.router.url) {
            link.active = true;
          }
        }
        for (const link of this.layoutResponseModel.footerLinks) {
          if ("/" + link.url === this.router.url) {
            link.active = true;
          }
        }
        localStorage.setItem("headerfooter", JSON.stringify(response));
        this.sService.setLogin(response);

        if (!this.sLng) this.sLng = this.layoutResponseModel.defaultLanguageId;

        this.languageService.setLanguageId(this.sLng);
        this.getLanguage();
        this.getMcLanguage();
      },
      (error) => {
        //
      }
    );
  }

  getMeta() {
    this.mcService.getMeta().subscribe(
      (response: any) => {
        this.metadatas = response;
      },
      (error) => {
        //
      }
    );
  }

  getClientInfo() {
    this.mcService.getClientInfo().subscribe(
      (response: any) => {
        const hideCategories: boolean = response.hideCategories;
        this.clientName = response.name;
        this.algoliaService.setClientId(response.id);
        response.mediaCenterVersions.forEach((mediaCenterVersion) => {
          if (mediaCenterVersion.type === 'SuperMediaCenter') {
            this.algoliaService.setSuperMediaCenter();
          }
        })
        this.sService.setLegalBanner(response.mediaCenterVersions[0]?.legalBanner);
        localStorage.setItem("hideCategories", hideCategories ? "1" : "0");
        localStorage.setItem("hasPasswordHardening", response.passwordHardening);
        localStorage.setItem("hasStoryCodes", response.hasStoryCodes);

        // Local id dev (Bacardi) = 264
        // Vodafone id = 187
        // EasyJet id = 14
        // Loci id = 178
        localStorage.setItem("isVodafone", response.id == 187 ? "1" : "0");
        localStorage.setItem("isEasyJet", response.id == 14 ? "1" : "0");
        localStorage.setItem("isLoci", response.id == 178 ? "1" : "0");
        this.isVodafone = localStorage.getItem("isVodafone") === "1";
        this.isEasyJet = localStorage.getItem("isEasyJet") === "1";
        this.isLoci = localStorage.getItem("isLoci") === "1";

        this.elementRef.nativeElement.style.setProperty(
          `--${'mainFont'}`,
          'Montserrat, sans-serif'
        );
        this.elementRef.nativeElement.style.setProperty(
          `--${'navigationFont'}`,
          'Montserrat, sans-serif'
        );
        this.elementRef.nativeElement.style.setProperty(
          `--${'sliderHeadlineFont'}`,
          'Montserrat, sans-serif'
        );

        if (this.isVodafone) {
          this.elementRef.nativeElement.style.setProperty(
            `--${'mainFont'}`,
            'Vodafone Rg, sans-serif'
          );
          this.elementRef.nativeElement.style.setProperty(
            `--${'navigationFont'}`,
            'Vodafone Rg, sans-serif'
          );
          this.elementRef.nativeElement.style.setProperty(
            `--${'sliderHeadlineFont'}`,
            'Vodafone ExB, sans-serif'
          );
        }
        if (this.isEasyJet) {
          this.elementRef.nativeElement.style.setProperty(
            `--${'mainFont'}`,
            'easyJet Rounded Book, sans-serif'
          );
          this.elementRef.nativeElement.style.setProperty(
            `--${'navigationFont'}`,
            'easyJet Rounded Book, sans-serif'
          );
          this.elementRef.nativeElement.style.setProperty(
            `--${'sliderHeadlineFont'}`,
            'easyJet Rounded Headline, sans-serif'
          );
        }
        if (this.isLoci) {
          this.elementRef.nativeElement.style.setProperty(
            `--${'mainFont'}`,
            'Roc Grotesk, sans-serif'
          );
          this.elementRef.nativeElement.style.setProperty(
            `--${'navigationFont'}`,
            'Roc Grotesk, sans-serif'
          );
          this.elementRef.nativeElement.style.setProperty(
            `--${'sliderHeadlineFont'}`,
            'Roc Grotesk, sans-serif'
          );
        }
      },
      (error) => {
        //
      }
    );
  }

  getStyle() {
    this.mcService.getStyle().subscribe(
      (response: any) => {
        this.styles = response;
        this.fontsAndColorsService.setPageStyleInfo(response);
        this.loader = false;
      },
      (error) => {
        //
        this.loader = false;
      }
    );
  }

  getMcLanguage() {
    this.mcService.getMcLanguage().subscribe(
      (response: any) => {
        this.mcLanguageService.setMCLanguage(response);
      },
      (error) => { }
    );
  }

  getLanguage() {
    if (this.sLng != 0) {
      if (this.sLng != 1) {
        this.mcService.getTranslation(this.sLng).subscribe(
          (response: any) => {
            this.languageService.setLanguage(response);
            this.loader = false;
            this.getMcLanguage();
          },
          (error) => {
            //
            this.loader = false;
          }
        );
      }
    }
  }

  agreeOnCookie() {
    //
    localStorage.CookieAgreed = true;
    this.showCookieNotice = false;
  }

  setCssVariables() {
    console.log("Estilos:");
    console.log(this.styles.style);
    for (let cssVar in this.styles.style) {
      this.elementRef.nativeElement.style.setProperty(
        `--${cssVar}`,
        this.styles.style[cssVar] || "inherit"
      );
    }

    for (let cssVar in this.styles.style.fonts) {
      this.elementRef.nativeElement.style.setProperty(
        `--${cssVar}`,
        this.styles.style.fonts[cssVar] || "inherit"
      );
    }
  }
}
