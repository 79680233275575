import { NgModule, Injector } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrandsComponent } from "../routes/brands/brands.component";
import { NewsComponent } from "../routes/news/news.component";
import { RedirectOldComponent } from "../routes/redirect-old/redirect-old.component";
import { MediaComponent } from "../routes/media/media.component";
import { ContactComponent } from "../routes/contact/contact.component";
import { LayoutComponent } from "../layout/layout.component";
import { HeaderComponent } from "../header/header.component";
import { HeaderComponent2 } from "../header/header-2.component";
import { HeaderComponent3 } from "../header/header-3.component";
import { FooterComponent } from "../footer/footer.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { HomepageComponent2 } from "./homepage/homepage-2.component";
import { HomepageComponent3 } from "./homepage/homepage-3.component";
import { HomepageComponent4 } from "./homepage/homepage-4.component";
import { TestimonialsComponent } from "../testimonials/testimonials.component";
import { RegisterComponent } from "../register/register.component";
import { LoginComponent } from "../login/login.component";
import { NewsDetailComponent } from "./news/news-detail/news-detail.component";
import { NewsDetailComponent2 } from "./news/news-detail/news-detail-2.component";
import { NewsDetailComponent4 } from "./news/news-detail/news-detail-4.component";
import { PopupComponent } from "../shared/components/popup/popup.component";
import { DownloadComponent } from "../download/download.component";
import { SocialHomeComponent } from "../social-home/social-home.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownComponent } from "../shared/components/dropdown/dropdown.component";
import { ClickOutsideDirective } from "../shared/directives/click-outside.directive";
import { SearchComponent } from "../header/search/search.component";
import { SearchResultsComponent } from "../header/search-results/search-results.component";
import { HttpRequestService } from "../shared/services/http-request.service";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { NgxPaginationModule } from "ngx-pagination";
import { CommunicationService } from "../shared/services/communication.service";
import { LoaderComponent } from "../shared/components/loader/loader.component";
import { LoadersmallComponent } from "../shared/components/loader-small/loader.component";
import { LoaderrelativeComponent } from "../shared/components/loader-relative/loader.component";
import { McService } from "../shared/services/mc-service";
import { MCLanguageService } from "../shared/services/mc-language.service";
import { MyFilesService } from "../shared/services/my-files.service";
import { StorageService } from "../shared/services/storage.service";
import { SearchPipe } from "../shared/pipes/search.pipe";
import { TermsComponent } from "./terms/terms.component";
import { ResultsComponent } from "./results/results.component";
import { ResultsSearchComponent } from "./results/results-search/results-search.component";
import { AgmCoreModule } from "@agm/core";
import { TokenManagerService } from "../shared/services/token-manager.service";
import { AuthInterceptorService } from "../shared/services/auth-interceptor.service";
import {
  AnonymousShareGuard,
  AuthGuard,
  AuthGuardService,
  MustRegisterGuard,
} from "../shared/services/auth-guard.service";
import { ProfileComponent } from "../header/profile/profile.component";
import { EmailPopupComponent } from "../login/email-popup/email-popup.component";
import { ResetPasswordComponent } from "../login/reset-password/reset-password.component";
import { CheckAuthenticationComponent } from "../login/check-authentication/check-authentication.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginpopupComponent } from "../shared/components/loginpopup/loginpopup.component";
import { FontsAndColorsService } from "../shared/services/fonts-and-colors.service";
import { LanguageService } from "../shared/services/language.service";
import { DxDateBoxModule } from "devextreme-angular";
import { TermsPopupComponent } from "../shared/components/terms-popup/terms-popup.component";
import { GoogleAnalyticsEventsService } from "../shared/services/google-analytics-events.service";
import { ShareModule } from "ngx-sharebuttons";
import { ShareComponent } from "../shared/components/share/share.component";
import { StoryPreviewComponent } from "../shared/components/story-preview/story-preview.component";
import { ShareButtonComponent } from "../shared/components/share-button/share-button.component";
import { DebounceComponent } from "../shared/debounce/debounce.component";
import { BrandStoriesComponent } from "./brand-stories/brand-stories.component";
import { StyleDirective } from "../shared/directives/style.directive";
import { ShortenPipe } from "../shared/pipes/shorten.pipe";
import { ClipboardModule } from "ngx-clipboard";
import { ReplacePipe } from "../shared/pipes/replace.pipe";
import { ResetPasswordAdminComponent } from "../login/reset-password-admin/reset-password-admin.component";
import { RegisterImportedComponent } from "../register-imported/register-imported.component";
import { EmbeddedShareComponent } from "../shared/components/embedded-share/embedded-share.component";
import { ErrorComponent } from "../error/error.component";
import { RegisterPopupComponent } from "../shared/components/register-popup/register-popup.component";
import { MyFilesComponent } from "../header/my-files/my-files.component";
import { MediaFileComponent } from "../shared/components/media-file/media-file.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { FileHistoryComponent } from "./file-history/file-history.component";
import { DownloadBoxService } from "../shared/services/download-box.service";
import { SavedStoryService } from "../shared/services/saved-story.service";
import { CompetitionPageComponent } from "./competition-page/competition-page.component";
import { UnsubscribeNewsletterComponent } from './unsubscribe-newsletter/unsubscribe-newsletter.component';
import { SliderPictureComponent } from "../shared/components/slider-picture/slider-picture.component";
import { RerenderDirective } from "../shared/directives/rerender.directive";
import { NewsGroupComponent } from "./news/news-group/news-group.component";
import { MediaGroupComponent } from "./media/media-group/media-group.component";
import { CodeStoryComponent } from './news/code-story/code-story.component';
import { ArticleComponent } from './news/article/article.component';
const appRoutes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        canActivate: [MustRegisterGuard],
        component: (() => {
          if (localStorage.getItem("isVodafone") === "1") {
            return HomepageComponent2;
          }
          if (localStorage.getItem("isEasyJet") === "1") {
            return HomepageComponent4
          }
          return HomepageComponent;
        })(),
        pathMatch: "full",
      },
      { path: "error", component: ErrorComponent },
      { path: "sign-in", component: LoginComponent },
      { path: "sign-in/:token", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      {
        path: "competition",
        component: CompetitionPageComponent,
      },
      {
        path: "register/:oauth_verifier/:authorization_id/:twitterName",
        component: RegisterComponent,
      },
      { path: "register-imported/:code", component: RegisterImportedComponent },
      {
        path: "home",
        canActivate: [MustRegisterGuard],
        component: (() => {
          if (localStorage.getItem("isVodafone") === "1") {
            return HomepageComponent2;
          }
          if (localStorage.getItem("isEasyJet") === "1") {
            return HomepageComponent4
          }
          return HomepageComponent;
        })(),
      },
      {
        path: "home/:id/:title/:media",
        canActivate: [AnonymousShareGuard],
        component: (() => {
          if (localStorage.getItem("isVodafone") === "1") {
            return HomepageComponent2;
          }
          if (localStorage.getItem("isEasyJet") === "1") {
            return HomepageComponent4
          }
          return HomepageComponent;
        })(),
      },
      {
        path: "about-us",
        canActivate: [AuthGuard, MustRegisterGuard],
        component: AboutUsComponent,
      },
      {
        path: "contacts",
        canActivate: [MustRegisterGuard],
        component: (() => {
          if (localStorage.getItem("isVodafone") === "1") {
            return HomepageComponent2;
          }
          if (localStorage.getItem("isEasyJet") === "1") {
            return ContactsComponent
          }
          return HomepageComponent;
        })(),
        // component: ContactsComponent,
      },
      {
        path: "brands",
        canActivate: [MustRegisterGuard],
        component: BrandsComponent,
      },
      {
        path: "brands/:brand",
        canActivate: [MustRegisterGuard],
        component: BrandStoriesComponent,
      },
      {
        path: "results",
        canActivate: [MustRegisterGuard],
        component: ResultsComponent,
      },
      {
        path: "results/:id/:title/:media",
        canActivate: [AnonymousShareGuard],
        component: ResultsComponent,
      },
      {
        path: "results/:id",
        canActivate: [AnonymousShareGuard],
        component: ResultsComponent,
      },
      {
        path: "stories",
        canActivate: [AuthGuard, MustRegisterGuard],
        component: NewsComponent,
      },
      {
        path: "content-files/:id/:title/:media",
        canActivate: [AnonymousShareGuard],
        component: MediaComponent,
      },
      {
        path: "content-files",
        canActivate: [MustRegisterGuard],
        component: MediaComponent,
      },
      {
        path: "media-library/:id/:title/:media",
        canActivate: [AnonymousShareGuard],
        component: MediaComponent,
      },
      {
        path: "media-library",
        canActivate: [MustRegisterGuard],
        component: MediaComponent,
      },
      // {
      //   path: "profile",
      //   canActivate: [AuthGuardService, MustRegisterGuard],
      //   component: ProfileComponent,
      // },
      {
        // path: "file-history",
        path: "profile",
        canActivate: [AuthGuardService, MustRegisterGuard],
        component: FileHistoryComponent,
      },
      {
        path: "contact-us",
        canActivate: [MustRegisterGuard],
        component: ContactComponent,
      },
      {
        path: "testimonials",
        canActivate: [AuthGuard, MustRegisterGuard],
        component: TestimonialsComponent,
      },
      {
        path: "download",
        canActivate: [MustRegisterGuard],
        component: DownloadComponent,
      },
      {
        path: "download/share/:ids",
        canActivate: [MustRegisterGuard],
        component: DownloadComponent,
      },
      {
        path: "search",
        canActivate: [MustRegisterGuard],
        component: SearchComponent,
      },
      { path: "forgotpassword", component: EmailPopupComponent },
      {
        path: "legal",
        canActivate: [MustRegisterGuard],
        component: TermsComponent,
      },
      {
        path: "newsdetail/:id",
        canActivate: [MustRegisterGuard],
        component: (() => {
          // return localStorage.getItem("isVodafone") === "1" ? NewsDetailComponent2 : NewsDetailComponent;
          return localStorage.getItem("isEasyJet") === "1" ? NewsDetailComponent4 : NewsDetailComponent2;
          return NewsDetailComponent2;
        })(),
      },
      {
        path: "story/:id/:title",
        canActivate: [AnonymousShareGuard],
        component: (() => {
          // return localStorage.getItem("isVodafone") === "1" ? NewsDetailComponent2 : NewsDetailComponent;
          return localStorage.getItem("isEasyJet") === "1" ? NewsDetailComponent4 : NewsDetailComponent2;
          return NewsDetailComponent2;
        })(),
      },
      {
        path: "story/:id/:title/:media",
        canActivate: [AnonymousShareGuard],
        component: (() => {
          // return localStorage.getItem("isVodafone") === "1" ? NewsDetailComponent2 : NewsDetailComponent;
          return localStorage.getItem("isEasyJet") === "1" ? NewsDetailComponent4 : NewsDetailComponent2;
          return NewsDetailComponent2;
        })(),
      },
      {
        path: "story/:id",
        canActivate: [AnonymousShareGuard],
        component: (() => {
          // return localStorage.getItem("isVodafone") === "1" ? NewsDetailComponent2 : NewsDetailComponent;
          return localStorage.getItem("isEasyJet") === "1" ? NewsDetailComponent4 : NewsDetailComponent2;
          return NewsDetailComponent2;
        })(),
      },
      {
        path: "story/:id/:media",
        canActivate: [AnonymousShareGuard],
        component: (() => {
          // return localStorage.getItem("isVodafone") === "1" ? NewsDetailComponent2 : NewsDetailComponent;
          return localStorage.getItem("isEasyJet") === "1" ? NewsDetailComponent4 : NewsDetailComponent2;
          return NewsDetailComponent2;
        })(),
      },
      // https://digitalnewsagency.com/stories/12170-formula-e-partners-with-voestalpine-for-european-races
      {
        path: "stories/:id",
        canActivate: [MustRegisterGuard],
        component: RedirectOldComponent,
      },
      // https://digitalnewsagency.com/media/254056/share
      {
        path: "media/:id/share",
        canActivate: [MustRegisterGuard],
        component: RedirectOldComponent,
      },
      // https://digitalnewsagency.com//users/49390/reset_password/f30373a60686b05d10b2c594d973666f4bf9f740
      // {path: 'users/:id/reset_password/:hash', component: RedirectOldComponent},
      { path: "users", component: RedirectOldComponent },
      { path: "users/:id", component: RedirectOldComponent },
      { path: "users/:id/:ida", component: RedirectOldComponent },
      { path: "users/:id/:ida/:idb", component: RedirectOldComponent },
      { path: "users/:id/:ida/:idb/:idc", component: RedirectOldComponent },
      {
        path: "users/:id/:ida/:idb/:idc/:idd",
        component: RedirectOldComponent,
      },
      { path: "ResetPassword/:id", component: ResetPasswordComponent },
      { path: "reset-password/:id", component: ResetPasswordAdminComponent },
      {
        path: "UserAuthentication/:id",
        component: CheckAuthenticationComponent,
      },
      {
        path: "unsubscribeNewsletter/:code",
        component: UnsubscribeNewsletterComponent,
      },
      { path: "**", redirectTo: "/" },
      // {path: 'popup',  component: PopupComponent},
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    ToastrModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    DxDateBoxModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD-5r_vASKExMERIUadi_Ff0n0n67xpVQM",
    }),
    ShareModule,
    ClipboardModule,
  ],
  declarations: [
    AboutUsComponent,
    ContactsComponent,
    BrandsComponent,
    TermsPopupComponent,
    NewsComponent,
    MediaComponent,
    ContactComponent,
    LayoutComponent,
    HeaderComponent,
    HeaderComponent2,
    HeaderComponent3,
    MyFilesComponent,
    FooterComponent,
    HomepageComponent,
    HomepageComponent2,
    HomepageComponent3,
    HomepageComponent4,
    TestimonialsComponent,
    RegisterComponent,
    LoginComponent,
    NewsDetailComponent,
    NewsDetailComponent2,
    NewsDetailComponent4,
    RedirectOldComponent,
    PopupComponent,
    DownloadComponent,
    LoginpopupComponent,
    LoaderComponent,
    LoadersmallComponent,
    LoaderrelativeComponent,
    SocialHomeComponent,
    DropdownComponent,
    ClickOutsideDirective,
    StyleDirective,
    RerenderDirective,
    SearchComponent,
    SearchResultsComponent,
    SearchPipe,
    TermsComponent,
    ResultsComponent,
    ResultsSearchComponent,
    ProfileComponent,
    EmailPopupComponent,
    ResetPasswordComponent,
    CheckAuthenticationComponent,
    ShareComponent,
    EmbeddedShareComponent,
    ShareButtonComponent,
    NewsGroupComponent,
    MediaGroupComponent,
    StoryPreviewComponent,
    DebounceComponent,
    BrandStoriesComponent,
    ShortenPipe,
    ReplacePipe,
    ErrorComponent,
    RegisterPopupComponent,
    MediaFileComponent,
    FileHistoryComponent,
    CompetitionPageComponent,
    UnsubscribeNewsletterComponent,
    SliderPictureComponent,
    CodeStoryComponent,
    ArticleComponent
  ],
  exports: [RouterModule],
  providers: [
    HttpRequestService,
    HttpClient,
    ToastrService,
    McService,
    DownloadBoxService,
    MCLanguageService,
    MyFilesService,
    SavedStoryService,
    CommunicationService,
    StorageService,
    TokenManagerService,
    AuthGuardService,
    AuthGuard,
    MustRegisterGuard,
    AnonymousShareGuard,
    GoogleAnalyticsEventsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    FontsAndColorsService,
    LanguageService,
  ],
})
export class RoutesModule { }
