import {
  Component,
  Input,
  HostListener,
  NgZone,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { McService } from "../../shared/services/mc-service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "../../shared/services/storage.service";
import { UrlHelper } from "../../shared/helpers/url-helper";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { PageStyle } from "../../models/style/style.model";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { Location } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { ButtonsHelper } from "../../shared/helpers/buttons-helper";
import { MyFilesService } from "../../shared/services/my-files.service";
import { WebNewsModel } from "../../routes/news/news-model";
import { WebManageMediaModel } from "../../download/download-model";
import { MyFilesModel } from "../../shared/services/my-files-model";

@Component({
  selector: "app-search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.less"],
})
export class SearchResultsComponent implements OnInit {
  @Input() searchResultStories: any;
  @Input() searchResultMedia: any;
  @Input() searchFrom: any;
  @Input() template: number;
  @Output() dataEvent = new EventEmitter<Number>();
  buttonsHelper = ButtonsHelper;
  resultStories: any;
  resultMedia: any = [];
  autoplay: boolean;
  mediaId: number;
  popup: boolean;
  loader: boolean;
  storyId: number;
  addDropdown: boolean;
  user: any;
  headerFooter: any;
  styles = new PageStyle();
  socials: string[] = [];
  currentUrl: string;
  language: Language;
  mediaTop: number;
  pageStories = 1;
  pageSizeStories = 12;
  maxPageStories = 1;
  totalCountStories: number;
  searchResults: number;

  grid: string;
  itemsPerPage = 12;
  newsHeadlineLength: number = 120;
  pageMedia = 1;
  pageSizeMedia = 12;
  maxPageMedia = 1;
  totalCountMedia: number;
  searchModel: any;
  loaderMedia = true;
  loaderStory = true;
  lazyLoadingLoader = false;
  showingStories = true;
  showLoadMore = true;
  cart: MyFilesModel = new MyFilesModel([], []);

  constructor(
    private mcService: McService,
    private router: Router,
    private languageService: LanguageService,
    private storageService: StorageService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    public fontsAndColorsService: FontsAndColorsService,
    private location: Location,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    ngZone: NgZone,
    private myFilesService: MyFilesService
  ) {
    this.myFilesService.files.subscribe((myFiles) => {
      this.cart = myFiles;
    });
    this.newsHeadlineLength = 90;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 65;
    }
    if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
      this.newsHeadlineLength = 12;
    }
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    this.route.params.subscribe((params: Params) => {
      const id: string = params["id"];
      const title: string = params["title"];
      let media: string = params["media"];

      if (media && media.indexOf("?") > -1)
        media = media.substr(0, media.indexOf("?"));

      if (media) {
        this.openPopup(+media);
      }
    });

    window.onresize = (e) => {
      ngZone.run(() => {
        this.getDimensions();
      });
    };
  }

  addToMyFiles(media) {
    let component = this;
    if (component.cart.isInMyFiles(media.id, media.isPublished)) {
      component.myFilesService.pushMyFiles(media.id, media.isPublished);
    }
  }

  download(media) {
    media.downloading = true;
    this.getMediaDetail(media);
  }

  allResults() {
    this.router.navigateByUrl("/results");
  }

  getMediaDetail(media) {
    let language = Number(localStorage.getItem("lng"));
    let mediaDetailResponseModel;
    this.mcService.getMediaDetail(media.id, language).subscribe(
      (response: any) => {
        mediaDetailResponseModel = response;
        if (
          mediaDetailResponseModel.mustRegisterToAccess &&
          !mediaDetailResponseModel.anonymousShare &&
          !localStorage.getItem("Token")
        ) {
          sessionStorage.setItem("redirectUrl", "/" + window.location.pathname);
          this.router.navigateByUrl("/sign-in");
        }
        if (mediaDetailResponseModel.downloadLinks.length > 0) {
          this.downloadMedia(
            mediaDetailResponseModel.downloadLinks[0],
            mediaDetailResponseModel,
            media
          );
        }
      },
      (error) => {
        media.downloading = false;
      }
    );
  }

  downloadMedia(download, mediaDetailResponseModel, media) {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (iOS) {
      try {
        (window as any).webkit.messageHandlers.downloadClicked.postMessage({
          url: download.downloadUrl,
        });
      } catch (err) {
        media.downloading = false;
      }

      return;
    }
    const component = this;
    download.downloadActive = true;
    this.http.get(download.downloadUrl, { responseType: "blob" }).subscribe(
      (blob: Blob) => {
        const filename = download.downloadUrl.split("/")[8];
        saveAs(blob, filename);
        media.downloading = false;
        if (component.user.cmsUser == null) {
          component.addDownload(media.id);
        }
      },
      (error) => {
        media.downloading = false;
      }
    );
  }

  addDownload(mediaId) {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    let niz: any = {};
    niz.mediaIds = [mediaId];
    niz.userId = this.user.id;
    niz.mediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId ? niz.userId.toString() : ""
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  updateMedia(e) {
    for (const media of this.resultMedia.media) {
      if (media.id === e.id) {
        media.downloads = e.downloads;
        media.shares = e.shares;
        media.views = e.views;
      }
    }
  }

  receiveDataFromChild(event) {
    this.dataEvent.emit(event);
  }

  openPopup(id: number) {
    this.popup = true;
    this.mediaId = id;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.getDimensions();
  }

  ngOnInit() {
    this.cart = this.myFilesService.getMyFiles();
    this.loaderMedia = true;
    this.loaderStory = true;
    this.headerFooter = JSON.parse(localStorage.getItem("headerfooter"));
    if (this.headerFooter != null && this.headerFooter != undefined) {
      this.headerFooter.headerLinks.forEach((x) => (x.active = false));
      this.headerFooter.footerLinks.forEach((x) => (x.active = false));
    }
    this.storageService.getSearchModel().subscribe((searchModel) => {
      this.searchModel = searchModel;
      this.grid = this.searchModel.stories ? "Columns3" : "Columns4";
      if (window.innerWidth <= 1100) {
        this.grid = "Columns2";
      }
    });

    this.user = TokenManagerService.getUser();

    this.storageService.getSearchItemStories().subscribe((term) => {
      this.loaderStory = true;
      this.pageStories = this.searchModel.page;
      this.currentUrl = window.location.href;
      this.resultStories = term;
      this.maxPageStories = Math.ceil(term.totalCount / this.pageSizeStories);
      if (this.resultStories.socialSharing != null) {
        this.socials = this.resultStories.socialSharing;
      }
      this.loaderStory = false;
      // setTimeout(() => {
      // }, 3000);
    });

    this.storageService.getSearchItemMedia().subscribe((term) => {
      this.loaderMedia = true;
      this.pageMedia = this.searchModel.page;
      this.currentUrl = window.location.href;
      this.resultMedia = term;
      this.maxPageMedia = Math.ceil(term.totalCount / this.itemsPerPage);
      if (this.resultMedia.media) {
        for (let f of this.resultMedia.media) {
          if (f.mediaType === "Audio" || f.mediaType === "Video") {
            f.mediaLengthMin = Math.floor(f.mediaLenght / 60);
            f.mediaLengthSec = f.mediaLenght - f.mediaLengthMin * 60;
          }
        }
      }

      if (this.resultMedia.socialSharing != null) {
        this.socials = this.resultMedia.socialSharing;
      }
      if (this.resultMedia.media != undefined) {
        const niz = this.resultMedia.media;
        for (const x of niz) {
          if (x.mediaType === "Document") {
            x.thumbUrl = "assets/images/document_default.png";
          } else if (x.mediaType === "Audio") {
            x.thumbUrl = "assets/images/audio_default.png";
          }
        }
      } else {
        // setTimeout(() => {
        this.loaderMedia = false;
        // }, 3000);

        return;
      }
      // setTimeout(() => {
      this.loaderMedia = false;
      this.getDimensions();
      // }, 3000);
    });
  }

  getDimensions() {
    const mediaPosition = document.getElementById(
      "media-wrapper"
    ) as HTMLElement;
    this.mediaTop = mediaPosition?.offsetTop;
    if (window.outerWidth < 768) {
      this.searchResults = 6;
    } else {
      this.searchResults = 10;
    }
  }

  showSubCategory(news) {
    news.subCategory = !news.subCategory;
  }

  getStoryUrl(storyId) {
    return (
      this.currentUrl.replace("results", "story") +
      "/" +
      storyId +
      "/" +
      UrlHelper.getUrlTitle(
        this.resultStories.stories.find((x) => x.staggingStoryId == storyId)
          .storyTitle
      )
    );
  }

  readMore(id) {
    //window.scrollTo(0, 0);
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "story/" + id.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
    }
    this.router.navigate([
      "/story/" +
      id.staggingStoryId +
      "/" +
      UrlHelper.getUrlTitle(
        this.resultStories.stories.find(
          (x) => x.staggingStoryId == id.staggingStoryId
        ).storyTitle
      ),
    ]);
  }

  openDropdown(id) {
    for (const x of this.resultStories.stories) {
      if (x.storyId === id) {
        x.dropdown = !x.dropdown;
      }
    }
  }

  openMediaPopup(media) {
    this.autoplay = false;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
    this.location.replaceState(media.relativeShareUrl);
  }

  openMediaPopupAutplay(media) {
    this.autoplay = true;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
    this.location.replaceState(media.relativeShareUrl);
  }
}
