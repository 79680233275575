<section class="news-page flex-center">
  <div class="gray-background"></div>
  <div
    class="content-wrapper"
    [ngStyle]="{
      'padding-bottom':
        itemsPerPage < webListResponse.news?.length ? '' : '100px'
    }"
  >
    <div class="news-feed-container">
      <div class="news-bar flex-center">
        <h1 class="hidden-h1">{{ clientName }}</h1>
        <h2 class="section-headline">
          <img
            class="brands-logo-img"
            src="{{ webListResponse.logoUrl }}"
            alt="Brand Logo"
          />
        </h2>
        <!-- <div class="white-triangle"></div>
        <div class="news-picker flex-center">
          <div
            (click)="fourGrid()"
            [ngClass]="grid == 'Columns4' ? 'active' : ''"
            class="four-columns flex-center"
          >
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns4'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th first"
            ></i>
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns4'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th second"
            ></i>
          </div>
          <div
            (click)="threeGrid()"
            [ngClass]="grid == 'Columns3' ? 'active' : ''"
            class="three-columns flex-center"
          >
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns3'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th"
            ></i>
          </div>
          <div
            (click)="twoGrid()"
            [ngClass]="grid == 'Columns2' ? 'active' : ''"
            class="two-columns flex-center"
          >
            <i
              [ngStyle]="{
                color:
                  styles.style && grid == 'Columns2'
                    ? styles.style.mainIdentifyingColor
                    : ''
              }"
              class="fal fa-th-large"
            ></i>
          </div>
        </div> -->
      </div>
      <div class="news-feed flex">
        <!-- <ng-container
          *ngFor="
            let news of webListResponse.news
              | paginate
                : {
                    itemsPerPage: itemsPerPage,
                    currentPage: p,
                    totalItems: webListResponse.totalCount
                  };
            index as i
          "
        >
          <app-story-preview
            [story]="news"
            [config]="webListResponse"
            [gridClass]="{
              'news-two-columns': grid == 'Columns2',
              'news-three-columns': grid == 'Columns3',
              'news-four-columns': grid == 'Columns4'
            }"
            [index]="i"
            [grid]="grid"
          >
          </app-story-preview>
        </ng-container> -->
        <ng-container
          *ngFor="
            let group of storyGroups
              | paginate
                : {
                    itemsPerPage: itemsPerPage / 4,
                    currentPage: p,
                    totalItems: webListResponse.totalCount / 4
                  };
            index as i
          "
        >
          <app-news-group
            class="group"
            [group]="group"
            [config]="webListResponse"
            [mirrored]="i % 2 != 0"
          ></app-news-group>
        </ng-container>
      </div>

      <div class="gray-triangle"></div>
    </div>
    <pagination-controls
      maxSize="6"
      previousLabel=""
      nextLabel=""
      class="paggination flex-center"
      *ngIf="!loader && itemsPerPage < webListResponse.totalCount"
      (pageChange)="changePage($event); setColor($event)"
    >
    </pagination-controls>
  </div>
</section>

<app-loader *ngIf="loader"></app-loader>
