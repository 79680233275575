import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { LinksModel } from "../models/links/links.model";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterState,
} from "@angular/router";
import { LayoutModel } from "../layout/layout-model";
import { PageStyle } from "../models/style/style.model";
import { FontsAndColorsService } from "../shared/services/fonts-and-colors.service";
import { StorageService } from "../shared/services/storage.service";
import { TokenManagerService } from "../shared/services/token-manager.service";
import { UserModel } from "../login/login-model";
import { Language } from "../models/language/language.model";
import { LanguageService } from "../shared/services/language.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.less"],
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() layoutResponseModel: LayoutModel = new LayoutModel();
  @Input() clientName: string;
  @Input() customClasses: string;
  @Input() isEasyJet: boolean;
  logo = "";
  styles: PageStyle;
  loggedUserName = "";
  anonymousShare: boolean;
  signIn: boolean;
  mustRegister: boolean;
  currentYear = new Date().getFullYear();
  socialLinkObjects: any;
  user: UserModel;
  language: Language;
  legalBanner: string;
  showLegalBanner: boolean = localStorage.getItem('legalBannerAgreed') == '1' ? false : true;

  constructor(
    private router: Router,
    private fontsAndColorsService: FontsAndColorsService,
    private storageService: StorageService,
    private languageService: LanguageService,
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    //
    const component = this;

    this.storageService.getMustRegister().subscribe((mustRegister) => {
      component.mustRegister = mustRegister;
    });

    this.storageService.getAnonymousShare().subscribe((anonymousShare) => {
      component.anonymousShare = anonymousShare;
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        component.signIn =
          component.router.routerState.snapshot.url.indexOf("sign-in") > -1;
      }
    });
  }

  ngOnChanges() {
    if (
      this.layoutResponseModel !== undefined &&
      this.layoutResponseModel !== null
    ) {
      this.socialLinkObjects = JSON.parse(
        this.layoutResponseModel.socialLinkJson
      );
      if (
        this.socialLinkObjects != null &&
        this.socialLinkObjects !== undefined
      ) {
        this.socialLinkObjects = this.socialLinkObjects.map((o) => {
          o.name = o.name.toLowerCase();
          o.link = this.checkLinkPrefix(o.link);
          return o;
        });
      } else {
        this.socialLinkObjects = [];
      }
    }
  }

  getLinkForUrl(url) {
    let r = url.indexOf("http") > -1 ? url : `/${url}`;
    console.log(r);
    return r;
  }

  isOutsideUrl(url) {
    return url.indexOf("http") > -1;
  }

  ngOnInit() {
    this.user = TokenManagerService.getUser();
    this.storageService.getName().subscribe((term) => {
      if (!term) {
        this.loggedUserName = "";
      } else {
        this.loggedUserName = term;
      }
    });
    this.storageService.getLegalBanner().subscribe((term) => {
      if (!term) {
        this.legalBanner = "";
      } else {
        this.legalBanner = term;
      }
    });
  }

  closeLegalBanner() {
    localStorage.setItem('legalBannerAgreed', '1');
    this.showLegalBanner = false;
  }

  checkLinkPrefix(link: string) {
    if (link.includes("http://") || link.includes("https://")) {
      return link;
    } else {
      return `http://${link}`;
    }
  }
  /*
  changeColor(e, color, link) {
    if (!link.active) {
      e.target.style.color = color;
    }
  }

  changeColorr(e, link) {
    if (!link.active) {
      e.target.style.color = this.styles.style.mainIdentifyingColor;
    } 
  }
*/
  changeColor(e, color) {
    e.target.style.color = color;
  }

  reroute(url) {
    //
    this.layoutResponseModel.headerLinks.forEach((x) => (x.active = false));
    const index = this.layoutResponseModel.footerLinks.indexOf(url);
    if (url.url.indexOf("http") > -1) {
      window.open(url.url, "_blank");
    } else {
      this.layoutResponseModel.footerLinks.forEach((x) => (x.active = false));
      url.active = true;
      this.router.navigate(["/" + url.url]);
    }
  }
}
