import { Component, Input, OnInit } from '@angular/core';
import { SavedStoryService } from '../../../shared/services/saved-story.service';
import { HttpClient } from '@angular/common/http';
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { TokenManagerService } from '../../../shared/services/token-manager.service';
import { DownloadAddToModel } from '../../media/media-model';
import { McService } from '../../../shared/services/mc-service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { UrlHelper } from '../../../shared/helpers/url-helper';
import { Router } from '@angular/router';
import { LanguageService } from "../../../shared/services/language.service";
import { Language } from "../../../models/language/language.model";

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.less']
})
export class ArticleComponent implements OnInit {

  @Input() story: any;
  @Input() mustRegisterToDownload: boolean;
  @Input() anonymousShare: boolean;
  @Input() marketName: string = "";

  language: Language;

  constructor(private languageService: LanguageService, private savedStoryService: SavedStoryService, private http: HttpClient, private mcService: McService, private googleAnalytics: GoogleAnalyticsEventsService, private router: Router) {
    this.language = this.languageService.getLanguage();
  }

  ngOnInit(): void {
    console.log(this.story);
  }

  toggleSavedStory(storyId) {
    if (this.inSavedStories(storyId))
      this.savedStoryService
        .remove(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
    else
      this.savedStoryService
        .add(storyId)
        .then((response: any) => { })
        .catch((error) => {
          console.error(error);
        });
  }

  inSavedStories(storyId) {
    return this.savedStoryService.inSavedStories(storyId);
  }


  addDownload(downloadIds) {
    const user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      user.id = null;
    }
    let niz = new DownloadAddToModel();
    niz.storyMediaIds = downloadIds;
    niz.publishedMediaIds = [];
    niz.userId = user.id;
    niz.storyMediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId ? niz.userId.toString() : ""
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  readMore(story) {
    window.scrollTo(0, 0);
    const user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      user.id = null;
    }
    if (user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "view",
        "story/" + story.staggingStoryId,
        "view",
        user.id ? user.id.toString() : "anonymous"
      );
    }
    this.router.navigateByUrl(
      `/story/${story.staggingStoryId}/${UrlHelper.getUrlTitle(
        story.storyTitle
      )}`
    );
    if (window.location.pathname === "/") {
      this.googleAnalytics.emitArticleEvent(
        "homepage",
        story.staggingStoryId
      );
    }
    this.googleAnalytics.emitReadMoreEvent(
      "grid",
      story.storyTitle,
      `/story/${story.staggingStoryId}/${UrlHelper.getUrlTitle(
        story.storyTitle
      )}`
    );
  }


  download(urls, downloadIds) {
    if (
      this.mustRegisterToDownload &&
      !this.anonymousShare &&
      localStorage.getItem("Token") === null
    ) {
      this.router.navigate(["/sign-in"]);
      return;
    } else {
      let component = this;
      var zip = new JSZip();
      var count = 0;
      var zipFilename = "download.zip";
      var user = TokenManagerService.getUser();

      if (urls.length > 1) {
        urls.forEach(function (url) {
          var filename = url.split("/")[8];
          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err) {
              throw err;
            }
            zip.file(filename, data, { binary: true });
            count++;
            if (count == urls.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, zipFilename);

                if (user.cmsUser == null) {
                  component.addDownload(downloadIds);
                }
              });
            }
          });
        });
      } else {
        for (const x of urls) {
          this.http
            .get(x, { responseType: "blob" })
            .toPromise()
            .then(
              (blob: Blob) => {
                let filename = x.split("/")[8];
                saveAs(blob, filename);
                if (user.cmsUser == null) {
                  component.addDownload(downloadIds);
                }
              },
              (error) => { }
            );
        }
      }
    }
  }

}
