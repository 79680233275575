<div *ngIf="!loader">
  <section class="hero-banner">
    <div class="main-story" [backgroundUrl]="backgroundUrl"
      [ngStyle]="{ 'background-image': 'url(' + backgroundUrl + ')' }">
      <div class="image-opacity"></div>
      <div class="main-headline">
        <div class="meta flex">
          <div *ngIf="showCategory" class="category-wrapper" (click)="showSubCategory(selectedFs)">
            <div class="category" [ngClass]="selectedFs?.category.length > 1 ? 'has-child' : ''">
              <div>
                {{ selectedFs?.category[0] }}
              </div>
            </div>
            <div class="category-dropdown" *ngIf="selectedFs?.dropdown && selectedFs?.category.length > 1">
              <div *ngFor="
                  let category of selectedFs?.category
                    | slice : 1 : selectedFs?.category.length
                ">
                {{ category }}
              </div>
            </div>
          </div>
        </div>
        <h1>
          {{ selectedFs?.storyTitle }}
        </h1>
        <h4>
          {{ selectedFs?.shortDescription }}
        </h4>
        <div class="headline-buttons">
          <div class="main-read-more flex-center" appStyle
            (click)="readMore(selectedFs, selectedFs?.storyTitle, selectedFs)">
            {{ language.readMore }}
          </div>
          <div class="story-nav-container">
            <div class="story-nav" (click)="prevSlide()">
              <i class="far fa-angle-left"></i>
            </div>
            <div class="story-nav" (click)="nextSlide()">
              <i class="far fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="featured-stories-container">
      <ng-container *ngFor="let fstory of nextFStories; let i = index" (click)="changeStory(fstory, i)">
        <app-story-preview [story]="fstory" [config]="homeModelResponse"
          [customClasses]="'half-height'"></app-story-preview>
      </ng-container>
    </div>
  </section>

  <section class="news flex-center">
    <div class="content-wrapper">
      <ng-container>
        <div class="news-feed" *ngIf="homeModelResponse.latestStories.length != 0">
          <h2 class="section-headline reset-margin-top">
            {{ language.latestStory }}
          </h2>
          <ng-container *ngFor="let group of storyGroupings; let i = index">
            <app-news-group [group]="group" [config]="homeModelResponse" [mirrored]="i % 2 != 0"></app-news-group>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </section>

  <section *ngIf="brands && brands.length > 0" class="brands">
    <div class="content-wrapper">
      <h2 class="section-headline reset-margin-top">
        {{ language.brands }}
      </h2>
      <div class="brand-nav-container">
        <div class="brand-nav" (click)="moveLeftBrands()">
          <i class="far fa-angle-left"></i>
        </div>
        <div class="brand-nav" (click)="moveRightBrands()">
          <i class="far fa-angle-right"></i>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="overflow-container">
        <div #brandsContainer id="brands-container" class="brand-wrapper related-centers-wrapper flex"
          [ngStyle]="{ 'left.px': -brandsCurrentScroll }">
          <a class="brand-box" *ngFor="let brand of brands" (click)="goToMc(brand.mediaCenterUrl, brand.id)"
            (mouseenter)="brand.brandHovered = true" (mouseleave)="brand.brandHovered = false">
            <div class="brand-image flex-center">
              <img src="{{ brand.logoUrl }}" alt="{{ brand.name }} Logo" />
            </div>
            <div class="opacity-fill" *ngIf="brand.brandHovered"></div>
            <div class="brand-name flex-center">
              {{ brand.brandHovered ? language.visit : brand.name }}
            </div>
          </a>
        </div>
      </div>
    </ng-container>
  </section>
</div>

<app-popup *ngIf="popup" [(hideCounters)]="homeModelResponse.hideCounters" [(popup)]="popup" [mediaId]="mediaId"
  [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>