import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { McService } from "../../services/mc-service";
import { GoogleAnalyticsEventsService } from "../../services/google-analytics-events.service";
import { PageStyle } from "../../../models/style/style.model";
import { Language } from "../../../models/language/language.model";
import { LanguageService } from "../../services/language.service";
import { WebNewsDetailsMediaModel } from "../../../routes/news/news-model";
import { HttpClient } from "@angular/common/http";
import { TokenManagerService } from "../../services/token-manager.service";
import { DownloadAddToModel } from "../../../routes/media/media-model";
import { ButtonsHelper } from "../../helpers/buttons-helper";
import { MyFilesService } from "../../services/my-files.service";
import { WebpHelperService } from "../../services/webp-helper.service";
import { Router } from "@angular/router";
import { MyFilesModel } from "../../services/my-files-model";
@Component({
  selector: "app-media-file",
  templateUrl: "./media-file.component.html",
  styleUrls: ["./media-file.component.less"],
})
export class MediaFileComponent implements OnInit {
  @Input() media: WebNewsDetailsMediaModel;
  @Input() config: any;
  @Input() gridClass: any = {};
  @Input() index: any = 0;
  @Input() grid: any = "";
  @Input() fullWidth: any = false;
  @Input() showExternally: any = false;
  @Input() full: boolean = false;
  @Input() newsDetail: boolean = false;
  @Input() customClasses: string;
  @Input() template: number;
  @Output() dataEvent = new EventEmitter<any>();

  additionalClasses: any = [];
  styles = new PageStyle();
  language: Language;
  popup: boolean;
  autoplay: boolean;
  loader = false;
  loginPopup = false;
  downloading = false;
  mediaId: number;
  disabledButton: boolean;
  user: any;
  buttonsHelper = ButtonsHelper;
  cartItems: MyFilesModel = new MyFilesModel([], []);
  shareUrl: string;

  constructor(
    private http: HttpClient,
    private mcService: McService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    private myFilesService: MyFilesService,
    private languageService: LanguageService,
    public webPHelper: WebpHelperService
  ) {
    this.language = languageService.getLanguage();
  }

  ngOnInit() {
    this.user = TokenManagerService.getUser();
    this.cartItems = this.myFilesService.getMyFiles();
    if (this.cartItems && this.cartItems.isInMyFiles(this.media.id, this.media.isPublished)) {
      this.media.addedToCart = true;
    } else {
      this.media.addedToCart = false;
    }
    this.isPublished = this.media.isPublished;
    //current url (not the route http://localhost:4200/media-library => http://localhost:4200/)+ media.relativeShareUrl
    this.shareUrl = window.location.origin + this.media.relativeShareUrl;
    this.drawGrid();
  }

  ngOnChanges() {
    this.drawGrid();
  }

  getSource(media: WebNewsDetailsMediaModel) {
    if (media.fullHDUrl) return media.fullHDUrl;
    return media.thumbUrl;
  }

  drawGrid() {
    this.additionalClasses = this.gridClass;
    this.additionalClasses["media-box"] = true;
  }

  isPublished: boolean = false;

  openMediaPopup(autoplay = false) {
    this.autoplay = autoplay;
    this.mediaId = this.media.id;
    this.isPublished = this.media.isPublished;
    this.popup = true;
    this.media.views++;

    if (this.showExternally) {
      console.log(this.media);
      this.dataEvent.emit({ mediaId: this.mediaId, isPublished: this.isPublished });
    }
  }

  handleImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = "/src/assets/files/placeholder.jpg";
  }

  onLogin(state) {
    if (state) {
      this.download();
    }
  }

  download() {
    if (
      this.config.mustRegisterToDownload &&
      !this.config.anonymousShare &&
      localStorage.getItem("Token") === null
    ) {
      this.loginPopup = true;
      return;
    } else {
      let component = this;
      component.disabledButton = true;
      this.downloading = true;
      this.http
        .get(this.media.downloadLink, { responseType: "blob" })
        .toPromise()
        .then(
          (blob: Blob) => {
            let filename = this.media.name;
            saveAs(blob, filename);
            this.downloading = false;
            if (component.user.cmsUser == null) {
              component.addDownload();
            }
            this.googleAnalytics.emitEvent(
              "FileDownload",
              "FileDownload",
              "Download",
              filename + " | " + this.config.storyTitle
            );
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  addDownload() {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    let niz = new DownloadAddToModel();
    if (this.isPublished) {
      niz.publishedMediaIds = [this.media.id];
      niz.storyMediaIds = [];
      niz.publishedMediaIds.forEach((mediaId) => {
        this.googleAnalytics.emitEvent(
          "media/" + mediaId,
          "download-pm",
          niz.userId ? niz.userId.toString() : ""
        );
      });
    } else {
      niz.storyMediaIds = [this.media.id];
      niz.publishedMediaIds = [];
      niz.storyMediaIds.forEach((mediaId) => {
        this.googleAnalytics.emitEvent(
          "media/" + mediaId,
          "download",
          niz.userId ? niz.userId.toString() : ""
        );
      });
    }

    niz.userId = this.user.id;
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        console.error(error);
      }
    );
  }

  sendToCart() {
    if (
      this.config.mustRegisterToDownload &&
      !this.config.anonymousShare &&
      localStorage.getItem("Token") === null
    ) {
      this.loginPopup = true;
      return;
    }
    console.log(this.media);
    if (!!this.cartItems && !this.cartItems.isInMyFiles(this.media.id, this.media.isPublished)) {
      this.media.addedToCart = true;
      this.media.checked = false;
      this.myFilesService.pushMyFiles(this.media.id, this.media.isPublished);
      this.cartItems = this.myFilesService.getMyFiles();
    }
  }
}
