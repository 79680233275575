export class BrandsModel {
  public customCategories: LookupModel[];
  public mainCategories: LookupModel[];
  public clients: BrandsManageModel[];
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
  public mcType: any;
}

export class LookupModel {
  public parentId: number;
  public id: number;
  public label: string;
}

export class BrandsManageModel {
  public logoUrl: string;
  public mediaCenterUrl: string;
  public name: string;
  public customCategories: LookupModel[];
  public mainCategories: LookupModel[];
  public brandHovered: boolean;
}
