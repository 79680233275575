<footer class="flex-center" [ngClass]="isEasyJet ? 'invert-colors' : ''">
  <div class="footer-wrap">
    <div class="dna flex-center">
      <div class="sign-up-container" *ngIf="user == null || true">
        <a href="/sign-in">
          <h2>{{ language.signUpToday }}</h2>
          <h4>{{ language.newsAlert }}</h4>
        </a>
      </div>
      <div class="links-container">
        <div class="links" *ngIf="
            !(anonymousShare && loggedUserName == '') &&
            !signIn &&
            (!mustRegister || loggedUserName.length > 0)
          ">
          <!-- <div class="links-title">Pages</div> -->
          <ng-container *ngFor="
              let link of layoutResponseModel?.footerLinks;
              let ind = index
            ">
            <a *ngIf="!isOutsideUrl(link.url)" class="link" routerLinkActive="active" routerLink="{{ link.url }}"
              (click)="reroute(link)">{{ link.link }}</a>
            <a *ngIf="isOutsideUrl(link.url)" class="link" routerLinkActive="active" href="{{ link.url }}"
              target="_blank" (click)="reroute(link)">{{ link.link }}</a>
          </ng-container>
          <a href="#" onClick="UC_UI.showSecondLayer();">{{ language.privacySettings }}</a>
          <a *ngIf="isEasyJet" routerLinkActive="active" routerLink="/contacts">Contacts</a>
        </div>
        <div *ngIf="layoutResponseModel?.showFooterSocialLinks" class="links">
          <div class="links-title">Social</div>
          <span *ngFor="let object of socialLinkObjects">
            <a *ngIf="object.checked" class="link" target="_blank" [href]="object.link">
              {{ object.name }}
            </a>
          </span>
        </div>
      </div>
      <div *ngIf="isEasyJet" class="powered-by">Powered by<img src="../../assets/images/dna-logo-white.svg" /></div>
    </div>
    <div class="copyright">
      <div>© {{ currentYear }} {{ clientName }}</div>
    </div>
  </div>
</footer>
<div class="legalBannerBackground" *ngIf="legalBanner && showLegalBanner">
  <div class="legalBanner">
    <h2>{{ language.legalBanner || 'Legal' }}</h2>
    <p>
      {{ legalBanner }}
    </p>
    <button class="generic-button" (click)="closeLegalBanner()">Okay</button>
  </div>
</div>