<div class="search-wrapper flex-center" [ngClass]="{ open: search }" (click)="handleClick($event)">
  <div class="search-box flex">
    <input autocomplete="off" id="story-search-big" [ngClass]="{ showingResults: search && showingResults }"
      type="search" placeholder="{{ language.searchHere }}" [(ngModel)]="searchModelData.searchModel.searchText"
      (input)="setSearchString()" />
    <div class="results-container" [ngClass]="{
        show:
          search &&
          showingResults &&
          (searchResultStories?.stories || searchResultMedia?.media)
      }">
      <div class="buttons-wrapper flex">
        <div class="search-type-button empty" [ngClass]="{ active: searchModelData.searchModel?.stories }"
          (click)="showStories()">
          Stories
        </div>
        <div class="search-type-button empty" [ngClass]="{ active: searchModelData.searchModel?.media }"
          (click)="showMedia()">
          Media Library
        </div>
      </div>
      <app-search-results [template]="template" [(searchResultStories)]="searchResultStories"
        [(searchResultMedia)]="searchResultMedia" [searchFrom]="searchFrom"
        (dataEvent)="receiveDataFromChild($event)"></app-search-results>
    </div>
    <div *ngIf="loader" id="loader">
      <img src="../assets/icons/loaderTransparent.gif" class="loader-gif" />
    </div>
  </div>

  <app-popup *ngIf="popup" [(hideCounters)]="searchResultMedia.hideCounters" [(popup)]="popup" [mediaId]="mediaId"
    [isPublished]="isPublished"></app-popup>
</div>