
import { map, take } from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UserAuthModel } from "../login-model";
import { MyFilesService } from '../../shared/services/my-files.service';
import { StorageService } from '../../shared/services/storage.service';

@Component({
  selector: "app-check-authentication",
  templateUrl: "./check-authentication.component.html",
  styleUrls: ["./check-authentication.component.less"],
})
export class CheckAuthenticationComponent implements OnInit {
  data = new UserAuthModel();
  isAuthenticated: any;

  constructor(
    private authLoginService: TokenManagerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private myFilesService: MyFilesService,
    private sService: StorageService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let userId = params["id"];
      this.data.code = userId;
    });
    this.authLoginService.checkAuthentication(this.data).subscribe(
      (response) => {
        const loggedUserName = TokenManagerService.getUser().fullName;
        this.sService.setName(loggedUserName);
        const files = this.myFilesService.getFiles();
        this.myFilesService.setMyFiles(files);
        if (response) {
          this.isAuthenticated = true;
        }
        if (this.isAuthenticated != undefined) {
          setTimeout(() => {
            this.redirect();
          }, 5000);
        }
      },
      (error) => {
        this.isAuthenticated = false;
      }
    );
  }

  redirect() {
    this.router.navigateByUrl("/");
  }
}
