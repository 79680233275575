import { catchError, map } from "rxjs/operators";

import { throwError as observableThrowError, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpRequestService } from "./http-request.service";
import { EmailModel } from "../../routes/contact/contact-model";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
} from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ToastrConfig } from "../helpers/toastr-config";
import { DownloadAddToModel } from "../../routes/media/media-model";

@Injectable()
export class McService {
  constructor(
    private http: HttpRequestService,
    private httpClient: HttpClient,
    private toastr: ToastrService
  ) { }

  private getNewsUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebGetNews";
  private getClientInfoUrl = environment.apiUrl + "/MediaCenterWeb/GetClientInfo";
  private getMediaUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebGetMedia";
  private getContactUsUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebContactUs";
  private getHomepageUrl =
    environment.apiUrl + "/MediaCenterWeb/GetMediaCenterWebHome";
  private getHomepageUrlMedia =
    environment.apiUrl + "/MediaCenterWeb/GetMediaCenterWebHomeMedia";
  private getHomepageUrlStoriescategory =
    environment.apiUrl + "/MediaCenterWeb/GetMediaCenterWebHomeStoriesCategory";
  private getHomepageLatestStoriesUrl =
    environment.apiUrl + "/MediaCenterWeb/GetMediaCenterWebHomeLatestStories";
  private getHomepageInitialUrl =
    environment.apiUrl + "/MediaCenterWeb/GetMediaCenterWebHomeInitial";
  private getHeaderFooterUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebHeaderFooterLinks";
  private getTermsUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebTermsAndCondition";
  private getRegisterUrl =
    environment.apiUrl + "/MediaCenterUser/GetForRegister";
  private getSearchUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebGetForFilter";
  private redirectToCmsUrl =
    environment.apiUrl + "/MediaCenterUser/RedirectToCMS";
  private registerUrl = environment.apiUrl + "/MediaCenterUser/Register";
  private registerFbUrl =
    environment.apiUrl + "/MediaCenterUser/RegisterWithFacebook";
  private getUpdateUrl = environment.apiUrl + "/MediaCenterUser/GetForUpdate";
  private getTestimonialsUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebTestimonials";
  private updateUrl = environment.apiUrl + "/MediaCenterUser/UpdateUser";
  private facebookUrl =
    environment.apiUrl + "/MediaCenterUser/LoginWithFacebook";
  private addSharedToNewsUrl =
    environment.apiUrl + "/MediaCenterWeb/AddShareToNews";
  private addSharedToMediaUrl =
    environment.apiUrl + "/MediaCenterWeb/AddShareToMedia";
  private addViewToMediaUrl =
    environment.apiUrl + "/MediaCenterWeb/AddViewToMedia";
  private addDownloadToMediaUrl =
    environment.apiUrl + "/MediaCenterWeb/AddDownloadToMedia";
  private getNewsDetailUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebGetNewsDetail";
  private getMediaDetailUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebMediaDetail";
  private webDownloadboxUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebDownloadBox";
  private webGetBrandsUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebGetBrands";
  private sendEmailUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebContactUsSendEmail";
  private searchMcStoriesUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebSearchStories";
  private searchMcStoriesWithCodesUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebSearchStoriesWithCode";
  private searchMcMediaUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebSearchMedia";
  private getStyleUrl =
    environment.apiUrl + "/MediaCenterWeb/GetFontsAndColorsForWeb";
  private getMetadatasUrl =
    environment.apiUrl + "/MediaCenterWeb/MediaCenterWebGetMetadatas";
  private registerWithTwitterUrl =
    environment.apiUrl + "/MediaCenterUser/RegisterWithTwitter";
  private getUserByTokenUrl =
    environment.apiUrl + "/MediaCenterUser/GetUserByToken";
  private getUserTweetsUrl =
    environment.apiUrl + "/MediaCenterWeb/GetUserTweets";
  private getTranslationUrl =
    environment.apiUrl + "/MediaCenterWeb/GetTranslation";
  private getLanguagesUrl = environment.apiUrl + "/MediaCenterWeb/GetLanguages";
  private searchHomePageUrl =
    environment.apiUrl + "/MediaCenterWeb/SearchHomePage";
  private getBrandStoriesUrl =
    environment.apiUrl + "/MediaCenterWeb/GetBrandStories";
  private linkedinFeedUrl = environment.apiUrl + "/MediaCenterWeb/LinkedInFeed";
  private unsubscribeNewsletterUrl =
    environment.apiUrl + "/MediaCenterUser/UnsubscribeFromNewsletter";
  private redirectToMcUrl =
    environment.apiUrl + "/MediaCenterUser/RedirectToMc";
  private loginWithTokenUrl =
    environment.apiUrl + "/MediaCenterUser/LoginWithToken";
  private redirectMarketUrl =
    environment.apiUrl + "/MediaCenterUser/RedirectMarket";
  private getMarketsUrl =
    environment.apiUrl + "/Markets/Urls";
  private getCurrentMarketUrl =
    environment.apiUrl + "/Markets/Current";
  private getSearchConfigUrl =
    environment.apiUrl + "/MediaCenterWeb/SearchConfig";
  private getLoginAttemptsUrl = environment.apiUrl + "/McLoginAttempts/List"

  redirectToMc(clientId) {
    return this.http.post(this.redirectToMcUrl + "?clientId=" + clientId, null, false, null, true, false);
  }

  getSearchConfig() {
    return this.http.get(this.getSearchConfigUrl);
  }

  getLoginAttempts(userId, page, pageSize) {
    return this.http.get(this.getLoginAttemptsUrl + "?mcUserId=" + userId + "&page=" + page + "&pageSize=" + pageSize);
  }

  getCurrentMarket() {
    return this.http.get(this.getCurrentMarketUrl);
  }

  redirectToMarket() {
    return this.http.post(this.redirectMarketUrl, null, false, null, true, false);
  }

  getMarkets() {
    return this.http.get(this.getMarketsUrl);
  }

  loginWithToken(token) {
    return this.http.post(this.loginWithTokenUrl + "?tokenString=" + token, null);
  }

  getNews(page, id) {
    return this.http.get(
      this.getNewsUrl + "?page=" + page + "&languageId=" + id
    );
  }

  getClientInfo() {
    return this.http.get(this.getClientInfoUrl);
  }

  getBrandStories(clientName, page, id) {
    return this.http.get(
      this.getBrandStoriesUrl +
      "?clientName=" +
      clientName +
      "&page=" +
      page +
      "&languageId=" +
      id
    );
  }

  getMedia(page, type = null, id) {
    const mediaType = type ? "&type=" + type : "";

    return this.http.get(
      this.getMediaUrl + "?page=" + page + mediaType + "&languageId=" + id
    );
  }

  getContactUs(id) {
    return this.http.get(this.getContactUsUrl + "?languageId=" + id);
  }

  redirectToCms(data) {
    return this.http.post(this.redirectToCmsUrl, data);
  }

  getTestimonials() {
    return this.http.get(this.getTestimonialsUrl);
  }

  getHomepage(id) {
    return this.http.get(this.getHomepageUrl + "?languageId=" + id);
  }
  getHomepageMedia(id) {
    return this.http.get(this.getHomepageUrlMedia + "?languageId=" + id);
  }
  getHomepageStoriesCategory(id) {
    return this.http.get(
      this.getHomepageUrlStoriescategory + "?languageId=" + id
    );
  }
  getHomepageLatestStories(id) {
    return this.http.get(
      this.getHomepageLatestStoriesUrl + "?languageId=" + id
    );
  }
  getHomepageInitial(id) {
    return this.http.get(this.getHomepageInitialUrl + "?languageId=" + id);
  }

  searchHomepage(searchValue, id) {
    return this.http.get(
      this.searchHomePageUrl +
      "?searchValue=" +
      searchValue +
      "&languageId=" +
      id
    );
  }

  getHeaderFooter(id) {
    return this.http.get(this.getHeaderFooterUrl + "?languageId=" + id);
  }

  getTerms() {
    return this.http.get(this.getTermsUrl);
  }

  getRegister() {
    return this.http.get(this.getRegisterUrl);
  }

  register(data) {
    return this.http.post(
      this.registerUrl,
      data,
      false,
      "You have successfully registered.\nCheck your email to verify your account."
    );
  }

  registerWithTwitter(data) {
    return this.http.post(
      this.registerWithTwitterUrl,
      data,
      false,
      "You have successfully registered.\nCheck your email to verify your account."
    );
  }

  registerFb(data) {
    return this.http.post(
      this.registerFbUrl,
      data,
      false,
      "You have successfully registered.\nCheck your email to verify your account."
    );
  }

  getUpdate(id = 0) {
    return this.http.get(this.getUpdateUrl + "?userId=" + id);
  }

  update(data) {
    return this.http.post(
      this.updateUrl,
      data,
      true,
      "You have updated your profile"
    );
  }

  getSearch() {
    return this.http.get(this.getSearchUrl);
  }

  getStyle() {
    return this.http.get(this.getStyleUrl);
  }

  searchMcStories(request, hasStoryCodes = false) {
    if (hasStoryCodes && !request.marketId)
      return this.http.post(this.searchMcStoriesWithCodesUrl, request);
    return this.http.post(this.searchMcStoriesUrl, request);
  }

  searchMcMedia(request) {
    return this.http.post(this.searchMcMediaUrl, request);
  }

  addSharedToNews(storyId, userId, socialType, noErrorToast?) {
    const data = { userId, sharedItemId: storyId, socialType };
    return this.http.post(
      this.addSharedToNewsUrl,
      data,
      false,
      null,
      noErrorToast
    );
  }

  addViewToMedia(id, userId, isPublished = false) {
    return this.http.get(
      this.addViewToMediaUrl + "?mediaId=" + id + "&userId=" + userId + "&isPublished=" + isPublished
    );
  }

  addSharedToMedia(mediaId, userId, socialType, isPublished = false, noErrorToast?) {
    const data = { userId, sharedItemId: mediaId, socialType, isPublished };
    return this.http.post(
      this.addSharedToMediaUrl,
      data,
      false,
      null,
      noErrorToast
    );
  }

  addDownloadToMedia(data: DownloadAddToModel) {
    return this.http.post(this.addDownloadToMediaUrl, data);
  }

  getNewsDetail(id, lid, cmsUser: boolean) {
    return this.http.get(
      this.getNewsDetailUrl +
      "?storyId=" +
      id +
      "&languageId=" +
      lid +
      "&cmsUser=" +
      cmsUser
    );
  }

  downloadBox(ids) {
    return this.http.post(this.webDownloadboxUrl, ids, false, "", false);
  }

  getBrands() {
    return this.http.get(this.webGetBrandsUrl);
  }

  getMediaDetail(id, lid, isPublished = false) {
    return this.http.get(
      this.getMediaDetailUrl + "?mediaId=" + id + "&languageId=" + lid + "&isPublished=" + isPublished
    );
  }

  sendEmail(data: EmailModel) {
    return this.http.post(this.sendEmailUrl, data);
  }

  logInFb(id) {
    return this.http.get(this.facebookUrl + "?facebookToken=" + id);
  }

  getMeta() {
    return this.http.get(this.getMetadatasUrl);
  }

  getTranslation(id) {
    return this.http.get(this.getTranslationUrl + "?languageId=" + id);
  }

  getMcLanguage() {
    return this.http.get(this.getLanguagesUrl);
  }

  getUserByToken(token) {
    return this.httpClient.get(this.getUserByTokenUrl).pipe(
      map((response: Response) => {
        // const toast: Toast = {
        //   type: 'success',
        //   title: 'Well Done !',
        //   body:  'Operation successful.',
        //   showCloseButton: true,
        //   closeHtml: '<div class="toaster-close-icon"></div>'
        // };
        //
        // this.toasterService.pop(toast);

        return response;
      }),
      catchError((response) => this.handleError(response))
    );
  }

  handleError(error: any) {
    let errMsg: any;
    errMsg = error.error.message ? error.error.message : error.toString();
    //

    this.toastr.error(errMsg, "Oh Snap!", ToastrConfig.error);
    return observableThrowError(errMsg);
  }

  getUserTweets() {
    return this.http.get(this.getUserTweetsUrl);
  }

  linkedinFeed() {
    return this.http.get(this.linkedinFeedUrl);
  }

  unsubscribeFromNewsletter(code: string) {
    return this.http.post(this.unsubscribeNewsletterUrl, { code: code });
  }
}
