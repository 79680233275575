export class DownloadModel {
  public downloadBoxList: DownloadBoxModel[];
  public mustRegisterToDownload;
  public mustRegisterToAccess;
  public hideCounters: boolean;
}

export class DownloadBoxModel {
  public media: MediaDetailModel[];
  public storyTitle: string;
}

export class deleteModel {
  public id: number;
  public url: string;
}

export class MediaDetailModel {
  public media: WebManageMediaModel;
  public downloadLinks: DownloadMediaModel[];
  public storyId: number;
  public alsoIn: boolean;
  public alsoInTitle: string;
  public alsoInShortDescription: string;
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
}

export class WebManageMediaModel {
  public id: number;
  public thumbUrl: string;
  public fullHDUrl: string;
  public name: string;
  public mediaType: string;
  public views: number;
  public downloads: number;
  public shared: number;
  public downloadLink: string;
  public lastDownloads: string[];
  public mediaLenght: number;

  //added new properties
  public checked: boolean;
  public addedToCart: boolean;
  public mediaDropdown: boolean;
  public shareDropdown: boolean;
  public shares?: number;
  public shareUrl?: string;
  public shareTitle?: string;
  public shareShortDescription?: string;

  public mediaLengthMin: number;
  public mediaLengthSec: number;
  public isPublished: boolean;

  // constructor(id: number, thumbUrl: string, name: string, mediaType: string, views: number, downloads: number, shared: number, downloadLink: string){
  //   this.id = id;
  //   this.thumbUrl = thumbUrl;
  //   this.name = name;
  //   this.mediaType = mediaType;
  //   this.views = views;
  //   this.downloads = downloads;
  //   this.shared = shared;
  //   this.downloadLink = downloadLink;
  // }

}

export class DownloadMediaModel {
  public label: string;
  public downloadUrl: string;
  public isOriginal: string;

  // added properties
  public checked: boolean;
  public downloadActive: boolean;
}
