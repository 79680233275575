export class Language {
  public myFiles: string;
  public myFilesNoFiles: string;
  public logInMyFiles: string;
  public delete: string;
  public filterOrSearch: string;
  public loadMore: string;
  public deleteAll: string;
  public share: string;
  public itemsInBox: string;
  public cancel: string;
  public back: string;
  public next: string;
  public searchBoxTitle: string;
  public searchBoxText: string;
  public searchBoxSubtitle: string;
  public aboutUs: string;
  public brands: string;
  public contactUs: string;
  public media: string;
  public news: string;
  public testimonials: string;
  public search: string;
  public searchResults: string;
  public applyFilters: string;
  public clearFilters: string;
  public signIn: string;
  public register: string;
  public readMore: string;
  public whatStory: string;
  public featuredStories: string;
  public latestStory: string;
  public latestFrom: string;
  public latestFromMedia: string;
  public allFiles: string;
  public videoFiles: string;
  public imageFiles: string;
  public documents: string;
  public audioFiles: string;
  public sendToCart: string;
  public download: string;
  public youMayAlsoLike: string;
  public availableFormats: string;
  public alsoAvailableIn: string;
  public downloadBox: string;
  public downloadVideoInFormat: string;
  public shareCart: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public password: string;
  public confirmPassword: string;
  public companyName: string;
  public categoriesOfInterest: string;
  public languages: string;
  public socialHandles: string;
  public add: string;
  public iHaveRead: string;
  public iWantNewsletter: string;
  public submit: string;
  public signInFb: string;
  public signInTw: string;
  public forgotPassword: string;
  public searchFrom: string;
  public selectMedia: string;
  public selectDate: string;
  public from: string;
  public to: string;
  public searchHere: string;
  public categories: string;
  public send: string;
  public ourTeam: string;
  public home: string;
  public downloading: string;
  public addToMyFiles: string;
  public addedToMyFiles: string;
  public competition: string;
  public storyDate: string;
  public storyPublisher: string;
  public filterBy: string;
  public visit: string;
  public seeStories: string;
  public newsAlert: string;
  public signUpToday: string;
  public legal: string;
  public privacySettings: string;
  public addStoryToFav: string;
  public selectCategory: string;
  public addToFiles: string;
  public expand: string;
  public enterEmail: string;
  public enterPassword: string;
  public myAccount: string;
  public redirectToCms: string;
  public logOut: string;
  public noDownloadBoxes: string;
  public savedStories: string;
  public noStoriesSaved: string;
  public images: string;
  public downloadAllImages: string;
  public selectBrand: string;
  public downloadAllVideos: string;
  public videos: string;
  public downloadAllDocuments: string;
  public audios: string;
  public downloadAllAudios: string;
  public downloadAll: string;
  public items: string;
  public viewArticle: string;
  public searchForTerm: string;
  public searchNoResults: string;
  public termsAndConditions: string;
  public privacyPolicy: string;
  public newsletter: string;
  public suscribeConfirmation: string;
  public noBrandsInCustomCategory: string;
  public current: string;
  public files: string;
  public firstNameRequired: string;
  public lastNameRequired: string;
  public emailNameRequired: string;
  public invalidEmail: string;
  public telephone: string;
  public max50Characters: string;
  public min4Characters: string;
  public passwordNoSpaces: string;
  public confirmPasswordRequired: string;
  public passwordsNoMatch: string;
  public currentPassword: string;
  public mustEnterFirstPassword: string;
  public mustEnterOldPassword: string;
  public done: string;
  public userType: string;
  public mediaUserTypeRequired: string;
  public deleteAccount: string;
  public deleteAccountConfirmation: string;
  public incorrectPassword: string;
  public emailSentSuccess: string;
  public messageRequired: string;
  public maxLength50: string;
  public or: string;
  public downloadMediaLogIn: string;
  public latestDownloads: string;
  public noDownloads: string;
  public unsupportedVideoBrowser: string;
  public registrationSuccess: string;
  public activationEmailSent: string;
  public activationEmailInstructions: string;
  public lookForwardMessage: string;
  public thankYou: string;
  public disclaimer: string;
  public mediaCentreTitle: string;
  public mediaCentreSubtitle: string;


  constructor() {
    this.competition = "Competition";
    this.addToMyFiles = "Add to my files";
    this.addedToMyFiles = "Added!";
    this.filterOrSearch = "Filter or Search";
    this.loadMore = "Load More";
    this.delete = "Delete";
    this.myFilesNoFiles = "You have no files selected";
    this.logInMyFiles = "You must log in to your account to see your files";
    this.deleteAll = "Delete All";
    this.share = "Share";
    this.itemsInBox = "Items in my box";
    this.myFiles = "My Files";
    this.cancel = "Cancel";
    this.next = "Next";
    this.back = "Back";
    this.search = "Search";
    this.searchResults = "Showing results for: ";
    this.applyFilters = "Apply Filters";
    this.clearFilters = "Clear Filters";
    this.signIn = "Sign In";
    this.register = "Register";
    this.readMore = "Read More";
    this.whatStory = "What Story Are You Looking For?";
    this.featuredStories = "Featured Stories";
    this.latestStory = "Latest Stories";
    this.latestFrom = "Latest From";
    this.latestFromMedia = "Latest From Media";
    this.allFiles = "All Files";
    this.sendToCart = "Send To Cart";
    this.download = "Download";
    this.downloading = "Downloading";
    this.availableFormats = "Available formats";
    this.alsoAvailableIn = "Also available in";
    this.downloadBox = "Download Box";
    this.downloadVideoInFormat = "Download all Video files in format";
    this.shareCart = "Share Download Box";
    this.firstName = "First Name";
    this.lastName = "Last Name";
    this.email = "Email";
    this.phone = "Phone";
    this.password = "Password";
    this.confirmPassword = "Confirm Password";
    this.companyName = "Company Name";
    this.categoriesOfInterest = "Categories of interest";
    this.languages = "Languages";
    this.socialHandles = "Social handles";
    this.add = "Add";
    this.iHaveRead = "I have read and accept the T&C";
    this.iWantNewsletter = "I want to receive newsletter";
    this.submit = "Submit";
    this.signInFb = "Sign in with Facebook";
    this.signInTw = "Sign in with Twitter";
    this.forgotPassword = "Forgot password";
    this.searchFrom = "Search from";
    this.selectMedia = "Select media";
    this.selectDate = "Select date";
    this.from = "From";
    this.to = "To";
    this.categories = "Categories";
    this.send = "Send";
    this.ourTeam = "Our Team";
    this.aboutUs = "About Us";
    this.brands = "Brands";
    this.contactUs = "Contact Us";
    this.media = "Media";
    this.news = "News";
    this.testimonials = "Testimonials";
    this.home = "Home";
    //TO DO: Pending translations
    this.searchHere = "What are you looking for today?";
    this.storyDate = "Published On";
    this.storyPublisher = "Written By";
    this.youMayAlsoLike = "Latest Stories";
    this.filterBy = "Filter by:";
    this.imageFiles = "Images";
    this.videoFiles = "Videos";
    this.documents = "Documents";
    this.audioFiles = "Audios";
    this.visit = "Go to Brand Site";
    this.seeStories = "See Stories";
    this.newsAlert = "Get the news alerts you want, when you want them.";
    this.signUpToday = "Sign up today.";
    this.legal = "Legal";
    this.privacySettings = "Privacy Settings";
    this.addStoryToFav = "Add story to favorites";
    this.selectCategory = "Select a category";
    this.addToFiles = "Add to my files";
    this.expand = "Expand";
    this.enterEmail = "Enter your email";
    this.enterPassword = "Enter your password";
    this.myAccount = "My account";
    this.redirectToCms = "Redirect to cms";
    this.logOut = "Log out";
    this.noDownloadBoxes = "No download boxes to show.";
    this.savedStories = "My Saved Stories";
    this.noStoriesSaved = "No stories saved. Save stories of interest and come back to them later.";
    this.images = "Images";
    this.downloadAllImages = "Download all images";
    this.selectBrand = "Select a brand";
    this.downloadAllVideos = "Download all videos";
    this.videos = "Videos";
    this.downloadAllDocuments = "Download all documents";
    this.audios = "Audios";
    this.downloadAllAudios = "Download all audios";
    this.downloadAll = "Download all";
    this.items = "items";
    this.viewArticle = "View article";
    this.searchForTerm = "SEARCH FOR A TERM TO SEE RESULTS";
    this.searchNoResults = "WE COULDN'T FIND A MATCH, PLEASE TRY AGAIN.";
    this.termsAndConditions = "Terms & Conditions";
    this.privacyPolicy = "Privacy Policy";
    this.newsletter = "Newsletter";
    this.suscribeConfirmation = "Are you sure you want to unsubscribe?";
    this.noBrandsInCustomCategory = "No brands in this custom category.";
    this.current = "Current";
    this.files = "Files";
    this.firstNameRequired = "First name is required";
    this.lastNameRequired = "Last name is required";
    this.emailNameRequired = "Email name is required";
    this.invalidEmail = "Must be a valid email address";
    this.telephone = "Telephone number";
    this.max50Characters = "Max 50 characters";
    this.min4Characters = "Min 8 characters";
    this.passwordNoSpaces = "Password cannot contain spaces";
    this.confirmPasswordRequired = "Confirm password is required";
    this.passwordsNoMatch = "Passwords do not match.";
    this.currentPassword = "Current password";
    this.mustEnterFirstPassword = "You must type your password above first.";
    this.mustEnterOldPassword = "You must enter your old password.";
    this.done = "Done";
    this.userType = "User Type";
    this.mediaUserTypeRequired = "Media User Type is required";
    this.deleteAccount = "Delete account";
    this.deleteAccountConfirmation = "Are you sure you want to delete your account? Please enter your password and confirm your decision.";
    this.incorrectPassword = "The password is incorrect.";
    this.emailSentSuccess = "You have successfully sent mail. Thank you for contacting us.";
    this.messageRequired = "Message is required";
    this.maxLength50 = "Max length is 50";
    this.or = "OR";
    this.downloadMediaLogIn = "To Download Media Content Please Log In";
    this.latestDownloads = "Latest Downloads";
    this.noDownloads = "No downloads yet";
    this.unsupportedVideoBrowser = "Your browser does not support HTML5 video.";
    this.registrationSuccess = "Registration successful";
    this.activationEmailSent = "You are just one click away from creating your online account with digitalnewsagency.com. We have sent an activation email to you (please check your spam/junk.)";
    this.activationEmailInstructions = "Please click the Activate button and you’ll be all set to go. If you have difficulties accessing your account, or have any questions or require any additional information please contact our team on hello@digitalnewsagency.com";
    this.lookForwardMessage = "We look forward to sharing our content with you.";
    this.thankYou = "Thank you";
    this.disclaimer = "Disclaimer";
    this.mediaCentreTitle = "Media Centre";
    this.mediaCentreSubtitle = "Welcome to the easyJet media centre, where you'll be able to find all our latest news.";
  }
}
