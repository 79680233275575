import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { LayoutModel } from "../layout/layout-model";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { TokenManagerService } from "../shared/services/token-manager.service";
import { StorageService } from "../shared/services/storage.service";
import { McService } from "../shared/services/mc-service";
import { environment } from "../../environments/environment";
import { FontsAndColorsService } from "../shared/services/fonts-and-colors.service";
import { PageStyle } from "../models/style/style.model";
import { MetaModel } from "../models/meta/meta";
import { Meta, Title } from "@angular/platform-browser";
import { Language } from "../models/language/language.model";
import { LanguageService } from "../shared/services/language.service";
import { CommunicationService } from "../shared/services/communication.service";
import { ButtonsHelper } from "../shared/helpers/buttons-helper";
import { MyFilesService } from "../shared/services/my-files.service";
import { GoogleAnalyticsEventsService } from "../shared/services/google-analytics-events.service";
import { SearchComponent } from "./search/search.component";
import { MyFilesModel } from "../shared/services/my-files-model";

@Component({
  selector: "app-header-3",
  templateUrl: "./header-3.component.html",
  styleUrls: ["./header-3.component.less"],
})
export class HeaderComponent3 implements OnInit {
  @Input() layoutResponseModel: LayoutModel;
  @Input() metadatas: MetaModel[];
  @ViewChild('appSearch') searchChild: SearchComponent;
  @ViewChild("logoFlag") logoFlag: ElementRef;
  styles: PageStyle;
  search: boolean;
  mobileNav: boolean;
  showDropdown: boolean;
  showMobDropdown: boolean;
  loggedUserName = "";
  mustRegister: boolean;
  anonymousShare: boolean;
  cms: any;
  user: any;
  loader: boolean;
  loginActive = false;
  loginColor: any;
  signinActive = false;
  signinColor: any;
  logo = "";
  language: Language;
  mobileLanguageDropdown = false;
  brandsColor: any;
  brandsActive = false;
  showDropdownLng = false;
  activeLng = "English";
  sLng: number;
  fromBrandsUrl: string;
  biggerLogo = false;
  invertColors = true;
  socialLinkObjects: any;
  showMyFiles: boolean = false;
  buttonsHelper = ButtonsHelper;
  cart: MyFilesModel = new MyFilesModel([], []);
  loggedUserEmail: any;
  markets = [];

  invertedHeaderRoutesExact: string[] = ["/", "/home", "/about-us", "story"];
  invertedHeaderRoutesPartial: string[] = ["story"];

  constructor(
    private router: Router,
    private mcService: McService,
    private authService: TokenManagerService,
    private languageService: LanguageService,
    private titleService: Title,
    private meta: Meta,
    private fontsAndColorsService: FontsAndColorsService,
    private communicationsService: CommunicationService,
    private storageService: StorageService,
    private myFilesService: MyFilesService,
    private googleAnalytics: GoogleAnalyticsEventsService
  ) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.myFilesService.files.subscribe((myFiles) => {
      this.cart = myFiles;
    });
    if (this.router.url.indexOf("brands") > 0) {
      this.brandsActive = true;
      this.brandsColor = this.styles.style.mainIdentifyingColor;
    }

    this.checkRouteForHeader();

    const component = this;
    this.communicationsService.fromBrands.subscribe((url: string) => {
      if (url) {
        setTimeout(() => {
          url = url.replace(/%2F/, "/");
          url = url.replace(/%2F/, "/");
          url = url.replace(/%3A/, ":");
          component.fromBrandsUrl = url;
        });
      }
    });

    this.languageService.languageId.subscribe((languageId: number) => {
      this.sLng = languageId;
      this.getLng(this.sLng);
    });

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    this.storageService.getMustRegister().subscribe((mustRegister) => {
      component.mustRegister = mustRegister;
    });
    this.storageService.getAnonymousShare().subscribe((anonymousShare) => {
      component.anonymousShare = anonymousShare;
    });
  }

  checkRouteForHeader() {
    this.checkIsInvertedHeader(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIsInvertedHeader(event.url);
      }
    });
  }

  checkIsInvertedHeader(route: string) {
    const oneExact = this.invertedHeaderRoutesExact.some((r) => r == route);
    const onePartial = this.invertedHeaderRoutesPartial.some(
      (r) => route.indexOf(r) > 0
    );
    this.invertColors = oneExact || onePartial;
  }

  currentMarket;

  ngOnInit() {
    var actualWebsite: String = window.location.origin;

    this.cart = this.myFilesService.getMyFiles();
    this.language = this.languageService.getLanguage();

    this.sLng = Number(localStorage.getItem("lng"));
    this.getLng(this.sLng);

    this.storageService.getName().subscribe((term) => {
      this.loggedUserName = term;
      this.showDropdown = false;
      this.user = TokenManagerService.getUser();
      this.loggedUserEmail = this.user.email;
    });
    this.user = TokenManagerService.getUser();
    this.loggedUserName = this.user.fullName;
    this.loggedUserEmail = this.user.email;

    this.search = false;
    this.mobileNav = false;

    this.socialLinkObjects = JSON.parse(
      this.layoutResponseModel.socialLinkJson
    );
    if (
      this.socialLinkObjects !== null &&
      this.socialLinkObjects !== undefined
    ) {
      this.socialLinkObjects = this.socialLinkObjects.map((o) => {
        o.name = o.name.toLowerCase();
        o.link = this.checkLinkPrefix(o.link);
        return o;
      });
    } else {
      this.socialLinkObjects = [];
    }
    this.mcService.getMarkets().subscribe((markets: any) => {
      this.markets = markets;
      //check according to current url which market is active, ignoring cases, protocol and www, query params, everything after /, just the domain
      let currentDomain = window.location.href.toLowerCase();
      currentDomain = currentDomain.replace(/(^\w+:|^)\/\//, "");
      currentDomain = currentDomain.replace(/www./, "");
      currentDomain = currentDomain.replace(/\/.*/, "");
      this.markets.forEach((market) => {
        let marketUrl = market.url.toLowerCase();
        marketUrl = marketUrl.replace(/(^\w+:|^)\/\//, "");
        marketUrl = marketUrl.replace(/www./, "");
        marketUrl = marketUrl.replace(/\/.*/, "");
        if (marketUrl === currentDomain) {
          this.currentMarket = market;
        }
      });
    });
  }

  closeMyFiles(event) {
    if (event.target.id !== "myFilesNav") this.showMyFiles = false;
  }

  openMyFiles() {
    this.closeNav();
    this.showMyFiles = true;
  }

  checkLinkPrefix(link: string) {
    if (link.includes("http://") || link.includes("https://")) {
      return link;
    } else {
      return `http://${link}`;
    }
  }

  onLoad() {
    const width = (this.logoFlag.nativeElement as HTMLImageElement)
      .naturalWidth;
    const height = (this.logoFlag.nativeElement as HTMLImageElement)
      .naturalHeight;

    if (width > 4 * height) {
      this.biggerLogo = true;
    } else {
      this.biggerLogo = false;
    }
  }

  getLng(languageId) {
    if (this.layoutResponseModel) {
      for (let i = 0; i < this.layoutResponseModel.languages.length; i++) {
        if (languageId == this.layoutResponseModel.languages[i].id) {
          this.activeLng = this.layoutResponseModel.languages[i].label;
        }
        if (languageId == 0) {
          this.activeLng = "English";
        }
      }
    }
  }

  translate(id, label) {
    this.loader = true;
    this.activeLng = label;

    this.languageService.setLanguageId(id);

    this.router.navigateByUrl("/home");
    location.reload();
  }

  changeColor(e, color, link) {
    if (link && !link.active) {
      e.target.style.color = color;
    }
  }

  changeColorr(e, link) {
    if (link && !link.active) {
      e.target.style.color = this.styles.style.textLinkHoverColor;
    }
  }

  changeColorCustomLink(e, color) {
    if (!this.brandsActive) {
      e.target.style.color = "#3a3a3a";
    }
  }

  changeColorrCustomLink(e, link) {
    if (!this.brandsActive) {
      e.target.style.color = this.styles.style.textLinkHoverColor;
    }
  }

  changeColorLinkBack(e, color) {
    e.target.style.color = this.styles.style.textH2Color;
  }

  changeColorLink(e) {
    e.target.style.color = this.styles.style.mainIdentifyingColor;
  }

  redirectToCms() {
    this.mcService.redirectToCms(this.user.cmsUser).subscribe(
      (response: any) => {
        window.open(environment.cmsUrl + response.token, "_blank");
      },
      (error) => {
        console.error(error);
      }
    );
  }

  logout() {
    this.authService.logout();
    this.user = TokenManagerService.getUser();
    this.myFilesService.setMyFiles(this.myFilesService.getFiles());
    this.loggedUserName = this.user.fullName;
    this.loggedUserEmail = this.user.email;
    if (this.mustRegister) {
      this.router.navigateByUrl("/sign-in");
    } else {
      this.router.navigateByUrl("/home");
    }
  }

  showProfile() {
    this.showDropdown = !this.showDropdown;
  }

  showLanguage() {
    this.showDropdownLng = !this.showDropdownLng;
  }

  showDropdownMarkets: boolean = false;

  showMarkets() {
    this.showDropdownMarkets = !this.showDropdownMarkets;
  }

  showMobileLanguage() {
    this.mobileLanguageDropdown = !this.mobileLanguageDropdown;
  }

  showMobileProfile() {
    this.showMobDropdown = !this.showMobDropdown;
  }

  editProfile() {
    this.router.navigateByUrl("/profile");
  }

  reroute(url) {
    this.layoutResponseModel.footerLinks.forEach((x) => (x.active = false));
    this.brandsActive = false;
    this.brandsColor = this.styles.style.textHeaderColor;
    const index = this.layoutResponseModel.headerLinks.indexOf(url);
    if (url.url.indexOf("http") > -1) {
      window.open(url.url, "_blank");
    } else {
      this.layoutResponseModel.headerLinks.forEach((x) => (x.active = false));
      this.router.navigateByUrl(url.url);
      url.active = true;
    }
    this.googleAnalytics.emitEvent(
      "MenuClick",
      "MenuClick",
      "MenuOption",
      url.link
    );
  }

  activeLink() {
    this.layoutResponseModel.footerLinks.forEach((x) => (x.active = false));
    this.layoutResponseModel.headerLinks.forEach((x) => {
      if ("/" + x.url === "/home") {
        x.active = true;
      } else {
        x.active = false;
      }
    });
  }

  rerouteToLogin(text: string) {
    this.layoutResponseModel.headerLinks.forEach((x) => (x.active = false));
    this.layoutResponseModel.footerLinks.forEach((x) => (x.active = false));
    this.googleAnalytics.emitEvent("MenuClick", "MenuOption", text);
  }

  rerouteToBrands() {
    this.brandsActive = true;
    this.brandsColor = this.styles.style.mainIdentifyingColor;
    this.layoutResponseModel.headerLinks.forEach((x) => (x.active = false));
    this.layoutResponseModel.footerLinks.forEach((x) => (x.active = false));
  }

  showSearch() {
    this.search = true;
    this.searchChild?.wipeSearch();
    const input = document.getElementById("story-search-big");
    setTimeout(() => {
      input.focus();
    }, 250);
    const body = document.getElementsByTagName("body")[0];
    body.style.position = "fixed";
    this.googleAnalytics.emitEvent(
      "MenuClick",
      "MenuOption",
      this.language.search
    );
  }

  openMobileNav() {
    this.mobileNav = true;
  }

  closeNav() {
    this.mobileNav = false;
  }

  onClickOutside(event: Object) {
    if (event && event["value"] === true) {
      this.showDropdown = false;
    }
  }

  onClickOutsidee(event: Object) {
    if (event && event["value"] === true) {
      this.showDropdownLng = false;
    }
  }

  onClickOutsideMarkets(event: Object) {
    if (event && event["value"] === true) {
      this.showDropdownMarkets = false;
    }
  }

  goToMarket(market: any) {
    let url = market.url;
    this.loader = true;
    const currentProtocol = window.location.protocol;
    if (url.indexOf("http") == -1 && url.indexOf("https") == -1) {
      url = currentProtocol + "//" + url;
    }
    this.mcService.redirectToMarket().subscribe(
      (response: any) => {
        const token = response.token;
        window.location.href = url + '?token=' + token;
      },
      (error) => {
        window.location.href = url;
      }
    );
  }

  showFiles() {
    this.showMyFiles = !this.showMyFiles;
    if (this.showMyFiles)
      this.googleAnalytics.emitEvent(
        "MenuClick",
        "MenuOption",
        this.language.myFiles || this.language.myFiles
      );
  }
}
