import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../environments/environment';
import { ToastrConfig } from "../helpers/toastr-config";
import { HttpRequestService } from "./http-request.service";

@Injectable()
export class SavedStoryService {
  private addUrl = environment.apiUrl + '/SavedStory/Add';
  private getAllUrl = environment.apiUrl + '/SavedStory/GetAll';
  private removeUrl = environment.apiUrl + '/SavedStory/Remove';
  private getAllIdsUrl = environment.apiUrl + '/SavedStory/GetAllIds';

  savedStoryIds: any[] = [];

  constructor(private http: HttpRequestService, private toastr: ToastrService) { }

  add(storyId) {
    return this.http.post(this.addUrl, storyId).toPromise().then((response) => {
      if (!this.savedStoryIds) this.savedStoryIds = [];
      this.savedStoryIds.push(storyId);
      localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
      this.toastr.success('Story Saved.', 'Well Done!', ToastrConfig.success);
    });
  }

  remove(storyId) {
    return this.http.post(this.removeUrl, storyId).toPromise().then((response) => {
      if (!this.savedStoryIds) this.savedStoryIds = [];
      this.savedStoryIds = this.savedStoryIds.filter(sId => sId != storyId);
      localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
      this.toastr.success('Story Removed.', 'Well Done!', ToastrConfig.success);
    });
  }

  getAll(languageId = 1) {
    return this.http.get(this.getAllUrl + '?languageId=' + languageId);
  }

  getIds() {
    return this.http.get(this.getAllIdsUrl).subscribe(
      (response: any) => {
        this.savedStoryIds = response;
        localStorage.setItem("savedStories", JSON.stringify(this.savedStoryIds));
      },
      (error) => console.error(error));
  }

  inSavedStories(storyId) {
    if (!this.savedStoryIds) this.savedStoryIds = [];
    return this.savedStoryIds.includes(storyId);
  }
}
