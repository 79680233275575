<section class="story-detail flex-center">
  <div class="story-body">
    <div class="flex">
      <div class="header-image flex-center" [ngClass]="[
        !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : ''
      ]">
        <div class="header-wrapper flex-center" [ngClass]="[
          !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : '',
          webNewsDetailsResponse?.isMobileCrop ? 'mobile-crop' : ''
        ]">
          <div class="slider-overlay" *ngIf="
            webNewsDetailsResponse?.smallHeroImage == false &&
            !webNewsDetailsResponse?.embeddedCode
          "></div>
          <div class="story-featured-image" [ngClass]="[
            !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : '',
            webNewsDetailsResponse?.embeddedCode ? 'story-with-video' : ''
          ]">
            <div class="white-triangle" [ngClass]="[
              !webNewsDetailsResponse?.smallHeroImage ? ' story-detail-v2' : ''
            ]"></div>

            <picture class="featured-image" *ngIf="
              webNewsDetailsResponse?.featuredMediaType != 'Video' &&
              !webNewsDetailsResponse?.embeddedCode &&
              webNewsDetailsResponse != undefined
            ">
              <source class="featured-image"
                srcset="{{ webPHelperService.changeFileToWebP(webNewsDetailsResponse.thumbUrl) }}" type="image/webp" />
              <source class="featured-image" srcset="{{ webNewsDetailsResponse.thumbUrl }}" type="image/jpeg" />
              <img (error)="webPHelperService.removeWebP($event)" class="featured-image"
                src="{{ webNewsDetailsResponse.thumbUrl }}" alt="Story featured image" />
            </picture>

            <video class="featured-image" *ngIf="webNewsDetailsResponse?.featuredMediaType === 'Video'" loop
              [muted]="true" oncontextmenu="return false;">
              <source src="{{ webNewsDetailsResponse?.thumbUrl }}" type="video/mp4" />
              {{ language.unsupportedVideoBrowser }}
            </video>
            <div *ngIf="webNewsDetailsResponse?.embeddedCode" class="story-details-iframe"
              [innerHTML]="webNewsDetailsResponse?.embeddedCode"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex">

      <div class="story-information">
        <div class="story-share" *ngIf="webNewsDetailsResponse">
          <div class="share-buttons">
            <div class="story-save" (click)="toggleSavedStory(webNewsDetailsResponse.storyId)">
              <div class="add-to-wishlist">
                <i *ngIf="!inSavedStories(webNewsDetailsResponse?.storyId)" class="far fa-heart"></i>
                <i *ngIf="inSavedStories(webNewsDetailsResponse?.storyId)" class="fas fa-heart"></i>
              </div>
            </div>
            <ng-container *ngFor="let social of socials">
              <app-share-button [image]="webNewsDetailsResponse?.thumbUrl" [sbUrl]="currentUrl"
                [title]="webNewsDetailsResponse?.storyTitle" [description]="webNewsDetailsResponse?.shortDescription"
                [storyId]="webNewsDetailsResponse.storyId" [mediaId]="webNewsDetailsResponse.mediaId"
                [staggingStoryId]="webNewsDetailsResponse.staggingStoryId" [social]="social" [open]="share"
                [shared]="webNewsDetailsResponse?.shared">
              </app-share-button>
            </ng-container>
          </div>
        </div>

        <div class="story-content">
          <div class="header-data">
            <div class="story-information-item" *ngIf="publisher">
              <div class="title">{{ language.storyPublisher }}</div>
              <div class="info">
                {{ webNewsDetailsResponse?.publishedDate | date : " dd MMMM yyyy" }}
              </div>
            </div>
            <div class="story-information-item" *ngIf="!webNewsDetailsResponse?.hideStoryDate">
              <div class="title">{{ language.storyDate }}</div>
              <div class="info">
                {{ webNewsDetailsResponse?.publishedDate | date : " dd MMMM yyyy" }}
              </div>
            </div>

            <!-- <div class="story-save" (click)="toggleSavedStory(webNewsDetailsResponse.storyId)">
            <div class="add-to-wishlist">
              <i *ngIf="!inSavedStories(webNewsDetailsResponse?.storyId)" class="far fa-heart"></i>
              <i *ngIf="inSavedStories(webNewsDetailsResponse?.storyId)" class="fas fa-heart"></i>
            </div>
            {{ language.addStoryToFav }}
          </div> -->
          </div>

          <div class="main-headline">
            <div class="meta flex">
              <div class="category-wrapper" (click)="showSubCategory(webNewsDetailsResponse)">
                <div [ngClass]="webNewsDetailsResponse?.category.length > 1 ? 'has-child' : ''">
                  <div *ngFor="let category of webNewsDetailsResponse?.category">
                    {{category?.name}}
                  </div>
                </div>
              </div>
            </div>
            <h1>
              {{ webNewsDetailsResponse?.storyTitle }}
            </h1>
            <h4>
              {{ webNewsDetailsResponse?.shortDescription }}
            </h4>
          </div>

          <div class="story-text">
            <!-- <div *ngIf="!loader" class="story-subtitle">
            <h2 [innerHTML]="webNewsDetailsResponse?.shortDescription"></h2>
          </div> -->
            <div id="bodytext" [innerHTML]="webNewsDetailsResponse?.text"></div>
          </div>
        </div>
      </div>


      <div class="story-highlight-media" *ngIf="webNewsDetailsResponse?.media.length > 0">
        <div class="border"></div>
        <h3 class="section-title">
          {{ language.media }}
          <div class="minimizeSection" [ngClass]="{ opened: mediaTabOpened }"
            (click)="mediaTabOpened = !mediaTabOpened">
            <i *ngIf="mediaTabOpened" class="fas fa-minus"></i>
            <i *ngIf="!mediaTabOpened" class="fas fa-plus"></i>
          </div>
        </h3>
        <ng-container *ngIf="imageMedia.length > 0 && mediaTabOpened">
          <div class="media-group-header">
            <div class="media-group-title">{{ language.images }} ({{ imageMedia.length }})</div>
            <div class="add-to-cart" (click)="downloadAll(imageMedia, 'image')">
              {{ downloadingImage ? language.downloading : language.downloadAllImages }}
              <span class="fal fa-download"></span>
            </div>
          </div>
          <div class="story-highlight-media-wrapper">
            <ng-container *ngFor="let media of imageMedia">
              <app-media-file class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
                [media]="media" [config]="webNewsDetailsResponse"></app-media-file>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="videoMedia.length > 0 && mediaTabOpened">
          <div class="media-group-header">
            <div class="media-group-title">{{ language.videos }} ({{ videoMedia.length }})</div>
            <div class="add-to-cart" (click)="downloadAll(videoMedia)">
              {{ language.downloadAllVideos }} <span class="fal fa-download"></span>
            </div>
          </div>
          <div class="story-highlight-media-wrapper">
            <ng-container *ngFor="let media of videoMedia">
              <app-media-file class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
                [media]="media" [config]="webNewsDetailsResponse"></app-media-file>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="documentMedia.length > 0 && mediaTabOpened">
          <div class="media-group-header">
            <div class="media-group-title">
              {{ language.documents }} ({{ documentMedia.length }})
            </div>
            <div class="add-to-cart" (click)="downloadAll(documentMedia)">
              {{ language.downloadAllDocuments }} <span class="fal fa-download"></span>
            </div>
          </div>
          <div class="story-highlight-media-wrapper">
            <ng-container *ngFor="let media of documentMedia">
              <app-media-file class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
                [media]="media" [config]="webNewsDetailsResponse"></app-media-file>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="audioMedia.length > 0 && mediaTabOpened">
          <div class="media-group-header">
            <div class="media-group-title">{{ language.audios }} ({{ audioMedia.length }})</div>
            <div class="add-to-cart" (click)="downloadAll(audioMedia)">
              {{ language.downloadAllAudios }} <span class="fal fa-download"></span>
            </div>
          </div>
          <div class="story-highlight-media-wrapper">
            <ng-container *ngFor="let media of audioMedia">
              <app-media-file class="media-file" [newsDetail]="true" [customClasses]="'news-detail-media-file'"
                [media]="media" [config]="webNewsDetailsResponse"></app-media-file>
            </ng-container>
          </div>
        </ng-container>
        <!-- <div class="download-all-container">
        <div class="generic-button" (click)="downloadAll()">
          <span class="fal fa-download"></span>
          Download All Media
        </div>
      </div> -->
      </div>

    </div>

  </div>

  <div id="latest-news" class="latest-news">
    <div class="story-feed" *ngIf="
        webNewsDetailsResponse?.youMayAlsoLikeSection &&
        (token || !webNewsDetailsResponse.mustRegisterToAccess)
      ">
      <h2 class="section-title">
        {{ language.youMayAlsoLike }}
      </h2>
      <div class="story-wrapper" [ngClass]="{ opened: relatedStoriesTabOpened }">
        <ng-container *ngFor="let relatedStory of webNewsDetailsResponse.alsoMayLike">
          <app-story-preview class="news-item" [story]="relatedStory" [config]="webNewsDetailsResponse"
            [customClasses]="" [template]="4"></app-story-preview>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<app-popup *ngIf="popup" [(popup)]="popup" [(hideCounters)]="webNewsDetailsResponse.hideCounters" [mediaId]="mediaId"
  [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup"
  (loginSuccessfulChange)="onLogin($event)"></app-loginpopup>