<div id="toast-container" toastContainer></div>
<app-header-2 *ngIf="styles && layoutResponseModel && !isVodafone && !isEasyJet"
  [layoutResponseModel]="layoutResponseModel"></app-header-2>
<app-header-2 *ngIf="styles && layoutResponseModel && isVodafone"
  [layoutResponseModel]="layoutResponseModel"></app-header-2>
<app-header-3 *ngIf="styles && layoutResponseModel && isEasyJet"
  [layoutResponseModel]="layoutResponseModel"></app-header-3>
<div *ngIf="styles" class="page-wrapper">
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="styles" [layoutResponseModel]="layoutResponseModel" [isEasyJet]="isEasyJet"
  [clientName]="clientName"></app-footer>