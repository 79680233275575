import {Component, OnInit} from '@angular/core';
import {McService} from '../shared/services/mc-service';
import {TestimonialsViewModel} from './testimonials-model';
import {Router} from '@angular/router';
import {LanguageService} from '../shared/services/language.service';
import {Language} from '../models/language/language.model';
import {PageStyle} from "../models/style/style.model";
import {FontsAndColorsService} from "../shared/services/fonts-and-colors.service";
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.less']
})
export class TestimonialsComponent implements OnInit {
  loader: boolean;
  language: Language;
  styles = new PageStyle();

  testimonialsResponse = new TestimonialsViewModel();

  constructor(private mcService: McService, private languageService:LanguageService, public fontsAndColorsService: FontsAndColorsService, private router: Router) {
    this.language = languageService.getLanguage();
    this.styles = fontsAndColorsService.getPageStyleInfo();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );

  }

  ngOnInit() {
    this.getTestimonials();
    this.loader = true;

  }

  getTestimonials(){
    this.mcService.getTestimonials()
      .subscribe((response: any) => {
          this.testimonialsResponse = response;
          // if (this.testimonialsResponse.mustRegisterToAccess && localStorage.getItem('Token') == null) {
          //   sessionStorage.setItem('redirectUrl', '/' + window.location.pathname);
          //   this.router.navigateByUrl('/sign-in');
          // }
          this.loader = false;
        },
        (error) =>
          console.log(error));
  }

}
