import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import { McService } from "../../shared/services/mc-service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "../../shared/services/storage.service";
import { UrlHelper } from "../../shared/helpers/url-helper";
import { TokenManagerService } from "../../shared/services/token-manager.service";
import { FontsAndColorsService } from "../../shared/services/fonts-and-colors.service";
import { PageStyle } from "../../models/style/style.model";
import { GoogleAnalyticsEventsService } from "../../shared/services/google-analytics-events.service";
import { LanguageService } from "../../shared/services/language.service";
import { Language } from "../../models/language/language.model";
import { Location } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { ButtonsHelper } from "../../shared/helpers/buttons-helper";
import { MyFilesService } from "../../shared/services/my-files.service";
import { WebNewsModel } from "../news/news-model";
import { AlgoliaService } from "../../shared/services/algolia.service";
import { WebManageMediaModel } from "../../download/download-model";
import {
  SearchResultModel,
  AlgoliaNewsRecords,
  AlgoliaMediaRecords,
} from "../../header/search/search-model";
import { MyFilesModel } from "../../shared/services/my-files-model";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.less"],
})
export class ResultsComponent implements OnInit {
  buttonsHelper = ButtonsHelper;
  resultStories: any;
  resultMedia: any = [];
  autoplay: boolean;
  mediaId: number;
  popup: boolean;
  loader: boolean;
  storyId: number;
  addDropdown: boolean;
  user: any;
  headerFooter: any;
  styles = new PageStyle();
  socials: string[] = [];
  currentUrl: string;
  language: Language;
  mediaTop: number;
  pageStories = 1;
  pageSizeStories = 12;
  maxPageStories = 1;
  totalCountStories: number;
  searchResults: number;
  isPublished: boolean = false;
  grid: string;
  itemsPerPage = 12;
  newsHeadlineLength: number = 120;
  pageMedia = 1;
  pageSizeMedia = 12;
  maxPageMedia = 1;
  totalCountMedia: number;
  searchModel: any;
  loaderMedia = true;
  loaderStory = true;
  lazyLoadingLoader = false;
  showingStories = true;
  showLoadMore = true;
  cart: MyFilesModel = new MyFilesModel([], []);
  storyGroups: WebNewsModel[][] = [[]];
  mediaGroups: WebManageMediaModel[][] = [[]];
  searchResultStories = new SearchResultModel();
  searchResultMedia = new SearchResultModel();
  resultId: number;

  constructor(
    private mcService: McService,
    private router: Router,
    private languageService: LanguageService,
    private storageService: StorageService,
    private googleAnalytics: GoogleAnalyticsEventsService,
    public fontsAndColorsService: FontsAndColorsService,
    private location: Location,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    ngZone: NgZone,
    private myFilesService: MyFilesService,
    private algoliaService: AlgoliaService
  ) {
    this.myFilesService.files.subscribe((myFiles) => {
      this.cart = myFiles;
    });
    this.newsHeadlineLength = 90;
    if (window.innerWidth <= 1500 && window.innerWidth >= 1200) {
      this.newsHeadlineLength = 65;
    }
    if (window.innerWidth <= 1200 && window.innerWidth >= 700) {
      this.newsHeadlineLength = 12;
    }
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe((language) => {
      this.language = language;
    });

    this.route.params.subscribe((params: Params) => {
      const id: string = params["id"];
      const title: string = params["title"];
      let media: string = params["media"];

      if (media && media.indexOf("?") > -1)
        media = media.substr(0, media.indexOf("?"));

      this.resultId = +id;
      if (media) {
        this.openPopup(+media);
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.isPublished = params["isPublished"];
      if (this.isPublished === undefined) this.isPublished = false;
      console.log("published", this.isPublished);
      if (this.isPublished) {
        this.openPopup(this.resultId);
      }
    });

    window.onresize = (e) => {
      ngZone.run(() => {
        this.getDimensions();
      });
    };
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 300 &&
      !this.lazyLoadingLoader
    ) {
      this.loadMore();
    }
  }

  loadMore() {
    if (
      this.searchModel.stories &&
      this.searchModel.page < this.maxPageStories
    ) {
      this.searchModel.page++;
      this.lazyLoadingLoader = true;
      this.changePageStories(this.searchModel.page);
    } else if (
      this.searchModel.media &&
      this.searchModel.page < this.maxPageMedia
    ) {
      this.searchModel.page++;
      this.lazyLoadingLoader = true;
      this.changePageMedia(this.searchModel.page);
    }
  }

  addToMyFiles(media) {
    let component = this;
    if (component.cart.isInMyFiles(media.id, this.isPublished)) {
      component.myFilesService.pushMyFiles(media.id, this.isPublished);
    }
  }

  download(media) {
    media.downloading = true;
    this.getMediaDetail(media);
  }

  getMediaDetail(media) {
    let language = Number(localStorage.getItem("lng"));
    let mediaDetailResponseModel;
    this.mcService.getMediaDetail(media.id, language, this.isPublished).subscribe(
      (response: any) => {
        mediaDetailResponseModel = response;
        if (
          mediaDetailResponseModel.mustRegisterToAccess &&
          !mediaDetailResponseModel.anonymousShare &&
          !localStorage.getItem("Token")
        ) {
          sessionStorage.setItem("redirectUrl", "/" + window.location.pathname);
          this.router.navigateByUrl("/sign-in");
        }
        if (mediaDetailResponseModel.downloadLinks.length > 0) {
          this.downloadMedia(
            mediaDetailResponseModel.downloadLinks[0],
            mediaDetailResponseModel,
            media
          );
        }
      },
      (error) => {
        media.downloading = false;
        //
      }
    );
  }

  downloadMedia(download, mediaDetailResponseModel, media) {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (iOS) {
      try {
        (window as any).webkit.messageHandlers.downloadClicked.postMessage({
          url: download.downloadUrl,
        });
      } catch (err) {
        media.downloading = false;
      }

      return;
    }
    const component = this;
    download.downloadActive = true;
    this.http.get(download.downloadUrl, { responseType: "blob" }).subscribe(
      (blob: Blob) => {
        const filename = download.downloadUrl.split("/")[8];
        saveAs(blob, filename);
        media.downloading = false;
        if (component.user.cmsUser == null) {
          component.addDownload(media.id);
        }
      },
      (error) => {
        media.downloading = false;
      }
    );
  }

  addDownload(mediaId) {
    this.user = TokenManagerService.getUser();
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    let niz: any = {};
    niz.mediaIds = [mediaId];
    niz.userId = this.user.id;
    niz.mediaIds.forEach((mediaId) => {
      this.googleAnalytics.emitEvent(
        "media/" + mediaId,
        "download",
        niz.userId ? niz.userId.toString() : ""
      );
    });
    this.mcService.addDownloadToMedia(niz).subscribe(
      (response) => { },
      (error) => {
        //
      }
    );
  }

  showStories() {
    if (!this.searchModel.stories || true) {
      this.searchModel.stories = true;
      this.searchModel.media = false;
      this.storageService.setSearchModel(this.searchModel);
      if (this.searchResultStories.stories.length == 0) {
        this.searchMc();
      }
    }
  }

  showMedia() {
    if (!this.searchModel.media || true) {
      this.searchModel.stories = false;
      this.searchModel.media = true;
      this.storageService.setSearchModel(this.searchModel);
      if (this.searchResultMedia.media.length == 0) {
        this.searchMc();
      }
    }
  }

  async searchMc() {
    if (this.searchModel.stories) {
      this.loaderStory = true;
      this.searchModel.languageId = Number(localStorage.getItem("lng"));
      this.searchModel.page = 1;
      this.searchModel.pageSize = 12;

      const response = await this.algoliaService.searchStories(
        this.searchModel.searchText,
        this.searchModel.page
      );
      const hits: AlgoliaNewsRecords[] = response.hits;
      this.searchResultStories.stories = hits
        .map((hit) => AlgoliaNewsRecords.convertToWebNewsModel(hit))
        .filter((story) => story?.fullHDUrl || story?.thumbUrl);
      this.maxPageStories = response.nbPages;
      for (const result of this.searchResultStories.stories) {
        if (result.featuredEmbeddedCode) {
          result.embeddedCode = this.sanitizer.bypassSecurityTrustHtml(
            result.featuredEmbeddedCode
          );
        }
      }
      this.checkMustBeRegistered(
        this.searchResultStories?.mustRegisterToAccess
      );
      this.storageService.setSearchModel(this.searchModel);
    }

    if (this.searchModel.media) {
      this.loaderMedia = true;
      this.searchModel.page = 1;
      this.searchModel.pageSize = 12;

      const response = await this.algoliaService.searchMedia(
        this.searchModel.searchText,
        this.searchModel.page
      );
      const hits: AlgoliaMediaRecords[] = response.hits;
      this.searchResultMedia.media = hits.map((hit) =>
        AlgoliaMediaRecords.convertToWebManageMediaModel(hit)
      );
      this.maxPageMedia = response.nbPages;
      this.checkMustBeRegistered(this.searchResultMedia?.mustRegisterToAccess);
      this.storageService.setSearchModel(this.searchModel);
    }

    this.resultStories = this.searchResultStories;
    this.resultMedia = this.searchResultMedia;
  }

  checkMustBeRegistered(mustRegisterToAccess) {
    if (
      mustRegisterToAccess === true &&
      localStorage.getItem("Token") == null
    ) {
      this.loader = false;
      sessionStorage.setItem("redirectUrl", "/" + window.location.pathname);
      this.router.navigateByUrl("/sign-in");
    } else {
      if (this.searchModel.media)
        this.storageService.setSearchItemMedia(this.searchResultMedia);
      else this.storageService.setSearchItemStories(this.searchResultStories);
      this.loader = false;
    }
  }

  updateMedia(e) {
    for (const media of this.resultMedia.media) {
      if (media.id === e.id) {
        media.downloads = e.downloads;
        media.shares = e.shares;
        media.views = e.views;
      }
    }
  }

  openPopup(id: number) {
    this.popup = true;
    this.mediaId = id;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.getDimensions();
  }

  ngOnInit() {
    this.cart = this.myFilesService.getMyFiles();
    this.loaderMedia = true;
    this.loaderStory = true;
    this.headerFooter = JSON.parse(localStorage.getItem("headerfooter"));
    if (this.headerFooter != null && this.headerFooter != undefined) {
      this.headerFooter.headerLinks.forEach((x) => (x.active = false));
      this.headerFooter.footerLinks.forEach((x) => (x.active = false));
    }
    this.storageService.getSearchModel().subscribe((searchModel) => {
      this.searchModel = searchModel;
    });

    this.user = TokenManagerService.getUser();

    this.storageService.getSearchItemStories().subscribe((term) => {
      //aca agarro nuevos resultados de busqueda desde el search, entonces arranco de 0
      this.resultStories = term;
      this.searchResultStories = term;
      if (this.resultStories.maxPages)
        this.maxPageStories = this.resultStories.maxPages;
      // if (!this.resultStories?.stories) {
      this.storyGroups = [[]];
      // }
      this.groupStories();
      this.loaderStory = false;
    });

    this.storageService.getSearchItemMedia().subscribe((term) => {
      //aca agarro nuevos resultados de busqueda desde el search, entonces arranco de 0
      this.resultMedia = term;
      this.searchResultMedia = term;
      if (this.resultMedia.maxPages)
        this.maxPageMedia = this.resultMedia.maxPages;
      // if (!this.resultMedia?.media) {
      this.mediaGroups = [[]];
      // }
      this.groupMedia();
      this.loaderMedia = false;
    });
  }

  groupStories() {
    let count = 0;
    if (this.searchResultStories?.stories || this.resultStories?.stories)
      for (const story of this.resultStories?.stories) {
        if (this.storyGroups[count].length >= 4) count++;
        if (!this.storyGroups[count]) this.storyGroups[count] = [];
        this.storyGroups[count].push(story);
      }
  }

  groupMedia() {
    let count = 0;
    if (this.searchResultMedia?.media || this.resultMedia?.media)
      for (const media of this.resultMedia?.media) {
        if (this.mediaGroups[count].length >= 4) count++;
        if (!this.mediaGroups[count]) this.mediaGroups[count] = [];
        this.mediaGroups[count].push(media);
      }
  }

  getDimensions() {
    const mediaPosition = document.getElementById(
      "media-wrapper"
    ) as HTMLElement;
    this.mediaTop = mediaPosition?.offsetTop;
    if (window.outerWidth < 768) {
      this.searchResults = 6;
    } else {
      this.searchResults = 10;
    }
  }

  showSubCategory(news) {
    news.subCategory = !news.subCategory;
  }

  getStoryUrl(storyId) {
    return (
      this.currentUrl.replace("results", "story") +
      "/" +
      storyId +
      "/" +
      UrlHelper.getUrlTitle(
        this.resultStories.stories.find((x) => x.staggingStoryId == storyId)
          .storyTitle
      )
    );
  }

  readMore(id) {
    if (localStorage.getItem("Token") === null) {
      this.user.id = null;
    }
    if (this.user.cmsUser == null) {
      this.googleAnalytics.emitEvent(
        "story/" + id.staggingStoryId,
        "view",
        this.user.id ? this.user.id.toString() : "anonymous"
      );
    }
    this.router.navigate([
      "/story/" +
      id.staggingStoryId +
      "/" +
      UrlHelper.getUrlTitle(
        this.resultStories.stories.find(
          (x) => x.staggingStoryId == id.staggingStoryId
        ).storyTitle
      ),
    ]);
  }

  openDropdown(id) {
    for (const x of this.resultStories.stories) {
      if (x.storyId === id) {
        x.dropdown = !x.dropdown;
      }
    }
  }

  openMediaPopup(media) {
    this.autoplay = false;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
    this.location.replaceState(media.relativeShareUrl);
  }

  openMediaPopupAutplay(media) {
    this.autoplay = true;
    this.mediaId = media.id;
    this.popup = true;
    media.views++;
    this.location.replaceState(media.relativeShareUrl);
  }

  async changePageStories(page) {
    this.pageStories = page;
    this.searchModel.page = page;
    const response = await this.algoliaService.searchStories(
      this.searchModel.searchText,
      this.searchModel.page
    );
    const hits: AlgoliaNewsRecords[] = response.hits;
    this.searchResultStories.stories = hits.map((hit) =>
      AlgoliaNewsRecords.convertToWebNewsModel(hit)
    );
    this.maxPageStories = response.nbPages;

    if (this.resultStories.socialSharing != null) {
      this.socials = this.resultStories.socialSharing;
    }
    this.resultStories.stories = this.resultStories.stories.concat(
      response.stories
    );
    this.groupStories();
    this.lazyLoadingLoader = false;
  }

  async changePageMedia(page) {
    this.pageMedia = page;
    this.searchModel.page = page;

    const response = await this.algoliaService.searchMedia(
      this.searchModel.searchText,
      this.searchModel.page
    );
    const hits: AlgoliaMediaRecords[] = response.hits;
    this.searchResultMedia.media = hits.map((hit) =>
      AlgoliaMediaRecords.convertToWebManageMediaModel(hit)
    );
    this.maxPageMedia = response.nbPages;

    if (response.media) {
      for (let f of response.media) {
        if (f.mediaType === "Audio" || f.mediaType === "Video") {
          f.mediaLengthMin = Math.floor(f.mediaLenght / 60);
          f.mediaLengthSec = f.mediaLenght - f.mediaLengthMin * 60;
        }
      }
    }

    if (response.socialSharing != null) {
      this.socials = response.socialSharing;
    }
    if (response.media != undefined) {
      const niz = response.media;
      for (const x of niz) {
        if (x.mediaType === "Document") {
          x.thumbUrl = "assets/images/document_default.png";
        } else if (x.mediaType === "Audio") {
          x.thumbUrl = "assets/images/audio_default.png";
        }
      }
      this.resultMedia.media = this.resultMedia.media.concat(response.media);
    }
    this.groupMedia();
    this.lazyLoadingLoader = false;
  }
}
