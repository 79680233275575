import { SafeHtml } from "@angular/platform-browser";

export class WebNewsListModel {
  public newsGridType: string;
  public maxNewsPerPage: number;
  public news: WebNewsModel[];
  public stories: WebNewsModel[];
  public mustRegisterToAccess: boolean;
  public mustRegisterToDownload: boolean;
  public socialSharing: string[];
  public totalCount: number;
  public logoUrl: string;
  public hideCounter: boolean;
}

export class WebNewsModel {
  public storyId: number;
  public staggingStoryId: number;
  public audio: number;
  public video: number;
  public images: number;
  public document: number;
  public publishedDate: string;
  public category: string[];
  public storyTitle: string;
  public shortDescription: string;
  public thumbUrl: string;
  public fullHDUrl?: string;
  public featuredEmbeddedCode: string;
  public embeddedCode: SafeHtml;
  public views: number;
  public shared: number;
  public hideStoryDate: boolean;

  //added new properties
  public shareDropdown: boolean;
  public categoryDropdown: boolean;
  public subCategory: boolean;
}

export class WebNewsDetailsModel {
  public smallHeroImage: boolean;
  public youMayAlsoLikeSection: boolean;
  public storyId: number;
  public audio: number;
  public video: number;
  public images: number;
  public document: number;
  public publishedDate: string;
  public socialSharing: string[];
  public featuredMediaType: string;
  public mustRegisterToDownload: boolean;
  public mustRegisterToAccess: boolean;
  public anonymousShare: boolean;
  public category: WebNewsDetailsCategoryModel[];
  public storyTitle: string;
  public shortDescription: string;
  public text: string;
  public thumbUrl: string;
  public featuredEmbeddedCode: string;
  public embeddedCode: SafeHtml;
  public views: number;
  public shared: number;
  public media: WebNewsDetailsMediaModel[];
  public alsoMayLike: WebNewsModel[];
  public isMobileCrop: boolean;
  public hideCounters: boolean;
  public hideStoryDate: boolean;
  //added new properties
  public sharedDropdown: boolean;
  public categoryDropdown: boolean;
  public staggingStoryId?: number;
  public mediaId: number;
}

export class WebNewsDetailsCategoryModel {
  public id: number;
  public name: string;
  public isCustom: boolean;
}

export class WebNewsDetailsMediaModel {
  public id: number;
  public staggingStoryId: number;
  public thumbUrl: string;
  public fullHDUrl?: string;
  public name: string;
  public downloadLink: string;
  public mediaType: string;
  public views: number;
  public downloads: number;
  public lastDownloads: string[];
  public shareUrl: any;
  public mediaLenght: number;
  public isPublished: boolean;
  public relativeShareUrl: string;

  //added new properties
  public checked = false;
  public addedToCart: boolean;
  public mediaDropdown: boolean;
  public shares?: number;

  public mediaLengthMin: number;
  public mediaLengthSec: number;
}

export class NewsViewModel {
  public newsId: number;
  public userId: number;
}
