<div class="search-wrapper flex-center" [ngClass]="{ 'showing-loader': loader }">
  <div class="search-box flex">
    <div class="search-box-header">
      <div class="close-search" (click)="closeSearch()">
        <i class="fas fa-chevron-left"></i>
        <span>Go Back</span>
      </div>
    </div>

    <div class="search-content-wrapper">
      <div class="select-wrapper flex">
        <div class="search-from">
          <div class="smallheadline">
            {{ language.searchFrom }}
          </div>
          <div class="buttons-wrapper flex">
            <div class="generic-button empty" [ngClass]="{ active: searchFrom == 'stories' }"
              (click)="setSearchFrom('stories')">
              Stories
            </div>
            <div class="generic-button empty" [ngClass]="{ active: searchFrom == 'media' }"
              (click)="setSearchFrom('media')">
              Media
            </div>
          </div>
        </div>
        <div class="select-date">
          <div class="smallheadline">
            {{ language.selectDate }}
          </div>
          <div class="buttons-wrapper flex">
            <div class="from">
              <div class="calendar-input flex">
                <i class="fal fa-calendar-alt" (click)="openDateFrom()"></i>
                <dx-date-box [(value)]="searchModelData.searchModel.dateFrom" [opened]="dateFromButton"
                  [displayFormat]="'dd/MM/yyyy'" type="date" (onValueChanged)="changeFromDate($event)" name="dateFrom"
                  [placeholder]="language.from">
                </dx-date-box>
              </div>
            </div>
            <div class="to">
              <div class="calendar-input flex">
                <i class="fal fa-calendar-alt" (click)="openDateTo()"></i>
                <dx-date-box [(value)]="searchModelData.searchModel.dateTo" [opened]="dateToButton"
                  [displayFormat]="'dd/MM/yyyy'" (onValueChanged)="changeToDate($event)" type="date" name="dateTo"
                  [placeholder]="language.to">
                </dx-date-box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear-all" (click)="clearAll()">Clear all</div>
      <div class="search-input-wrapper">
        <div class="search-icon"><i class="fas fa-search"></i></div>
        <input autocomplete="off" id="story-search-big" type="search" placeholder="{{ language.searchHere }}"
          [(ngModel)]="searchModelData.searchModel.searchText" />
      </div>

      <div class="select-media flex" *ngIf="searchFrom != 'stories'">
        <div class="generic-button empty" [ngClass]="{ active: searchModelData.searchModel.images }" (click)="
            searchModelData.searchModel.images =
              !searchModelData.searchModel.images
          ">
          <i class="fal fa-camera-retro"></i>
          {{ language.images }}
        </div>

        <div class="generic-button empty" [ngClass]="{ active: searchModelData.searchModel.video }" (click)="
            searchModelData.searchModel.video =
              !searchModelData.searchModel.video
          ">
          <i class="fal fa-play"></i>
          {{ language.videoFiles }}
        </div>
        <div class="generic-button empty" [ngClass]="{ active: searchModelData.searchModel.document }" (click)="
            searchModelData.searchModel.document =
              !searchModelData.searchModel.document
          ">
          <i class="fal fa-file"></i>
          {{ language.documents }}
        </div>

        <div class="generic-button empty" [ngClass]="{ active: searchModelData.searchModel.audio }" (click)="
            searchModelData.searchModel.audio =
              !searchModelData.searchModel.audio
          ">
          <i class="fal fa-volume-up"></i>
          {{ language.audioFiles }}
        </div>
      </div>

      <div class="input-wrapper flex flex-between">
        <!-- <div *ngIf="
          searchModelData.customCategories?.length > 0 ||
          searchModelData.mainCategories?.length > 0
        " class="categories section" [ngClass]="[searchModelData.subClients.length == 0 ? 'no-clients' : '']">
          <div class="flex flex-between">
            <div class="smallheadline">
              {{ language.categories }}
            </div>
          </div>

          <div class="column-wrapper flex" [ngClass]="[
            searchModelData.subClients.length == 0 ? 'no-clients' : ''
          ]">
            <ng-container *ngIf="!searchModelData?.hasCustomCategories">
              <div class="list-option flex" *ngFor=" let category of searchModelData?.mainCategories let i = index ">
                <input class="allS" [checked]="category.checked" type="checkbox" id="category-{{ i }}"
                  (click)="mainChecked( category)" />
                <label class="short-view" for="category-{{ i }}">{{ category.label }}</label>
              </div>
            </ng-container>

            <ng-container *ngIf="searchModelData?.hasCustomCategories">
              <div class="list-option flex"
                *ngFor=" let category of filterUniqueCategories(searchModelData?.customCategories) let i = index ">
                <input class="allS" [checked]="category.marked" type="checkbox" id="custom-category-{{ i }}"
                  (click)="customChecked(category)" />
                <label class="short-view" for="custom-category-{{ i }}">{{ category.label }}</label>
              </div>
            </ng-container>
          </div>
        </div> -->

        <div class="brands section" *ngIf="searchModelData.subClients?.length > 0">
          <div class="flex flex-between">
            <div class="smallheadline">
              {{ language.brands }}
            </div>
          </div>
          <div class="column-wrapper flex">
            <div class="list-option flex" *ngFor="let brand of searchModelData.subClients">
              <input class="allSb" [checked]="brand.checked" type="checkbox" (click)="brandChecked(brand)" />
              <label class="short-view">{{ brand.label | shorten : 15 }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="generic-button search-button" (click)="searchMc()">
        {{ language.search }}
      </div>
    </div>
  </div>
  <app-loader *ngIf="loader"></app-loader>
</div>