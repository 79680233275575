export const environment = {
  production: false,
  cmsUrl: 'https://devcms.moonideas.com/redirected?token=',
  apiUrlRoot: "https://dna-api-development.azurewebsites.net",
  apiUrl: "https://dna-api-development.azurewebsites.net/api",
  signalrUrl: "https://signalr-develop.digitalnewsagency.com:5002",
  mediaUrl: "https://dnamediastorage.blob.core.windows.net/",
  facebookAppId: "137527330414505",
  facebookAppSecret: "9142cc75dfbbe38e5a8104c057fc7bea",
  algoliaApplicationId: "0KMDPEZ84J",
  algoliaApiKey: "e44d0540379aa8b931318b3275dc2b7e",
  algoliaStoriesIndex: 'dev_stories',
  algoliaMediaIndex: 'dev_media',
};
