<section class="profile">
  <div class="section-toggle">
    <div class="section-button" [ngClass]="{ active: activeSection == 'file-history' }"
      (click)="activeSection = 'file-history'">
      {{ language.myFiles }}
    </div>
    <div class="section-button" [ngClass]="{ active: activeSection == 'saved-stories' }"
      (click)="activeSection = 'saved-stories'">
      {{ language.savedStories }}
    </div>
  </div>

  <div class="file-history" *ngIf="activeSection == 'file-history'">
    <div class="filters-wrapper">
      <div class="file-type-filters">
        <ng-container *ngFor="let filter of filters">
          <div class="filter-button flex generic-button empty" (click)="filterType(filter.type)"
            [ngClass]="{ active: fileType == filter.type }">
            <i [ngClass]="[filter.faClass]"></i>
            <span>{{ filter.text }}</span>
          </div>
        </ng-container>
      </div>
      <div class="filter-bottom-bar date-filters">
        <div class="buttons-wrapper flex">
          <div class="from">
            <div class="calendar-input flex">
              <i class="fal fa-calendar-alt" (click)="openDateFrom()"></i>
              <dx-date-box [(value)]="dateFrom" [opened]="dateFromButton" [displayFormat]="'dd/MM/yyyy'" type="date"
                (onValueChanged)="changeFromDate($event)" name="dateFrom" [placeholder]="language.from">
              </dx-date-box>
            </div>
          </div>
          <div class="to">
            <div class="calendar-input flex">
              <i class="fal fa-calendar-alt" (click)="openDateTo()"></i>
              <dx-date-box [(value)]="dateTo" [opened]="dateToButton" [displayFormat]="'dd/MM/yyyy'"
                (onValueChanged)="changeToDate($event)" type="date" name="dateTo" [placeholder]="language.to">
              </dx-date-box>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="download-boxes">
      <div class="download-box" *ngIf="myFilesService.getMyFiles()?.length > 0">
        <div class="download-box-header">
          <div class="info">
            <div class="date">{{ language.current }}</div>
            <div class="files-amount">
              {{ myFilesService.getMyFiles()?.length }} {{ language.files }}
            </div>
          </div>
          <div class="actions">
            <div class="download-all generic-button flex inverse" (click)="downloadCart()">
              <i class="fas fa-download"></i>
              <span>{{ language.download || language.downloadAll }}</span>
            </div>
            <div class="minimizeSection" [ngClass]="{ opened: cartFilesOpened }"
              (click)="cartFilesOpened = !cartFilesOpened">
              <i *ngIf="cartFilesOpened" class="fas fa-minus"></i>
              <i *ngIf="!cartFilesOpened" class="fas fa-plus"></i>
            </div>
          </div>
        </div>
        <div class="media-feed" *ngIf="cartFilesOpened">
          <ng-container *ngFor="let media of cartMedias">
            <app-media-file *ngIf="showFile(media)" [media]="media" [config]="fileHistoryModel"
              class="media-image"></app-media-file>
          </ng-container>
        </div>
      </div>
      <ng-container *ngFor="let box of fileHistoryModel?.downloadBoxes">
        <div class="download-box" *ngIf="showBox(box)">
          <div class="download-box-header">
            <div class="info">
              <div class="date">{{ parseDate(box.date) }}</div>
              <div class="files-amount">
                {{ box.storyMedias?.length }} {{ language.files }}
              </div>
            </div>
            <div class="actions">
              <div class="download-all generic-button flex inverse" (click)="downloadBox(box)">
                <i class="fas fa-download"></i>
                <span>{{ language.download || language.downloadAll }}</span>
              </div>
              <div class="minimizeSection" [ngClass]="{ opened: box.opened }" (click)="box.opened = !box.opened">
                <i *ngIf="box.opened" class="fas fa-minus"></i>
                <i *ngIf="!box.opened" class="fas fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="media-feed" *ngIf="box.opened">
            <ng-container *ngFor="let media of box.storyMedias">
              <app-media-file *ngIf="showFile(media)" [media]="media" [config]="fileHistoryModel"
                class="media-image"></app-media-file>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div class="no-results" *ngIf="showNoResults()">
        {{ language.noDownloadBoxes }}
      </div>
    </div>
  </div>
  <div class="saved-stories-container" *ngIf="activeSection == 'saved-stories'">
    <div class="saved-stories">
      <ng-container *ngFor="let story of savedStories?.stories; index as i">
        <app-story-preview [story]="story" [config]="savedStories" [index]="i">
        </app-story-preview>
      </ng-container>
    </div>
    <div class="no-results" *ngIf="showNoResults()">
      {{ language.noStoriesSaved }}
    </div>
  </div>
  <app-loader *ngIf="loader > 0"></app-loader>
  <div class="my-profile">
    <div class="flex my-profile-header-container">
      <h1 class="my-profile-header">{{ language.myAccount }}</h1>
      <i (click)="editFormOpened = true" *ngIf="!editFormOpened" class="fas fa-edit"></i><i
        (click)="editFormOpened = false" *ngIf="editFormOpened" class="fas fa-times"></i>
    </div>
    <div class="profile-container" *ngIf="editFormOpened">
      <form action="" class="register-form" [formGroup]="valForm" (ngSubmit)="onAccountFormSubmit($event)">
        <ng-container *ngIf="isAdmin">
          <div class="account-element">
            <label for="name">{{ language.firstName }}</label>
            <input type="text" id="name" placeholder="Enter your first name"
              [(ngModel)]="registerResponseModel?.adminModel.firstName" formControlName="firstname" />
            <span class="text-danger" *ngIf="
                valForm.controls['firstname'].hasError('required') &&
                (valForm.controls['firstname'].dirty ||
                  valForm.controls['firstname'].touched)
              ">
              {{ language.firstNameRequired }}</span>
          </div>
          <div class="account-element">
            <label for="lastname">{{ language.lastName }}</label>
            <input type="text" id="lastname" placeholder="Enter your last name"
              [(ngModel)]="registerResponseModel?.adminModel.lastName" formControlName="lastname" />
            <span class="text-danger" *ngIf="
                valForm.controls['lastname'].hasError('required') &&
                (valForm.controls['lastname'].dirty ||
                  valForm.controls['lastname'].touched)
              ">
              {{ language.lastNameRequired }}</span>
          </div>

          <div class="account-element">
            <label for="email">{{ language.email }}</label>
            <input type="text" id="email" placeholder="{{language.enterEmail}}"
              [(ngModel)]="registerResponseModel?.adminModel.email" (keyup)="checkIfEmpty()" formControlName="email" />
            <span class="text-danger" *ngIf="
                valForm.controls['email'].hasError('required') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              ">
              {{ language.emailNameRequired }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['email'].hasError('email') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              ">{{ language.invalidEmail }}</span>
          </div>

          <div class="account-element">
            <label for="phone">{{ language.telephone }}</label>
            <input type="text" id="phone" placeholder="Enter your phone number"
              [(ngModel)]="registerResponseModel?.adminModel.telephoneNumber" formControlName="telephone" />
          </div>

          <div class="account-element">
            <label for="name">{{ language.password }}</label>
            <input autocomplete="off" type="password" id="password" (keyup)="setConfirmError()"
              placeholder="Leave empty to keep current password"
              [(ngModel)]="registerResponseModel?.adminModel.password" formControlName="password" (focus)="onPwFocus()"
              (blur)="onPwBlur()" />
            <span class="text-danger" *ngIf="
                valForm.controls['password'].hasError('maxlength') &&
                (valForm.controls['password'].dirty ||
                  valForm.controls['password'].touched)
              ">
              {{ language.max50Characters }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['password'].hasError('minlength') &&
                (valForm.controls['password'].dirty ||
                  valForm.controls['password'].touched)
              ">
              {{ language.min4Characters }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['password'].hasError('pattern') &&
                (valForm.controls['password'].dirty ||
                  valForm.controls['password'].touched) &&
                !valForm.controls['newPassword'].hasError('minlength')
              ">
              {{ language.passwordNoSpaces }}</span>
            <div class="password-checker" *ngIf="checkPasswordService.hasPasswordHardening() && isPwFocus">
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckNumber(registerResponseModel?.adminModel.password)">X
                </div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckNumber(registerResponseModel?.adminModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckNumber(registerResponseModel?.adminModel.password) ? 'pw-green' : 'pw-red' ">Number
                  (0-9)</span>
              </div>
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckSpecial(registerResponseModel?.adminModel.password)">X
                </div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckSpecial(registerResponseModel?.adminModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckSpecial(registerResponseModel?.adminModel.password) ? 'pw-green' : 'pw-red' ">Special
                  Character</span>
              </div>
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckLength(registerResponseModel?.adminModel.password)">X
                </div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckLength(registerResponseModel?.adminModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckLength(registerResponseModel?.adminModel.password) ? 'pw-green' : 'pw-red' ">Atleast
                  8 Character</span>
              </div>
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckCase(registerResponseModel?.adminModel.password)">
                  X</div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckCase(registerResponseModel?.adminModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckCase(registerResponseModel?.adminModel.password) ? 'pw-green' : 'pw-red' ">Lowercase
                  & Uppercase</span>
              </div>
            </div>
          </div>

          <div class="account-element">
            <label for="lastname">{{ language.confirmPassword }}</label>
            <input [(ngModel)]="registerResponseModel?.adminModel.confirmPassword"
              [ngClass]="[confirmError ? 'ng-invalid ng-touched' : '']" type="password" id="repeat-password"
              placeholder="Repeat your password" formControlName="confirmPassword" (keyup)="checkPassword()" />
            <span class="text-danger" *ngIf="
                valForm.controls['confirmPassword'].hasError('required') &&
                (valForm.controls['confirmPassword'].dirty ||
                  valForm.controls['confirmPassword'].touched)
              ">
              {{ language.confirmPasswordRequired }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['confirmPassword'].value !=
                  valForm.controls['password'].value &&
                !valForm.controls['confirmPassword'].hasError('required') &&
                valForm.controls['password'].value != ''
              ">{{ language.passwordsNoMatch }}</span>
          </div>

          <div class="account-element" *ngIf="isPasswordSet">
            <label for="lastname">{{ language.currentPassword }}</label>
            <input [(ngModel)]="registerResponseModel?.adminModel.password"
              [ngClass]="[oldPassword ? 'ng-invalid ng-touched' : '']" (keyup)="ifEmpty()" type="password"
              id="old-password" placeholder="Enter your old password" formControlName="oldPassword" />
            <span class="text-danger" *ngIf="oldPassword">{{ language.mustEnterFirstPassword }}</span>
            <span class="text-danger" *ngIf="enterPw">{{ language.mustEnterOldPassword }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!isAdmin">
          <div class="account-element">
            <label for="name">{{ language.firstName }}</label>
            <input type="text" id="name" placeholder="Enter your first name"
              [(ngModel)]="registerResponseModel?.mediaUserModel.firstName" formControlName="firstname" />
            <span class="text-danger" *ngIf="
                valForm.controls['firstname'].hasError('required') &&
                (valForm.controls['firstname'].dirty ||
                  valForm.controls['firstname'].touched)
              ">
              {{ language.firstNameRequired }}</span>
          </div>

          <div class="account-element">
            <label for="lastname">{{ language.lastName }}</label>
            <input type="text" id="lastname" placeholder="Enter your last name"
              [(ngModel)]="registerResponseModel?.mediaUserModel.lastName" formControlName="lastname" />
            <span class="text-danger" *ngIf="
                valForm.controls['lastname'].hasError('required') &&
                (valForm.controls['lastname'].dirty ||
                  valForm.controls['lastname'].touched)
              ">
              {{ language.lastNameRequired }}</span>
          </div>

          <div class="account-element">
            <label for="email">{{ language.email }}</label>
            <input type="text" id="email" placeholder="{{language.enterEmail}}"
              [(ngModel)]="registerResponseModel?.mediaUserModel.email" (keyup)="checkIfEmpty()"
              formControlName="email" />
            <span class="text-danger" *ngIf="
                valForm.controls['email'].hasError('required') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              ">
              {{ language.emailNameRequired }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['email'].hasError('email') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              ">{{ language.invalidEmail }}</span>
          </div>

          <div class="account-element">
            <label for="phone">{{ language.telephone }}</label>
            <input type="text" id="phone" placeholder="Enter your phone number" [(ngModel)]="
                registerResponseModel?.mediaUserModel.telephoneNumber
              " formControlName="telephone" />
          </div>

          <div class="account-element" *ngIf="showPw">
            <label for="name">{{ language.password }}</label>
            <input autocomplete="off" type="password" id="password" (keyup)="setConfirmError()"
              placeholder="{{language.enterPassword}}" [(ngModel)]="registerResponseModel?.mediaUserModel.password"
              formControlName="password" (focus)="onPwFocus()" (blur)="onPwBlur()" />
            <span class="text-danger" *ngIf="
                valForm.controls['password'].hasError('maxlength') &&
                (valForm.controls['password'].dirty ||
                  valForm.controls['password'].touched)
              ">
              {{ language.max50Characters }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['password'].hasError('minlength') &&
                (valForm.controls['password'].dirty ||
                  valForm.controls['password'].touched)
              ">
              {{ language.min4Characters }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['password'].hasError('pattern') &&
                (valForm.controls['password'].dirty ||
                  valForm.controls['password'].touched) &&
                !valForm.controls['newPassword'].hasError('minlength')
              ">
              {{ language.passwordNoSpaces }}</span>
            <div class="password-checker" *ngIf="checkPasswordService.hasPasswordHardening() && isPwFocus">
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckNumber(registerResponseModel?.mediaUserModel.password)">X
                </div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckNumber(registerResponseModel?.mediaUserModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckNumber(registerResponseModel?.mediaUserModel.password) ? 'pw-green' : 'pw-red' ">Number
                  (0-9)</span>
              </div>
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckSpecial(registerResponseModel?.mediaUserModel.password)">X
                </div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckSpecial(registerResponseModel?.mediaUserModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckSpecial(registerResponseModel?.mediaUserModel.password) ? 'pw-green' : 'pw-red' ">Special
                  Character</span>
              </div>
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckLength(registerResponseModel?.mediaUserModel.password)">X
                </div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckLength(registerResponseModel?.mediaUserModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckLength(registerResponseModel?.mediaUserModel.password) ? 'pw-green' : 'pw-red' ">Atleast
                  8 Character</span>
              </div>
              <div class="password-checker-item">
                <div class="pw-error"
                  *ngIf="!checkPasswordService.passwordCheckCase(registerResponseModel?.mediaUserModel.password)">
                  X</div>
                <img src="/assets/icons/pw-tick.svg"
                  *ngIf="checkPasswordService.passwordCheckCase(registerResponseModel?.mediaUserModel.password)">
                <span
                  [ngClass]="checkPasswordService.passwordCheckCase(registerResponseModel?.mediaUserModel.password) ? 'pw-green' : 'pw-red' ">Lowercase
                  & Uppercase</span>
              </div>
            </div>
          </div>

          <div class="account-element" *ngIf="showPw">
            <label for="lastname">{{ language.confirmPassword }}</label>
            <input [(ngModel)]="
                registerResponseModel?.mediaUserModel.confirmPassword
              " [ngClass]="[confirmError ? 'ng-invalid ng-touched' : '']" type="password" id="repeat-password"
              placeholder="Repeat your password" formControlName="confirmPassword" (keyup)="checkPassword()" />
            <span class="text-danger" *ngIf="
                valForm.controls['confirmPassword'].hasError('required') &&
                (valForm.controls['confirmPassword'].dirty ||
                  valForm.controls['confirmPassword'].touched)
              ">
              {{ language.confirmPasswordRequired }}</span>
            <span class="text-danger" *ngIf="
                valForm.controls['confirmPassword'].value !=
                  valForm.controls['password'].value &&
                !valForm.controls['confirmPassword'].hasError('required') &&
                valForm.controls['password'].value != ''
              ">{{ language.passwordsNoMatch }}</span>
          </div>

          <div class="account-element" *ngIf="isPasswordSet">
            <label for="lastname">{{ language.currentPassword }}</label>
            <input [(ngModel)]="registerResponseModel?.mediaUserModel.oldPassword"
              [ngClass]="[oldPassword ? 'ng-invalid ng-touched' : '']" (keyup)="ifEmpty()" type="password"
              id="old-password" placeholder="Leave empty to keep current password" formControlName="oldPassword" />
            <span class="text-danger" *ngIf="oldPassword">{{ language.mustEnterFirstPassword }}</span>
            <span class="text-danger" *ngIf="enterPw">{{ language.mustEnterOldPassword }}</span>
          </div>

          <div class="account-element">
            <label for="lastname">{{ language.companyName }}</label>
            <input type="text" id="company" placeholder="Enter your company name"
              [(ngModel)]="registerResponseModel?.mediaUserModel.companyName" formControlName="company" />
          </div>

          <div class="account-element">
            <label>{{ language.categoriesOfInterest }}</label>
            <div class="select bigger register-dropdown flex-center" (click)="categoryDropdown = !categoryDropdown">
              <span *ngIf="categories.length === 0">{{
                language.categoriesOfInterest
                }}</span>
              <div class="flex select-list">
                <div>{{ getSelectedCategoriesText() }}</div>
              </div>

              <div class="dropdown flex flex-column" *ngIf="categoryDropdown">
                <div class="choice flex" *ngFor="
                    let category of registerResponseModel.customCatogories
                  " (click)="$event.stopPropagation(); addCategory(category)">
                  <input type="checkbox" [checked]="category.marked" (click)="
                      $event.stopPropagation(); addCategory(category, $event)
                    " />
                  <div>{{ category.label }}</div>
                </div>
                <div class="button flex-center" (click)="categoryDropdown = true">
                  {{ language.done }}
                </div>
              </div>
            </div>
          </div>

          <div class="account-element">
            <label>{{ language.languages }}</label>
            <div class="select bigger register-dropdown flex-center" (click)="languageDropDown = !languageDropDown">
              <span *ngIf="languages.length === 0">{{
                language.languages
                }}</span>
              <div class="flex select-list">
                <ng-container *ngFor="let lan of languages; let isLast = last">
                  <div>{{ lan }}{{ isLast ? "" : ", " }}</div>
                </ng-container>
              </div>
              <div class="dropdown flex flex-column" *ngIf="languageDropDown">
                <div class="choice flex" *ngFor="let lang of registerResponseModel.languages"
                  (click)="$event.stopPropagation(); addLanguage(lang)">
                  <input type="checkbox" [checked]="lang.checked" />
                  <div>{{ lang.label }}</div>
                </div>
                <div class="button flex-center" (click)="languageDropDown = true">
                  {{ language.done }}
                </div>
              </div>
            </div>
          </div>

          <div class="account-element">
            <label>{{ language.userType }}</label>
            <div class="select bigger register-dropdown flex-center" (click)="mediaUser = !mediaUser">
              <span *ngIf="usersToShow.length === 0">Users</span>
              <div class="flex select-list">
                <ng-container *ngFor="let user of usersToShow; let isLast = last">
                  <div>{{ user }}{{ isLast ? "" : ", " }}</div>
                </ng-container>
              </div>
              <div class="dropdown flex flex-column" *ngIf="mediaUser">
                <div class="choice flex" *ngFor="let user of registerResponseModel.userGroups"
                  (click)="$event.stopPropagation(); addUser(user, $event)">
                  <input type="checkbox" [checked]="user.checked" />
                  <div>{{ user.name }}</div>
                </div>
                <div class="button flex-center" (click)="mediaUser = true">
                  {{ language.done }}
                </div>
              </div>
            </div>
            <div class="text-danger" *ngIf="userFilled == false">
              {{ language.mediaUserTypeRequired }}
            </div>
          </div>

          <div div class="account-element social-handles">
            <label for="#">{{ language.socialHandles }}</label>
            <div class="add-wrapper">
              <div class="generic-button flex" (click)="addDropdown = !addDropdown">
                <div class="add flex-center">
                  <i class="far fa-plus"></i>
                </div>
                <div class="text-button flex-center">{{ language.add }}</div>
              </div>
              <div class="dropdown flex flex-column" *ngIf="addDropdown">
                <div class="choice flex">
                  <input type="checkbox" [ngClass]="{ facebook: 'active' }" [ngClass]="{ facebook: 'active' }"
                    (change)="facebook = !facebook" [checked]="facebook" />
                  <div>Facebook</div>
                </div>
                <div class="choice flex">
                  <input type="checkbox" [ngClass]="{ twitter: 'active' }" (change)="twitter = !twitter"
                    [checked]="twitter" />
                  <div>Twitter</div>
                </div>
                <div class="choice flex">
                  <input type="checkbox" [ngClass]="{ linkedin: 'active' }" (change)="linkedin = !linkedin"
                    [checked]="linkedin" />
                  <div>Linkedin</div>
                </div>
                <div class="choice flex">
                  <input type="checkbox" [ngClass]="{ instagram: 'active' }" (change)="instagram = !instagram"
                    [checked]="instagram" />
                  <div>Instagram</div>
                </div>
                <div class="choice flex">
                  <input type="checkbox" [ngClass]="{ youtube: 'active' }" (change)="youtube = !youtube"
                    [checked]="youtube" />
                  <div>Youtube</div>
                </div>
                <div class="choice flex">
                  <input type="checkbox" [ngClass]="{ wordpress: 'active' }" (change)="wordpress = !wordpress"
                    [checked]="wordpress" />
                  <div>WordPress</div>
                </div>
                <div class="button flex-center" (click)="addDropdown = false">
                  {{ language.done }}
                </div>
              </div>
            </div>
          </div>
          <div class="social-handles social-wrapper flex">
            <div class="social-button flex" *ngIf="facebook">
              <div class="fb-icon"></div>
              <input type="text" placeholder="facebook" [(ngModel)]="registerResponseModel.mediaUserModel.facebookLink"
                formControlName="facebook" />
            </div>
            <div class="social-button flex" *ngIf="twitter">
              <div class="tw-icon"></div>
              <input type="text" placeholder="twitter" [(ngModel)]="registerResponseModel.mediaUserModel.twitterLink"
                formControlName="twitter" />
            </div>
            <div class="social-button flex" *ngIf="instagram">
              <div class="ig-icon"></div>
              <input type="text" placeholder="instagram"
                [(ngModel)]="registerResponseModel.mediaUserModel.instagramLink" formControlName="instagram" />
            </div>
            <div class="social-button flex" *ngIf="linkedin">
              <div class="linkedin-icon"></div>
              <input type="text" placeholder="linkedin" [(ngModel)]="registerResponseModel.mediaUserModel.linkedInLink"
                formControlName="linkedin" />
            </div>
            <div class="social-button flex" *ngIf="youtube">
              <div class="youtube-icon"></div>
              <input type="text" placeholder="youtube" [(ngModel)]="registerResponseModel.mediaUserModel.youTubeLink"
                formControlName="youtube" />
            </div>
            <div class="social-button flex" *ngIf="wordpress">
              <div class="wordpress-icon"></div>
              <input type="text" placeholder="wordpress"
                [(ngModel)]="registerResponseModel.mediaUserModel.wordPressLink" formControlName="wordpress" />
            </div>
          </div>

          <div class="checkbox-wrapper">
            <div class="flex">
              <input type="checkbox" [(ngModel)]="
                  registerResponseModel?.mediaUserModel.receiveNewsletter
                " (click)="newsletterCheck()" [checked]="
                  registerResponseModel.mediaUserModel.receiveNewsletter
                " formControlName="newsletter" />
              <span>{{ language.iWantNewsletter }}</span>
            </div>
          </div>
        </ng-container>
        <div class="register-buttons flex-center">
          <button type="submit" class="generic-button" appStyle>Update</button>
        </div>
      </form>
      <div class="deleteAccount" (click)="deleteAccount = true">
        {{ language.deleteAccount }}
      </div>
      <div class="loginAttempts">
        <h1>Last Login Attempts</h1>
        <ng-container *ngFor="let loginAttempt of loginAttempts">
          <div class="tableRow">
            <p>{{ loginAttempt.time | date : "dd/MM/yyyy hh:mm a" }}</p>
            <span class="{{ !loginAttempt.failed ? 'successful' : 'failed' }}">{{ !loginAttempt.failed ? 'Successful' :
              'Failed' }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<div class="deleteAccountPopup" *ngIf="deleteAccount">
  <div class="popup-container">
    <div class="popup-header">
      <div class="title">{{ language.deleteAccount }}</div>
      <div class="close" (click)="deleteAccount = false">x</div>
    </div>
    <div class="popup-content">
      <div class="text">
        {{ language.deleteAccountConfirmation }}
      </div>
      <input type="password" [ngClass]="{ 'error-input': wrongPassword }" placeholder="Password"
        [(ngModel)]="passwordForDeleting" />
      <div class="wrong-password" *ngIf="wrongPassword">
        {{ language.incorrectPassword }}
      </div>
      <div class="buttons">
        <div class="cancel" (click)="dontDeleteMyAccount()">{{ language.cancel }}</div>
        <div class="delete" (click)="deleteMyAccount()">{{ language.delete }}</div>
      </div>
    </div>
  </div>
</div>