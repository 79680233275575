import { Component, OnInit } from '@angular/core';
import { ContactsService } from './contacts.service';
import { ContactsModel } from './contacts.model';
import { Meta } from '@angular/platform-browser';
import { McService } from '../../shared/services/mc-service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageStyle } from '../../models/style/style.model';
import { FontsAndColorsService } from '../../shared/services/fonts-and-colors.service';
import { MetaModel } from '../../models/meta/meta';
import { LanguageService } from '../../shared/services/language.service';
import { Language } from '../../models/language/language.model';
import { BrandsManageModel, BrandsModel } from '../brands/brands-model';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less'],
  providers: [ContactsService]
})
export class ContactsComponent implements OnInit {
  contacts = new ContactsModel();
  loader: boolean;
  styles = new PageStyle();
  metadatas: MetaModel;
  language: Language;
  brandsResponse = new BrandsModel();
  brands: BrandsManageModel[] = [];
  isEasyJet: boolean = false;

  constructor(private ContactsService: ContactsService, private languageService: LanguageService, private meta: Meta, private titleService: Title, private router: Router,
    private mcService: McService, private fontsAndColorsService: FontsAndColorsService) {
    this.styles = fontsAndColorsService.getPageStyleInfo();
    this.language = languageService.getLanguage();

    this.languageService.language.subscribe(
      (language) => {
        this.language = language;
      }
    );
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getContacts();
    // if (localStorage.getItem("isEasyJet") === "1") {
    //   this.isEasyJet = true;
    // }
  }

  getContacts() {
    this.loader = true;
    this.getHardcodedContacts();
    // this.ContactsService.mediaCenterWebContacts()

    //   .subscribe((response: any) => {
    //     // this.contacts = response;
    //     this.loader = false;
    //   },
    //     (error) => {
    //       //
    //     });
  }

  getHardcodedContacts() {
    this.contacts.ourTeam = [
      {
        name: 'Holly Mitchell',
        email: 'Holly.Mitchell@easyjet.com',
        description: 'for easyJet media enquiries for journalists from the UK or UK publications.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Holly Mitchell.jpg',
      },
      {
        name: 'Andy Cockburn',
        email: 'Andy.Cockburn@easyJet.com',
        description: 'For easyJet media enquiries for journalists from the UK or UK publications on London Gatwick, London Luton, London Stansted, London Southend, Liverpool and Manchester as well as Egypt, Israel, Turkey and Jordan.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Andy Cockburn.jpg',
      },
      {
        name: 'Paula Peralta',
        email: 'Paula.Peralta@easyjet.com',
        description: 'for easyJet media enquiries for journalists from the UK or UK publications on Scotland and Northern Ireland as well as the Isle of Man, Jersey and Gibraltar.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Paula Peralta.jpg',
      },
      {
        name: 'Lottie Phillips',
        email: 'Lottie.phillips@easyJet.com',
        description: 'For easyJet media enquiries for Bristol, Birmingham, Bournemouth, Newcastle, Newquay, Southampton, as well as Denmark, Sweden, Norway and Finland and Iceland.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Lottie Phillips.jpg',
      },
      {
        name: 'Kirsten de Haan',
        email: 'Kirsten.dehaan@easyjet.com',
        description: 'for easyJet media enquiries for journalists from the Netherlands and Belgium.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Kirsten Dehaan.jpg',
      },
      {
        name: 'Matteo Finotto',
        email: 'Matteo.Finotto@easyJet.com',
        description: 'for easyJet media enquiries for journalists from Southern Europe and the Balkans (Italy, Spain, Portugal, Greece, Bulgaria, Croatia, Cyprus, Kosovo, Serbia, Montenegro and Slovenia).',
        position: '1',
        thumbUrl: '../../assets/easyJet/Matteo Finotto.jpg',
      },
      {
        name: 'Sara Scuri',
        email: 'Sara.Scuri@easyJet.com',
        description: 'for easyJet media enquiries for Italy.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Sara Scuri.jpg',
      },
      {
        name: 'Carinne Heinen',
        email: 'Carinne.Heinen@easyJet.com',
        description: 'for easyJet media enquiries for journalists from France, Switzerland (French-speaking), Belgium (French-speaking), Luxembourg and Morocco.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Carinne Heinen.jpg',
      },
      {
        name: 'Manuel Guenin',
        email: 'Manuel.Guenin@easyjet.com',
        description: 'for easyJet media enquiries for journalists from Austria, Germany, Hungary, Poland and Switzerland (German-speaking).',
        position: '1',
        thumbUrl: '../../assets/easyJet/Manuel Guenin.jpg',
      },
      {
        name: 'Doris Diagne',
        email: 'Doris.Diagne@easyjet.com',
        description: 'for easyJet media enquiries for journalists from France.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Doris Diagne.jpg',
      },
      {
        name: 'Kundai Musara',
        email: 'Kundai.Musara@easyJet.com',
        description: 'for easyJet media enquiries on Sustainability Communications.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Kundai Musara.jpg',
      },
      {
        name: 'Matt Clemens',
        email: 'Matt.Clemens@easyJet.com',
        description: 'for easyJet media enquiries on Sustainability Communications.',
        position: '1',
        thumbUrl: '../../assets/easyJet/Matt.jpeg',
      },
    ]
    this.contacts.rotary = [
      { day: 'Friday', date: '20/10/2023', name: 'Kundai Musara' },
      { day: 'Saturday', date: '21/10/2023', name: 'Kundai Musara' },
      { day: 'Sunday', date: '22/10/2023', name: 'Kundai Musara' },
      { day: 'Monday', date: '23/10/2023', name: 'Kundai Musara' },
      { day: 'Tuesday', date: '24/10/2023', name: 'Kundai Musara' },
      { day: 'Wednesday', date: '25/10/2023', name: 'Kundai Musara' },
      { day: 'Thursday', date: '26/10/2023', name: 'Kundai Musara' },
      { day: 'Friday', date: '27/10/2023', name: 'Matteo Finotto' },
      { day: 'Saturday', date: '28/10/2023', name: 'Matteo Finotto' },
      { day: 'Sunday', date: '29/10/2023', name: 'Matteo Finotto' },
      { day: 'Monday', date: '30/10/2023', name: 'Manuel Guenin' },
      { day: 'Tuesday', date: '31/10/2023', name: 'Manuel Guenin' },
      { day: 'Wednesday', date: '1/11/2023', name: 'Manuel Guenin' },
      { day: 'Thursday', date: '2/11/2023', name: 'Manuel Guenin' },
      { day: 'Friday', date: '3/11/2023', name: 'Carinne Heinen' },
      { day: 'Saturday', date: '4/11/2023', name: 'Carinne Heinen' },
      { day: 'Sunday', date: '5/11/2023', name: 'Carinne Heinen' },
      { day: 'Monday', date: '6/11/2023', name: 'Sara Scuri' },
      { day: 'Tuesday', date: '7/11/2023', name: 'Sara Scuri' },
      { day: 'Wednesday', date: '8/11/2023', name: 'Sara Scuri' },
      { day: 'Thursday', date: '9/11/2023', name: 'Sara Scuri' },
      { day: 'Friday', date: '10/11/2023', name: 'Lottie Phillips' },
      { day: 'Saturday', date: '11/11/2023', name: 'Lottie Phillips' },
      { day: 'Sunday', date: '12/11/2023', name: 'Lottie Phillips' },
      { day: 'Monday', date: '13/11/2023', name: 'Paula Peralta' },
      { day: 'Tuesday', date: '14/11/2023', name: 'Paula Peralta' },
      { day: 'Wednesday', date: '15/11/2023', name: 'Paula Peralta' },
      { day: 'Thursday', date: '16/11/2023', name: 'Paula Peralta' },
      { day: 'Friday', date: '17/11/2023', name: 'Manuel Guenin' },
      { day: 'Saturday', date: '18/11/2023', name: 'Manuel Guenin' },
      { day: 'Sunday', date: '19/11/2023', name: 'Manuel Guenin' },
      { day: 'Monday', date: '20/11/2023', name: 'Doris Diagne' },
      { day: 'Tuesday', date: '21/11/2023', name: 'Doris Diagne' },
      { day: 'Wednesday', date: '22/11/2023', name: 'Doris Diagne' },
      { day: 'Thursday', date: '23/11/2023', name: 'Doris Diagne' },
      { day: 'Friday', date: '24/11/2023', name: 'Andy Cockburn' },
      { day: 'Saturday', date: '25/11/2023', name: 'Andy Cockburn' },
      { day: 'Sunday', date: '26/11/2023', name: 'Andy Cockburn' },
      { day: 'Monday', date: '27/11/2023', name: 'Matteo Finotto' },
      { day: 'Tuesday', date: '28/11/2023', name: 'Matteo Finotto' },
      { day: 'Wednesday', date: '29/11/2023', name: 'Matteo Finotto' },
      { day: 'Thursday', date: '30/11/2023', name: 'Matteo Finotto' },
      { day: 'Friday', date: '1/12/2023', name: 'Kundai Musara' },
      { day: 'Saturday', date: '2/12/2023', name: 'Kundai Musara' },
      { day: 'Sunday', date: '3/12/2023', name: 'Kundai Musara' },
      { day: 'Monday', date: '4/12/2023', name: 'Carinne Heinen' },
      { day: 'Tuesday', date: '5/12/2023', name: 'Carinne Heinen' },
      { day: 'Wednesday', date: '6/12/2023', name: 'Carinne Heinen' },
      { day: 'Thursday', date: '7/12/2023', name: 'Carinne Heinen' },
      { day: 'Friday', date: '8/12/2023', name: 'Paula Peralta' },
      { day: 'Saturday', date: '9/12/2023', name: 'Paula Peralta' },
      { day: 'Sunday', date: '10/12/2023', name: 'Paula Peralta' },
      { day: 'Monday', date: '11/12/2023', name: 'Andy Cockburn' },
      { day: 'Tuesday', date: '12/12/2023', name: 'Andy Cockburn' },
      { day: 'Wednesday', date: '13/12/2023', name: 'Andy Cockburn' },
      { day: 'Thursday', date: '14/12/2023', name: 'Andy Cockburn' },
      { day: 'Friday', date: '15/12/2023', name: 'Sara Scuri' },
      { day: 'Saturday', date: '16/12/2023', name: 'Sara Scuri' },
      { day: 'Sunday', date: '17/12/2023', name: 'Sara Scuri' },
      { day: 'Monday', date: '18/12/2023', name: 'Kundai Musara' },
      { day: 'Tuesday', date: '19/12/2023', name: 'Kundai Musara' },
      { day: 'Wednesday', date: '20/12/2023', name: 'Kundai Musara' },
      { day: 'Thursday', date: '21/12/2023', name: 'Kundai Musara' },
      { day: 'Friday', date: '22/12/2023', name: 'Manuel Guenin' },
      { day: 'Saturday', date: '23/12/2023', name: 'Paula Peralta' },
      { day: 'Sunday', date: '24/12/2023', name: 'Andy Cockburn' },
      { day: 'Monday', date: '25/12/2023', name: 'Matteo Finotto' },
      { day: 'Tuesday', date: '26/12/2023', name: 'Doris Diagne' },
      { day: 'Wednesday', date: '27/12/2023', name: 'Lottie Phillips' },
      { day: 'Thursday', date: '28/12/2023', name: 'Sara Scuri' },
      { day: 'Friday', date: '29/12/2023', name: 'Paula Peralta' },
      { day: 'Saturday', date: '30/12/2023', name: 'Kundai Musara' },
      { day: 'Sunday', date: '31/12/2023', name: 'Carinne Heinen' },
      { day: 'Monday', date: '1/1/2024', name: 'Manuel Guenin' },
      { day: 'Tuesday', date: '2/1/2024', name: 'Manuel Guenin' },
      { day: 'Wednesday', date: '3/1/2024', name: 'Manuel Guenin' },
      { day: 'Thursday', date: '4/1/2024', name: 'Manuel Guenin' },
      { day: 'Friday', date: '5/1/2024', name: 'Lottie Phillips' },
      { day: 'Saturday', date: '6/1/2024', name: 'Lottie Phillips' },
      { day: 'Sunday', date: '7/1/2024', name: 'Lottie Phillips' },
      { day: 'Monday', date: '8/1/2024', name: 'Carinne Heinen' },
      { day: 'Tuesday', date: '9/1/2024', name: 'Carinne Heinen' },
      { day: 'Wednesday', date: '10/1/2024', name: 'Carinne Heinen' },
      { day: 'Thursday', date: '11/1/2024', name: 'Carinne Heinen' },
      { day: 'Friday', date: '12/1/2024', name: 'Doris Diagne' },
      { day: 'Saturday', date: '13/1/2024', name: 'Doris Diagne' },
      { day: 'Sunday', date: '14/1/2024', name: 'Doris Diagne' },
      { day: 'Monday', date: '15/1/2024', name: 'Paula Peralta' },
      { day: 'Tuesday', date: '16/1/2024', name: 'Paula Peralta' },
      { day: 'Wednesday', date: '17/1/2024', name: 'Paula Peralta' },
      { day: 'Thursday', date: '18/1/2024', name: 'Paula Peralta' },
      { day: 'Friday', date: '19/1/2024', name: 'Matteo Finotto' },
      { day: 'Saturday', date: '20/1/2024', name: 'Matteo Finotto' },
      { day: 'Sunday', date: '21/1/2024', name: 'Matteo Finotto' },
      { day: 'Monday', date: '22/1/2024', name: 'Sara Scuri' },
      { day: 'Tuesday', date: '23/1/2024', name: 'Sara Scuri' },
      { day: 'Wednesday', date: '23/1/2024', name: 'Sara Scuri' },
      { day: 'Thursday', date: '24/1/2024', name: 'Sara Scuri' },
      { day: 'Friday', date: '25/1/2024', name: 'Kundai Musara' },
      { day: 'Saturday', date: '26/1/2024', name: 'Kundai Musara' },
      { day: 'Sunday', date: '27/1/2024', name: 'Kundai Musara' },
      { day: 'Monday', date: '28/1/2024', name: 'Lottie Phillips' },
      { day: 'Tuesday', date: '29/1/2024', name: 'Lottie Phillips' },
      { day: 'Wednesday', date: '30/1/2024', name: 'Lottie Phillips' },
      { day: 'Thursday', date: '31/1/2024', name: 'Lottie Phillips' },
    ]
  }

  getBrands() {
    this.loader = true;
    this.mcService.getBrands()
      .subscribe((response: any) => {
        this.brandsResponse = response;
        // if (this.brandsResponse.mustRegisterToAccess && localStorage.getItem('Token') == null) {
        //   sessionStorage.setItem('redirectUrl', '/' + window.location.pathname);
        //   this.router.navigateByUrl('/sign-in');
        // }
        this.brands = response.clients;
        this.loader = false;
        if (this.brandsResponse.customCategories && this.brandsResponse.customCategories.length > 0)
          this.brandsResponse.customCategories.unshift({ id: 0, parentId: 0, label: 'All' });
        if (this.brandsResponse.mainCategories && this.brandsResponse.mainCategories.length > 0)
          this.brandsResponse.mainCategories.unshift({ id: 0, parentId: 0, label: 'All' });
      },
        (error) => {
          //
        });
  }
}
