<section class="media-component flex-center">
  <div class="content-wrapper">
    <div id="medias" class="media">
      <h1 class="hidden-h1">Media</h1>
      <div class="background-border"></div>
      <div class="mobile-only open-filters" [ngClass]="{ brandColor: isEasyJet }" (click)="toggleFilters()"><img
          src="../../assets/images/filters.svg" />
      </div>
      <div class="filter-options-container" [ngClass]="{ visible: filtersVisible, brandColor: isEasyJet }">
        <div class="filter-title">
          {{ language.filterBy || "FilterBy:" }}
          <i class="far fa-times mobile-only" (click)="toggleFilters()"></i>
        </div>
        <div class="left-and-right">
          <div class="left-part">
            <div class="select-date" *ngIf="searchModelData.subClients.length > 0">
              <div class="filter-select-container">
                <select id="brandSelect" class="filter-select" (change)="onChangeBrand($event.target.value)">
                  <option value="0">{{ language.selectBrand }}</option>
                  <option *ngFor="let brand of searchModelData.subClients" value="{{ brand.id }}">
                    {{ brand.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="right-part">
            <div class="search-input-wrapper">
              <div class="search-icon"><img src="../../assets/icons/searchIcon.png" /></div>
              <input placeholder="{{ language.search }}" autocomplete="off" id="story-search-big" type="search"
                [(ngModel)]="searchModelData.searchModel.searchText" />
            </div>
            <div class="select-date">
              <div class="buttons-wrapper flex">
                <div class="from">
                  <div class="calendar-input flex">
                    <i class="fal fa-calendar-alt" (click)="openDateFrom()"></i>
                    <dx-date-box [(value)]="searchModelData.searchModel.dateFrom" [opened]="dateFromButton"
                      [displayFormat]="'dd/MM/yyyy'" type="date" (onValueChanged)="changeFromDate($event)"
                      name="dateFrom" [placeholder]="language.from">
                    </dx-date-box>
                  </div>
                </div>
                <div class="to">
                  <div class="calendar-input flex">
                    <i class="fal fa-calendar-alt" (click)="openDateTo()"></i>
                    <dx-date-box [(value)]="searchModelData.searchModel.dateTo" [opened]="dateToButton"
                      [displayFormat]="'dd/MM/yyyy'" (onValueChanged)="changeToDate($event)" type="date" name="dateTo"
                      [placeholder]="language.to">
                    </dx-date-box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <div class="generic-button filter-toggle-button flex"
            (click)="getMedias((p = 1), selectedType, true); toggleFilters()">
            <span>
              {{ language.applyFilters || 'Apply Filters' }}
            </span>
          </div>
          <div class="generic-button filter-toggle-button flex" (click)="clearFilters(); toggleFilters()">
            <span>
              {{ language.clearFilters || 'Clear Filters' }}
            </span>
          </div>
        </div>
      </div>
      <div class="filter-options-container-dummy"></div>
      <div class="media-bar">
        <div class="filter flex">
          <ng-container *ngFor="let filter of filters">
            <div *ngIf="filter.amount > 0" class="filter-button flex generic-button empty"
              (click)="changeTab(filter.key)" [ngClass]="{ active: activeSubTab == filter.key }">
              <i [ngClass]="[filter.faClass]"></i>
              <span>{{ filter.text }} ({{ filter.amount }})</span>
            </div>
          </ng-container>
        </div>

        <div class="action-buttons flex-center">
          <div class="download-container">
            <div class="action-buttons flex-center no-mobile">
              <div class="generic-button empty flex" (click)="sendToCart()">
                <i class="fal fa-folder"></i>
                <span>
                  {{ language.addToMyFiles || language.addToFiles }} ({{
                  mediaFiles?.length
                  }})
                </span>
              </div>
              <div class="generic-button empty flex" (click)="download()">
                <i class="fal fa-arrow-down"></i>
                <span>
                  {{ language.download }} ({{ mediaFiles?.length }})
                </span>
              </div>
            </div>
            <div class="flex downloading-message" *ngIf="downloadActive">
              <div class="cancel-download-loader-wrap">
                <app-loader></app-loader>
              </div>
              <button class="generic-button flex-center cancel-download" (click)="cancelDownload()">
                {{ language.cancel }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="media-feed">
        <div class="media-wrapper" *ngIf="!isEasyJet">
          <ng-container *ngFor="let group of mediaGroups; let i = index">
            <app-media-group class="group" [group]="group" [config]="config" [mirrored]="i % 2 != 0"></app-media-group>
          </ng-container>
        </div>
        <div class="media-wrapper wrap" *ngIf="isEasyJet">
          <ng-container *ngFor="let media of mediaFiles; let i = index">
            <app-media-file class="media-item" [template]="4" [media]="media" [config]="config"
              [full]="true"></app-media-file>
          </ng-container>
        </div>
      </div>

      <div class="load-more-wrapper">
        <div class="lazy-loading-loader-container" *ngIf="lazyLoadingLoader">
          <app-loadersmall></app-loadersmall>
        </div>
        <div class="break"></div>
      </div>
    </div>
  </div>
</section>

<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup"></app-loginpopup>