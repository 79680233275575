import { SafeHtml } from "@angular/platform-browser";

export class HomeModel {
  public featuredStories: FeaturedStoriesModel[];
  public allStories: LatestStoriesModel[];
  public latesStoryGridType: string;
  public mustRegisterToAccess: boolean;
  public mustRegisterToDownload: boolean;
  public title: string;
  public bodyText: string;
  public additionalBodyText: string;
  public latestStories: LatestStoriesModel[];
  public latestStoriesCategory: LatestStoriesCategoryModel[];
  public latestMedia: LatestMediaModel[];
  public socialSharing: string[];
  public socialFeeds: SocialModel[];
  public testimonials: TestimonialsModel[];
  public homeLatestMediaImage: boolean;
  public homeLatestMediaVideo: boolean;
  public homeLatestMediaDocument: boolean;
  public homeLatestMediaAudio: boolean;
  public homeShowTestimonials: boolean;
  public hideCounters: boolean;
  public filteredStories: LatestStoriesModel[];
}

export class FeaturedStoriesModel {
  public storyId: number;
  public audio: number;
  public images: number;
  public video: number;
  public document: number;
  public publishedDate: string;
  public category: string[];
  public storyTitle: string;
  public thumbUrl: string;
  public fullHDUrl: string;
  public featuredEmbeddedCode: string;
  public embeddedCode: SafeHtml;
  public mediaType: string;
  public hideStoryDate: boolean;
}

export class LatestStoriesModel {
  public staggingStoryId: number;
  public storyId: number;
  public audio: number;
  public images: number;
  public video: number;
  public document: number;
  public publishedDate: string;
  public category: string;
  public storyTitle: string;
  public thumbUrl: string;
  public fullHDUrl?: string;
  public featuredEmbeddedCode: string;
  public embeddedCode: SafeHtml;
  public shortDescription: string;
  public views: number;
  public shared: number;
  public hideStoryDate: boolean;

  //added new properties
  public shareDropdown: boolean;
  public dropdown: boolean;
}

export class LatestStoriesCategoryModel {
  public category: string;
  public latestStories: LatestStoriesModel[];
  public gridType: string;
}

export class LatestMediaModel {
  public mediaId: number;
  public mediaType: string;
  public thumbUrl: string;
  public fullHDUrl: string;
  public title: string;

  //added new properties
  public overlay: boolean;
}

export class SocialModel {
  public name: string;
  public imageUrl: string;
  public nickname: string;
  public text: string;
  public date: string;
}

export class TestimonialsModel {
  public name: string;
  public imageUrl: string;
  public clientLogoUrl: string;
  public text: string;
  public date: string;
}
