<div class="media-box media"
  [ngClass]="(newsDetail ? 'news-detail ' : '') + customClasses + (template === 4 ? ' media-item-4' : '')">
  <div [ngClass]="'media-image ' + customClasses + (template === 4 ? ' media-item-4' : '')">
    <span class="image-container">
      <div class="play-button-holder">
        <div *ngIf="media.mediaType === 'Video'" class="play-button flex-center" (click)="openMediaPopup(true)">
          <i class="fas fa-play"></i>
        </div>
      </div>
      <picture *ngIf="
          media.mediaType.toLowerCase() != 'audio' &&
          media.mediaType.toLowerCase() != 'document'
        ">
        <source srcset="{{ webPHelper.changeFileToWebP(getSource(media)) }}" type="image/webp" />
        <source srcset="{{ getSource(media) }}" type="image/jpeg" />
        <source srcset="assets/images/placeholder.jpg" type="image/jpeg" />
        <img (error)="webPHelper.removeWebP($event)" src="{{ getSource(media) }}" alt="Story featured image" />
      </picture>
      <div class="audio-overlay flex-center" *ngIf="media.mediaType.toLowerCase() === 'audio'">
        <div class="file-type-wrapper flex-center">
          <img src="../../../../assets/images/file-type-sound.svg" alt="{{ media.name }}" />
        </div>
      </div>
      <div class="document-overlay flex-center" *ngIf="media.mediaType.toLowerCase() === 'document'">
        <div class="file-type-wrapper flex-center">
          <img *ngIf="media.mediaType.toLowerCase() === 'document'" src="../../../../assets/images/file-type-pdf.svg"
            alt="{{ media.name }}" />
        </div>
      </div>
    </span>
    <div class="button-hover-overlay">
      <div class="button-container">
        <div class="open-popup overlay-button" (click)="openMediaPopup()">
          <i class="fas fa-expand-alt"></i>
          {{ language.expand }}
        </div>

        <div class="add-to-cart overlay-button" (click)="sendToCart()">
          <i class="far fa-file-plus"></i>
          {{ language.addToFiles }}
        </div>

        <div class="add-to-download-box overlay-button" (click)="download()">
          <i *ngIf="!downloading" class="fal fa-download"></i>
          {{ language.download }}
          <app-loadersmall *ngIf="downloading" [class]="'smaller'"></app-loadersmall>
        </div>

        <app-share *ngIf="
            config.socialSharing?.length > 0 &&
            (config?.storyTitle || media.name) && media.relativeShareUrl != null
          " [image]="media.thumbUrl" [big]="false" [vertical]="customClasses == 'news-detail-media-file'"
          [shared]="media.shares" [sbUrl]="shareUrl" [title]="config?.storyTitle || media.name"
          [description]="config?.shortDescription" [mediaId]="media.id" [webNewsDetailsResponse]="config"
          [isMediaShare]="true" [isMediaShareVertical]="true" [socials]="config.socialSharing"
          [isPublished]="isPublished"></app-share>
      </div>
    </div>
  </div>

  <div class="media-info" *ngIf="full">
    <div class="media-name">
      {{ media.name }}
    </div>

    <hr />

    <!-- <div class="bottom-row flex"> -->
    <div class="read-more" (click)="sendToCart()">
      <span *ngIf="!media.addedToCart">{{ language.addToMyFiles }}</span>
      <span *ngIf="media.addedToCart">{{ language.addedToMyFiles }}</span>
    </div>
    <!-- <div class="add-to-download-box round generic-button empty" (click)="download()">
        <app-loadersmall *ngIf="downloading" [class]="'smaller'"></app-loadersmall>
      </div> -->
    <!-- </div> -->
  </div>

  <div class="media-info news-detail" *ngIf="newsDetail">
    <h2>
      {{media.name}}
    </h2>
    <p>{{media.mediaType}}</p>
  </div>

</div>

<app-popup *ngIf="popup && !showExternally" [(popup)]="popup" [(hideCounters)]="config.hideCounters" [mediaId]="mediaId"
  [isPublished]="isPublished" [autoplay]="autoplay"></app-popup>
<app-loader *ngIf="loader"></app-loader>
<app-loginpopup *ngIf="loginPopup" [(loginPopup)]="loginPopup" (loginSuccessfulChange)="onLogin($event)"></app-loginpopup>